import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Dialog as MuiDialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function Dialog({
  withCloseButton, children, onClose, ...otherProps
}) {
  const fullScreen = useMediaQuery('@media (max-width: 767px)');

  const handleCloseButtonClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <MuiDialog fullScreen={fullScreen} {...otherProps}>
      {withCloseButton && (
        <IconButton
          onClick={handleCloseButtonClick}
          sx={{
            position: 'absolute',
            zIndex: 10,
            right: 8,
            top: 8,
            color: (t) => t.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </MuiDialog>
  );
}

export default Dialog;
