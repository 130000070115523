const getPipelineSteps = (pipeline) => {
  const stepsByType = {};

  pipeline.steps.forEach((step) => {
    stepsByType[step.step_type] = step;
  });

  const stepDefinitions = pipeline.step_definitions.filter((stepDefinition) => stepDefinition !== 'FINALIZATION');

  return stepDefinitions.map(
    (stepDefinition) => stepsByType[stepDefinition] || { step_type: stepDefinition },
  );
};

export default getPipelineSteps;
