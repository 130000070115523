/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const browserLanguage = (navigator.language || navigator.userLanguage)?.toLowerCase();

const initialState = {
  isLoading: false,
  language: browserLanguage?.includes('de') ? 'DE' : 'EN',
  isNavigationExpanded: true,
  highlightedItemId: null,
  isEditMode: false,
};

export const slice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setIsGlobalLoading: (state, { payload: isLoading }) => {
      state.isLoading = isLoading;
    },
    setLanguage: (state, { payload: { value } }) => {
      state.language = value;
    },
    expandNavigation: (state) => {
      state.isNavigationExpanded = !state.isNavigationExpanded;
    },
    setHighlightedItemId: (state, { payload: { value } }) => {
      state.highlightedItemId = value;
    },
    setEditMode: (state, { payload: { value } }) => {
      state.isEditMode = value;
    },
  },
});

export const { setIsGlobalLoading } = slice.actions;
export const { actions } = slice;

export default slice.reducer;
