import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  searchBoxContainer: {
    maxWidth: 1100,
    margin: 'auto',
  },
  mainInner: {
    margin: 'auto',
  },
  mainInnerCardsView: {
    maxWidth: 1100,
    background: 'transparent',
  },
  filterChips: {
    maxWidth: 1100,
    margin: 'auto',
  },
  cardTitle: {
    marginTop: 12,
    fontWeight: 500,
  },
  resultList: {
    marginBottom: '16px',
  },
});

export default useStyles;
