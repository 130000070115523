function arrayMove(arr, fromIndex, toIndex) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

const movePage = (
  subDocs,
  sourceSubDocIndex,
  sourcePageIndex,
  targetSubDocIndex,
  targetPageIndex,
) => {
  if (sourceSubDocIndex === targetSubDocIndex) {
    arrayMove(subDocs[sourceSubDocIndex].pages, sourcePageIndex, targetPageIndex);
  } else {
    const page = subDocs[sourceSubDocIndex].pages[sourcePageIndex];

    subDocs[sourceSubDocIndex].pages.splice(sourcePageIndex, 1);
    subDocs[targetSubDocIndex].pages.splice(targetPageIndex, 0, page);

    if (subDocs[sourceSubDocIndex].pages.length === 0) {
      subDocs.splice(sourceSubDocIndex, 1);
    }
  }
};

export default movePage;
