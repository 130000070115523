import {
  all, put, takeLatest, call,
} from 'redux-saga/effects';
import { NotificationManager as NM } from 'react-notifications';
import JSZip from 'jszip';

import apiRequest from 'api/request';

import { getSplittingState } from 'utils/localstorage/splittingLocalstorage';
import { actions } from './fileSplittingReducer';

import request from '../../utils/sagas/request';

import getSubDocs from './utils/getSubDocs';

async function fetchZipAndConvertToBase64(fileItemId) {
  try {
    const { data: { signed_link: signedLink } } = await apiRequest({
      method: 'post',
      url: '/document-download-links/_generate_thumbnails_link',
      data: {
        file_item_id: fileItemId,
      },
    });

    const response = await fetch(signedLink);
    const blob = await response.blob();
    const zip = await JSZip.loadAsync(blob);

    const base64ByPageNumber = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const fileName of Object.keys(zip.files)) {
      // eslint-disable-next-line no-await-in-loop
      const fileData = await zip.files[fileName].async('blob');
      // eslint-disable-next-line no-await-in-loop
      const base64String = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(fileData);
      });
      base64ByPageNumber[fileName.replace('/', '')] = base64String;
    }

    return base64ByPageNumber;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
}

export function* initFlow({ payload: { fileItemId } }) {
  try {
    const base64ByPageNumber = yield call(fetchZipAndConvertToBase64, fileItemId);

    const { data: fileItem } = yield request({
      method: 'get',
      url: `/files/${fileItemId}`,
    });

    const { data: { task_groups: taskGroups } } = yield request({
      method: 'post',
      url: '/tasks/_search',
      data: {
        processing_task_types: [
          'split_preparation', 'split_finalizing',
        ],
        file_item_ids: [fileItemId],
      },
    });

    const { data: { task_groups: preparationTaskGroups } } = yield request({
      method: 'post',
      url: '/tasks/_search',
      data: {
        processing_task_types: ['split_preparation'],
        file_item_ids: [fileItemId],
      },
    });

    const { data: { signed_link: signedLink } } = yield request({
      method: 'post',
      url: '/document-download-links/_generate',
      data: {
        file_item_id: fileItemId,
        direct_download: true,
      },
    });

    const task = taskGroups[0].tasks[0];
    const preparationTask = preparationTaskGroups[0].tasks[0];

    const localStorageSplittingState = getSplittingState(fileItemId);

    yield put(actions.initSuccess({
      fileItem,
      pdfUrl: signedLink,
      subDocs: localStorageSplittingState?.subDocs || getSubDocs(task.document_split_details),
      splitPointConfidences: task.document_split_details.split_point_confidences,
      preparationTaskId: preparationTask.id,
      base64ByPageNumber,
    }));
  } catch (e) {
    NM.error('Something went wrong');
  }
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.initRequest.type, initFlow),
  ]);
}
