import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  fileNameTable: {
    marginTop: -16,
    marginBottom: -16,

    '& td': {
      border: 'none',
    },
  },
  viewer: {
    // eslint-disable-next-line max-len
    '&& #rpv-core__popover-body-inner-search, && #rpv-core__popover-body-inner-zoom, && #rpv-core__popover-body-inner-toolbar-more-actions, .rpv-core__modal-body': {
      maxHeight: 'none !important',
    },
  },
});

export default useStyles;
