import React, { useState } from 'react';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import MetadataCell from 'components/MetadataCell';

import useDocumentClassesById from 'hooks/documentClasses/useDocumentClassesById';

import MainMetadata from './MainMetadata';

export default function MetadataBlock({ fileItem, onItemUpdate }) {
  const [tab, setTab] = useState('main');

  const documentClassesById = useDocumentClassesById() || {};

  const tablesStructure = documentClassesById[fileItem.document_class_id]?.tables || [];

  const metadata = fileItem.ai_metadata;

  if (!metadata) {
    if (fileItem.extractingMetadata) {
      return <div style={{ padding: 8 }}>Extracting metadata...</div>;
    }

    return <div style={{ padding: 8 }}>No metadata</div>;
  }

  const mainContent = (
    <MainMetadata
      fileItem={fileItem}
      onItemUpdate={onItemUpdate}
    />
  );

  const tables = metadata.tables_list || [];

  const tabsContent = {};

  tables.forEach((table) => {
    tabsContent[`table_${table.name}`] = table;
  });

  const renderTabContent = () => {
    const tabContent = tabsContent[tab];

    if (!tabContent) {
      return mainContent;
    }

    const { lines } = tabContent;
    const fields = tablesStructure.find(({ id }) => id === tabContent.id)?.fields;

    if (!lines || !fields) {
      return mainContent;
    }

    const fieldsById = {};

    fields.forEach((field) => {
      fieldsById[field.id] = field;
    });

    return (
      <TableContainer sx={{ height: '100%' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {lines.map((column) => (
                <TableCell key={column.id}>{column.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {lines[0].values.map((_, rowIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={rowIndex}>
                {lines.map((column) => (
                  <MetadataCell
                    key={column.id}
                    forceEditMode
                    fileItem={fileItem}
                    tableId={tabContent.id}
                    rowIndex={rowIndex}
                    metadata={{
                      id: column.id,
                      value: column.values[rowIndex],
                      type: fieldsById[column.id]?.field_type,
                    }}
                    onFileItemUpdate={onItemUpdate}
                  />

                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (tables.length === 0) {
    return mainContent;
  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Tabs
        value={tab}
        onChange={(e, value) => setTab(value)}
      >
        <Tab value="main" label="Main" />
        {tables.map((table) => (
          <Tab key={table.name} value={`table_${table.name}`} label={table.name} />
        ))}
      </Tabs>
      {renderTabContent()}
    </div>
  );
}
