import React, { useState } from 'react';
import cx from 'classnames';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import useStyles from './SearchBox.styles';

function SearchBox({
  className,
  placeholder = 'Search',
  suggestions = [],
  onSearch = () => {},
  renderOption,
  inputValue,
  onChange,
  onKeyDown = () => {},
  onInputChange = () => {},
}) {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 || e.code === 'Enter') {
      onSearch(value);
    } else if (e.keyCode === 27 || e.code === 'Escape') {
      setValue('');
    }
    onKeyDown(e);
  };

  const handleInputChange = (e, newInputValue, reason) => {
    if (!e) {
      return;
    }

    setValue(e.target.value || '');
    onInputChange(e, newInputValue, reason);

    if (reason === 'clear') {
      onSearch('');
    }
  };

  return (
    <Autocomplete
      className={cx(classes.root, className)}
      inputValue={inputValue === undefined ? value : inputValue}
      freeSolo
      options={suggestions}
      filterOptions={(x) => x}
      getOptionLabel={() => ''}
      renderInput={(params) => (
        <TextField
          className={classes.textField}
          {...params}
          placeholder={placeholder}
          InputProps={params.InputProps}
          onKeyDown={handleKeyDown}
        />
      )}
      renderOption={renderOption}
      onChange={onChange}
      onInputChange={handleInputChange}
    />
  );
}

export default SearchBox;
