import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import SplittingView from 'components/SplittingView';

const InboxItemSplittingPage = ({ inboxType }) => {
  const { orgName, itemId } = useParams();
  const [searchParams] = useSearchParams();

  return (
    <SplittingView
      fileItemId={searchParams.get('file_item_id')}
      inboxItemId={itemId}
      backUrl={inboxType === 'DEFAULT' ? `/${orgName}/inbox` : `/${orgName}/balance-import`}
    />
  );
};

export default InboxItemSplittingPage;
