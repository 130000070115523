import React, { useState } from 'react';
import { NotificationManager as NM } from 'react-notifications';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import Layout from 'components/Layout';

import { LinearProgress, Stack, Divider } from '@mui/material';
import { request } from 'api';
import useFileItem from './hooks/useFileItem';

import RecentFilesDialog from './RecentFilesDialog';
import PromptSelectionMenu from './PromptSelectionMenu'; // Add this import

const models = [
  ['VERTEX_AI', 'gemini-1.5-flash'],
  ['VERTEX_AI', 'gemini-1.5-pro'],
  ['OPEN_AI', 'gpt-4'],
  ['OPEN_AI', 'gpt-4-1106-preview'],
  ['OPEN_AI', 'gpt-4-0125-preview'],
  ['OPEN_AI', 'gpt-4o'],
  ['OPEN_AI', 'gpt-4o-mini'],
  ['OPEN_AI', 'gpt-3.5-turbo'],
  ['ANTHROPIC_AI', 'claude-3-5-sonnet-20240620'],
  ['ANTHROPIC_AI', 'claude-3-opus-20240229'],
  ['ANTHROPIC_AI', 'claude-3-sonnet-20240229'],
  ['ANTHROPIC_AI', 'claude-3-haiku-20240307'],
];

const AiTestPage = () => {
  const [aiModel, setAiModel] = useState(null);
  const [sourceType, setSourceType] = useState('TEXT');
  const [answerType, setAnswerType] = useState('TEXT');
  const [pages, setPages] = useState('1,2,3,4,5');
  const [prompt, setPrompt] = useState(localStorage.getItem('ai-test-prompt') || '');
  const [aiAnswer, setAiAnswer] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRecentFilesDialogOpened, setIsRecentFilesDialogOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    fileItem,
    isUploading,
    isProcessing,
    uploadFile,
    setFileItem,
  } = useFileItem();

  const renderFileText = () => {
    if (isUploading) {
      return 'Uploading file...';
    }

    if (isProcessing) {
      return 'Processing file...';
    }

    if (fileItem) {
      return fileItem.name;
    }

    return null;
  };

  const submitButtonDisabled = !fileItem || isProcessing || !aiModel || !pages || !prompt;

  const renderAnswer = () => {
    if (isSubmitting) {
      return <LinearProgress />;
    }

    if (aiAnswer) {
      return aiAnswer.answer;
    }

    return null;
  };

  const handleSubmit = async () => {
    const [provider, model] = aiModel.split('___');

    setIsSubmitting(true);

    try {
      const { data } = await request({
        method: 'post',
        url: `/ai-test/files/${fileItem.id}/_get-ai-answer`,
        data: {
          prompt,
          ai_processor: provider,
          ai_model: model,
          source_type: sourceType,
          answer_type: answerType,
          pages: pages.split(','),
        },
      });

      setAiAnswer(data);
    } catch (e) {
      NM.error('Something went wrong');
    }

    setIsSubmitting(false);
  };

  const handlePromptChange = (e) => {
    localStorage.setItem('ai-test-prompt', e.target.value);

    setPrompt(e.target.value);
  };

  const handleFileSelect = (file) => {
    setIsRecentFilesDialogOpened(false);
    setFileItem({ ...file, selected: true });
  };

  const handlePromptMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePromptMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePromptSelect = (selectedPrompt) => {
    setPrompt(selectedPrompt);
    handlePromptMenuClose();
  };

  return (
    <Layout
      app="ai-test"
      newButton={(
        <Button
          variant="contained"
          disabled={submitButtonDisabled}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      )}
    >
      <div style={{ padding: 8 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <Stack spacing={2}>
                <div>
                  <InputLabel shrink>File</InputLabel>
                  <div>{renderFileText()}</div>
                  <Stack direction="row">
                    <Button
                      component="label"
                      disabled={isUploading || isProcessing}
                      size="small"
                    >
                      <input
                        type="file"
                        hidden
                        accept="application/pdf"
                        onChange={(e) => uploadFile(e.target.files[0])}
                      />
                      Upload
                    </Button>
                    <Divider orientation="vertical" flexItem>or</Divider>
                    <Button
                      component="label"
                      disabled={isUploading || isProcessing}
                      size="small"
                      onClick={() => setIsRecentFilesDialogOpened(true)}
                    >
                      Select
                    </Button>
                  </Stack>
                </div>
                <FormControl fullWidth variant="standard">
                  <InputLabel>AI processor</InputLabel>
                  <Select
                    value={aiModel}
                    label="AI model"
                    onChange={(e) => setAiModel(e.target.value)}
                  >
                    {models.map(([provider, model]) => (
                      <MenuItem value={`${provider}___${model}`}>
                        {provider}
                        {' '}
                        -
                        {' '}
                        {model}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <InputLabel>Source type</InputLabel>
                  <Select
                    value={sourceType}
                    label="Source type"
                    onChange={(e) => setSourceType(e.target.value)}
                  >
                    <MenuItem value="TEXT">TEXT</MenuItem>
                    <MenuItem value="IMAGE">IMAGE</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <InputLabel>Answer type</InputLabel>
                  <Select
                    value={answerType}
                    label="Answer type"
                    onChange={(e) => setAnswerType(e.target.value)}
                  >
                    <MenuItem value="TEXT">TEXT</MenuItem>
                    <MenuItem value="JSON">JSON</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  value={pages}
                  variant="standard"
                  label="Pages"
                  onChange={(e) => setPages(e.target.value)}
                />
                <TextField
                  value={prompt}
                  variant="standard"
                  label="Prompt"
                  multiline
                  onChange={handlePromptChange}
                />
                <Button
                  variant="outlined"
                  onClick={handlePromptMenuOpen}
                  sx={{ mt: 1 }}
                >
                  Select prompt
                </Button>
                <PromptSelectionMenu
                  anchorEl={anchorEl}
                  onClose={handlePromptMenuClose}
                  onSelect={handlePromptSelect}
                />
              </Stack>
            </Grid>
            <Grid xs={6} sx={{ whiteSpace: 'pre-wrap' }}>
              {renderAnswer()}
            </Grid>
          </Grid>
        </Box>
      </div>
      {isRecentFilesDialogOpened && (
        <RecentFilesDialog
          onSelect={handleFileSelect}
          onClose={() => setIsRecentFilesDialogOpened(false)}
        />
      )}
    </Layout>
  );
};

export default AiTestPage;
