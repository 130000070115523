import React, { useState } from 'react';

import { Box, InputBase } from '@mui/material';

const EditableTextCellEditMode = ({
  initialValue,
  singleLine,
  onSubmit,
  onFocus,
  onBlur,
}) => {
  const [value, setValue] = useState(initialValue);

  const handleSubmit = async () => {
    onBlur();
    onSubmit(value);
  };

  const handleBlur = () => {
    onBlur();
    handleSubmit();
  };

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case 13:
        handleSubmit();
        break;
      default:
        break;
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value.replace(/\n/g, '');
    setValue(newValue);
  };

  return (
    <div style={{ position: 'relative', textDecoration: 'inherit', minHeight: 20 }}>
      <Box sx={{
        fontWeight: 'inherit',
        fontSize: 'inherit',
        textDecoration: 'inherit',
        visibility: 'hidden',
      }}
      >
        {value}
      </Box>
      <InputBase
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          fontWeight: 'inherit',
          fontSize: 'inherit',
          lineHeight: 'inherit',
          textDecoration: 'inherit',
          padding: 0,
          letterSpacing: 'normal',

          '&>input': {
            padding: 0,
            marginTop: '-0.5px',
          },
        }}
        placeholder="Enter value"
        value={value}
        onKeyDown={handleKeyDown}
        multiline={!singleLine}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default EditableTextCellEditMode;
