import React from 'react';

import Step from './Step';

import getPipelineSteps from './utils/getPipelineSteps';

import useStyles from './Pipeline.styles';

const Pipeline = ({
  pipeline,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {getPipelineSteps(pipeline).map((step, index) => (
        <Step
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          step={step}
          index={index}
        />
      ))}
    </div>
  );
};

export default Pipeline;
