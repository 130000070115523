import React from 'react';
import { useSelector } from 'react-redux';
import {
  PieChart, Pie, Tooltip, Cell, BarChart, Bar, XAxis,
} from 'recharts';

import { certificateClasses, energyCertificatesColors } from 'constants/energyCertificates';

import { immoListSelectors as selectors } from 'redux/selectors';
import { Stack, Typography } from '@mui/material';
import getBarChartData from 'pages/ImmoListPage/ImmoList/ImmoCharts/utils/getBarChartData';

const renderLabel = (entry) => `${entry.name} (${Math.round(entry.percent * 100)} %)`;

const ImmosCharts = () => {
  const items = useSelector(selectors.itemsSelector) || [];

  const countByEnergyEfficientClass = {};

  items.forEach((item) => {
    const efClass = item?.energy_efficiency?.energy_efficient_class;

    if (!countByEnergyEfficientClass[efClass]) {
      countByEnergyEfficientClass[efClass] = 0;
    }

    countByEnergyEfficientClass[efClass] += 1;
  });

  const data = certificateClasses.map((certClass) => {
    const value = countByEnergyEfficientClass[certClass] || 0;

    return ({
      name: certClass,
      value,
      color: value ? energyCertificatesColors[certClass] : 'transparent',
    });
  });

  const pieChartData = getBarChartData(items);

  return (
    <Stack direction="row" sx={{ justifyContent: 'center' }}>
      <PieChart width={450} height={250}>
        <Pie
          dataKey="value"
          data={data}
          outerRadius={80}
          label={renderLabel}
          startAngle={90}
          endAngle={-450}
        >
          {data.map((entry) => (
            <Cell key={entry.name} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
      <div style={{ padding: '0 100px' }}>
        <Typography sx={{ mb: '16px', mt: '8px' }}>
          Altersverteiling der Heizungsanlagen in Jahren
        </Typography>
        <BarChart
          width={300}
          height={180}
          data={pieChartData}
          style={{ margin: 'auto' }}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <Bar dataKey="value" fill="#055575" label={{ position: 'top' }} />
        </BarChart>
      </div>
    </Stack>
  );
};

export default ImmosCharts;
