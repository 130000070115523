/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import movePage from './utils/movePage';
import movePages from './utils/movePages';

const initialState = {
  isInited: false,
  fileItem: null,
  pdfUrl: null,
  subDocs: null,
  splitPointConfidences: null,
  preparationTaskId: null,
  showConfidences: true,
  base64ByPageNumber: null,
  highlightedPageNumber: null,
  selectedPages: {},
  zoom: Number(localStorage.getItem('immomate.splitting.zoom')) || 1,
};

export const slice = createSlice({
  name: 'fileSplitting',
  initialState,
  reducers: {
    initRequest: (state) => state,
    initSuccess: (state, {
      payload: {
        pdfUrl, subDocs, splitPointConfidences, preparationTaskId, base64ByPageNumber, fileItem,
      },
    }) => {
      state.fileItem = fileItem;
      state.pdfUrl = pdfUrl;
      state.subDocs = subDocs;
      state.isInited = true;
      state.splitPointConfidences = splitPointConfidences;
      state.preparationTaskId = preparationTaskId;
      state.base64ByPageNumber = base64ByPageNumber;
    },
    joinSubDocs: (state, { payload: { subDocIndex } }) => {
      state.subDocs[subDocIndex].pages.push(...state.subDocs[subDocIndex + 1].pages);
      state.subDocs.splice(subDocIndex + 1, 1);
    },
    splitPages: (state, { payload: { subDocIndex, pageIndex } }) => {
      const newPages = state.subDocs[subDocIndex].pages.splice(pageIndex + 1);

      state.subDocs.splice(subDocIndex + 1, 0, {
        localId: Math.random(),
        pages: newPages,
      });
    },
    deletePage: (state, { payload: { subDocIndex, pageIndex } }) => {
      const page = state.subDocs[subDocIndex].pages[pageIndex];
      page.marked_for_deletion = !page.marked_for_deletion;
    },
    rotateLeftPage: (state, { payload: { subDocIndex, pageIndex } }) => {
      state.subDocs[subDocIndex].pages[pageIndex].rotation -= 90;
    },
    rotateRightPage: (state, { payload: { subDocIndex, pageIndex } }) => {
      state.subDocs[subDocIndex].pages[pageIndex].rotation += 90;
    },
    movePage: (state, {
      payload: {
        sourceSubDocIndex, sourcePageIndex, targetSubDocIndex, targetPageIndex,
      },
    }) => {
      movePage(state.subDocs, sourceSubDocIndex, sourcePageIndex, targetSubDocIndex, targetPageIndex);
      state.showConfidences = false;
    },
    movePages: (state, {
      payload: {
        pageNumbers, targetSubDocIndex, targetPageIndex,
      },
    }) => {
      movePages(state.subDocs, pageNumbers, targetSubDocIndex, targetPageIndex);
      state.showConfidences = false;
    },
    setHighlightedPageNumber: (state, { payload: { pageNumber } }) => {
      state.highlightedPageNumber = pageNumber;
    },
    selectPage: (state, { payload: { pageNumber } }) => {
      if (state.selectedPages[pageNumber]) {
        delete state.selectedPages[pageNumber];
      } else {
        state.selectedPages[pageNumber] = true;
      }
    },
    resetSelectedPages: (state) => {
      state.selectedPages = initialState.selectedPages;
    },
    setZoom: (state, { payload: { value } }) => {
      state.zoom = value;
    },
    reset: (state) => ({
      ...initialState,
      zoom: state.zoom,
    }),
  },
});

export const { actions } = slice;

export default slice.reducer;
