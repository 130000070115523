import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    display: 'flex',

    '&:hover $editButton': {
      visibility: 'visible',
    },
  },
  withValue: {},
  editButton: {
    marginTop: -3,
    marginLeft: 8,

    '$withValue &': {
      visibility: 'hidden',
    },
  },
});

export default useStyles;
