import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';

import Close from '@mui/icons-material/Close';
import Settings from '@mui/icons-material/Settings';

import AdvisorSettingsDialog from 'components/AdvisorSettingsDialog';
import SearchBox from './SearchBox';
import AnswerContent from './AnswerContent';

import logo from '../../logo.svg';

import useAsyncAnswer from './hooks/useAsyncAnswer';

import useStyles from './AskImmoMateDialog.styles';

const AskImmoMateDialog = ({ onClose }) => {
  const classes = useStyles();
  const {
    isGenerating: isGeneratingProp,
    isGeneratingSummary,
    answer,
    onSubmit,
  } = useAsyncAnswer();

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const isGenerating = isGeneratingProp || answer?.status === 'IN_PROGRESS';

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      fullWidth
      open
    >
      <DialogContent className={classes.content}>
        <IconButton className={classes.closeButton} size="large" onClick={onClose}>
          <Close fontSize="inherit" />
        </IconButton>
        <IconButton className={classes.settingsButton} size="large" onClick={() => setIsSettingsOpen(true)}>
          <Settings />
        </IconButton>
        <img className={classes.logoImg} src={logo} alt="logo" />
        <div className={classes.title}>Ask ImmoMate</div>
        <div className={classes.description}>
          Answering your questions based on the immo documents
        </div>
        <SearchBox onSubmit={onSubmit} />
        <AnswerContent
          isGenerating={isGenerating}
          isGeneratingSummary={isGeneratingSummary}
          answer={answer}
        />
      </DialogContent>
      <AdvisorSettingsDialog open={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />
    </Dialog>
  );
};

export default AskImmoMateDialog;
