import axios from 'axios';
import {
  all, put, takeLatest, select,
} from 'redux-saga/effects';
import { NotificationManager as NM } from 'react-notifications';
import { fileListActions, setIsGlobalLoading } from 'redux/actions';
import { currentImmoSelectors, filtersSelectors } from 'redux/selectors';

import { actions } from './filesCreationReducer';

import request from '../../utils/sagas/request';

const fileTypes = {
  'application/pdf': 'PDF',
};

export function* createFilesFlow({ payload: { files } }) {
  yield put(setIsGlobalLoading(true));
  try {
    const subFolder = yield select(filtersSelectors.groupSelector);
    const immoId = yield select(currentImmoSelectors.idSelector);

    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];

      const { data: signedLinkData } = yield request({
        method: 'post',
        url: '/document-upload-links/_generate',
        data: {
          file_type: fileTypes[file.type],
          file_name: file.name,
          immo_id: immoId,
        },
      });

      yield axios({
        method: 'put',
        url: signedLinkData.signed_link,
        data: file,
        headers: {
          'Content-Type': file.type,
          'x-goog-if-generation-match': 0,
        },
      });

      yield request({
        method: 'post',
        url: '/files',
        data: {
          immo_id: immoId,
          parent_id: subFolder,
          name: file.name,
          type: 'file',
          gcs_object_id: signedLinkData.gcs_object_id,
        },
      });
    }
    yield put(actions.createFilesSuccess());
    NM.success('Files uploaded');
    yield put(fileListActions.getFileListRequest({ page: 1 }));
  } catch (e) {
    yield put(actions.createFilesFailure());
    const responseData = e.response?.data;

    if (responseData?.title) {
      NM.error(responseData?.details, responseData?.title);
    } else {
      NM.error('Something went wrong');
    }
  }
  yield put(setIsGlobalLoading(false));
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.createFilesRequest.type, createFilesFlow),
  ]);
}
