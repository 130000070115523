import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { NotificationManager as NM } from 'react-notifications';

import { Autocomplete, TextField } from '@mui/material';
import { mainSelectors } from 'redux/selectors';

import useDocumentClasses from 'hooks/documentClasses/useDocumentClasses';

import request from 'api/request';

import useStyles from './FileTypeCellEditMode.styles';

const FileTypeCellEditMode = ({
  item, onItemUpdate, onClose,
}) => {
  const classes = useStyles();
  const fileTypes = useDocumentClasses();
  const isEn = useSelector(mainSelectors.isEnSelector);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const labelKey = isEn ? 'name' : 'name_de';

  const handleChange = async (e, newValue) => {
    setIsSubmitting(true);

    try {
      const { data } = await request({
        method: 'patch',
        url: `/files/${item.file_item_id || item.id}`,
        data: {
          document_class_id: newValue.id,
        },
      });

      onItemUpdate({ ...data, extractingMetadata: true });
      onClose();

      const { data: file } = await request({
        method: 'post',
        url: `/files/${item.id}/_extract_metadata`,
        data: {},
      });
      onItemUpdate(file);
    } catch (error) {
      NM.error('Something went wrong');
    }

    setIsSubmitting(false);
  };

  return (
    <Autocomplete
      classes={{
        paper: classes.autocompletePaper,
      }}
      disabled={isSubmitting}
      onBlur={onClose}
      open={!isSubmitting}
      getOptionKey={((option) => option.key)}
      getOptionLabel={((option) => option[labelKey])}
      loading={!fileTypes}
      options={fileTypes || []}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          autoFocus
          InputProps={{ ...params.InputProps, disableUnderline: true }}
        />
      )}
    />
  );
};

export default FileTypeCellEditMode;
