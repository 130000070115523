import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { filtersSelectors as selectors } from 'redux/selectors';
import { filtersActions as actions } from 'redux/actions';
import { useWillMount, useWillUnmount } from 'hooks';

const useFilters = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const groupFilter = useSelector(selectors.groupSelector);
  const typesFilter = useSelector(selectors.typesSelector);
  const immosFilter = useSelector(selectors.immosSelector);

  useWillMount(() => {
    dispatch(actions.setGroupFilter({ value: searchParams.get('group') || null }));
    dispatch(actions.setTypeFilter({ value: searchParams.get('types')?.split(',') || [] }));
    dispatch(actions.setImmosFilter({ value: searchParams.get('immos')?.split(',') || [] }));
  });

  useEffect(() => {
    if (groupFilter && groupFilter !== 'scans') {
      searchParams.set('group', groupFilter);
    } else {
      searchParams.delete('group');
    }

    if (typesFilter.length > 0) {
      searchParams.set('types', typesFilter);
    } else {
      searchParams.delete('types');
    }

    if (immosFilter.length > 0) {
      searchParams.set('immos', immosFilter);
    } else {
      searchParams.delete('immos');
    }

    setSearchParams(searchParams);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupFilter, typesFilter, immosFilter]);

  useWillUnmount(() => {
    actions.reset();
  });
};

export default useFilters;
