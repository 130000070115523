import React, { useState } from 'react';

import { Tabs, Tab } from '@mui/material';
import { JsonView, defaultStyles } from 'react-json-view-lite';

import { useForm } from 'react-hook-form';

import { NotificationManager as NM } from 'react-notifications';

import { setAnswersSettings } from 'utils/answersSettings';

import AnswersCommonRulesForm from './AnswersCommonRulesForm';
import AnswersFileItemRulesForm from './AnswersFileItemRulesForm';

function AdvisorConfigForm({
  defaultValues, disabled, json, onSuccess,
}) {
  const [activeTab, setActiveTab] = useState('common');

  const { control, handleSubmit, watch } = useForm({
    values: defaultValues,
  });

  const validate = () => {
    NM.error('Validation failed');
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const onSubmit = (values) => {
    setAnswersSettings({
      ...values,
      file_item_rules: {
        ...values.file_item_rules,
        top_results_amount: Number(values.file_item_rules.top_results_amount),
        evidence_cut_off_score: Number(values.file_item_rules.evidence_cut_off_score),
      },
    });
    NM.success('Config updated');
    if (onSuccess) {
      onSuccess();
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'common':
        return (
          <AnswersCommonRulesForm control={control} watch={watch} />
        );
      case 'file-item-rules':
        return (
          <AnswersFileItemRulesForm control={control} watch={watch} />
        );
      case 'json':
        return (
          <JsonView data={json} style={defaultStyles} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Tabs value={activeTab} sx={{ mx: 3, mb: 3 }} onChange={handleTabChange}>
        <Tab label="Common" value="common" />
        <Tab label="File item rules" value="file-item-rules" />
        {json && <Tab label="JSON" value="json" />}
      </Tabs>
      <form id="answers-config" onSubmit={handleSubmit(onSubmit, validate)}>
        <fieldset style={{ border: 'none' }} disabled={disabled ? 'disabled' : undefined}>
          {renderTabContent()}
        </fieldset>
      </form>
    </>
  );
}

export default AdvisorConfigForm;
