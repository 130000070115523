import {
  Calendar,
  Hash,
  Building2,
  CircleDollarSign,
  BadgeDollarSign,
  Percent,
  ArrowDownCircle,
  Wallet,
  User,
  MapPin,
  Receipt,
  Circle,
  Home,
  Building,
  ShieldAlert,
  AlertTriangle,
  FileSignature,
  Banknote,
  Bell,
  ClipboardList,
  Phone,
  CreditCard,
  TrendingUp,
  Wrench,
  ClipboardCheck,
  RefreshCw,
  FileText,
  Users,
  Clock,
  Scale,
  Gavel,
  Stamp,
  ShieldOff,
  Mountain,
  Square,
  Flag,
  Ruler,
  TreePine,
  Car,
  X,
  Plus,
  Minus,
  Accessibility,
  CheckSquare,
  HelpCircle,
  LayoutDashboard,
  PieChart,
  CheckCircle,
  LineChart,
  Gauge,
  Zap,
  Shield,
  Key,
  Settings,
  LayoutGrid,
  Maximize2,
  Droplets,
  Flame,
  ListTodo,
  FileSearch,
  Sparkles,
  Dog,
  Image,
  Package,
  Factory,
  Box,
  Target,
  FilePlus,
  Paperclip,
  Heading,
  BarChart,
  Star,
  Database,
  Calculator,
  Mail,
  MessagesSquare,
  Activity,
  Search,
  Lock,
  PiggyBank,
  ArrowLeftRight,
  XCircle,
  AlertOctagon,
  HeadphonesIcon,
  MessageCircle,
  Fuel,
  Map,
  MessageSquare,
  Footprints,
  Lightbulb,
  FlaskConical,
} from 'lucide-react';

export const iconMap = {
  shortPropertyAddress: MapPin,
  landlordContacts: Phone,
  renterName: User,
  renterAddress: MapPin,
  renterContacts: Phone,
  signatureDate: Calendar,
  rentalStartDate: Calendar,
  rentalEndDate: Calendar,
  monthlyRentalPrice: CircleDollarSign,
  paymentCurrency: Banknote,
  additionalCosts: BadgeDollarSign,
  additionalCostServices: CircleDollarSign,
  extraordinaryTerminationConditions: AlertTriangle,
  maintenanceAndRepairs: Wrench,
  handoverProtocol: ClipboardCheck,
  landlordName: User,
  landlordAddress: MapPin,
  propertyId: Hash,
  propertyType: Home,
  propertyDescription: ClipboardList,
  renewalOptions: RefreshCw,
  rentalPaymentDueDate: Calendar,
  paymentMethod: CreditCard,
  rentIncreasesInfo: TrendingUp,
  securityDeposit: Wallet,
  securityDepositConditions: FileText,
  ordinaryTerminationConditions: FileText,
  useTerms: FileText,
  optionalClauses: FileText,
  propertyAddress: MapPin,
  documentDate: Calendar,
  summary: FileText,
  summaryDe: FileText,
  number: Hash,
  billingAddress: MapPin,
  serviceDateStart: Calendar,
  taxAmount: Receipt,
  currency: Banknote,
  dueDate: Calendar,
  provider: Building2,
  client: User,
  serviceDateEnd: Calendar,
  netAmount: CircleDollarSign,
  totalAmount: BadgeDollarSign,
  lawParagraphs: Scale,
  cashDiscount: Percent,
  reduction: ArrowDownCircle,
  paymentTerms: Wallet,
  thirdParties: Users,
  services: ClipboardList,
  servicePlace: MapPin,
  netPrice: CircleDollarSign,
  date: Calendar,
  startDate: Calendar,
  endDate: Calendar,
  period: Clock,
  vatReference: Receipt,
  paymentModalities: CreditCard,
  conditionsAndDeadlines: Clock,
  assumedEncumbrancesAndRestrictions: AlertTriangle,
  nameBuyer: User,
  withdrawConditions: FileText,
  liabilityForDefects: ShieldAlert,
  addressSeller: MapPin,
  additionalAgreements: FileText,
  contractualPenalties: Gavel,
  price: CircleDollarSign,
  nameSeller: User,
  notarisation: Stamp,
  addressBuyer: MapPin,
  warrantyExclusions: ShieldOff,
  priorNoticeOfConveyance: Bell,
  handoverDate: Calendar,
  handoverArrangements: ClipboardList,
  topographicalFeatures: Mountain,
  parcelNumbers: Hash,
  propertyBoundaries: Square,
  outlinesOfBuildings: Building,
  siteDesignations: Flag,
  scale: Ruler,
  parcelMapType: Map,
  district: MapPin,
  typeOfLanduse: TreePine,
  parcelNumber: Hash,
  size: Maximize2,
  parcel: Square,
  dateOfRegistration: Calendar,
  parkingSpaceEncumbrances: Car,
  spacingAreaEncumbrances: Maximize2,
  landlord: User,
  cancellations: X,
  superstructureEncumbrances: Building2,
  inclusionOfAncillaryAreas: Plus,
  considerationOfDeductions: Minus,
  determinationOfTheFloorAreas: Ruler,
  propertyOwner: User,
  sizeOfTheProperty: Ruler,
  typeOfUse: Home,
  descriptionOfLocation: MapPin,
  buildingInformation: Building2,
  accessibility: Accessibility,
  buildingSupervisoryAuthority: Building2,
  existingUse: Home,
  dimensions: Ruler,
  descriptionDetails: FileText,
  nameOwner: User,
  addressOwner: MapPin,
  proofOfComplianceWithBuildingLawRequirements: CheckSquare,
  expertReports: FileText,
  reasonOfChange: HelpCircle,
  roomLayoutsAndLocation: LayoutDashboard,
  necessityOrValueOfChange: HelpCircle,
  addressOfTheBuilding: MapPin,
  addressApplicant: MapPin,
  descriptionNewUse: FileText,
  nameApplicant: User,
  rightsOfUse: Key,
  allocationOfTheCommonProperty: Users,
  managementAndMaintenance: Settings,
  description: FileText,
  votingPrinciple: Users,
  propertyPlan: LayoutGrid,
  coOwnershipShares: PieChart,
  location: MapPin,
  buildingType: Building,
  heatingConstructionYear: Calendar,
  isDemandOriented: CheckCircle,
  isConsumptionOriented: CheckCircle,
  annualEnergyRequirementSqm: LineChart,
  validUntil: Calendar,
  energyEfficientClass: Gauge,
  livingSpace: Maximize2,
  residentialUnits: Building2,
  energySource: Zap,
  buildingConstructionYear: Calendar,
  energyConsumption: LineChart,
  policyHolder: User,
  insuredPersonOrObject: Shield,
  termsAndConditions: FileText,
  insuranceBenefits: Shield,
  sumsInsured: CircleDollarSign,
  insuranceStartDate: Calendar,
  terminationRights: FileText,
  policyholderObligations: ClipboardList,
  bankDetails: Building2,
  insurer: Building2,
  insurancePremium: CircleDollarSign,
  deductibles: Percent,
  insuranceIssuedAt: Calendar,
  insuranceEndDate: Calendar,
  periodOfTime: Clock,
  detailedRegulations: FileText,
  discount: Percent,
  policyType: FileText,
  meterReadingsElectricity: Zap,
  damageAndDefects: AlertTriangle,
  specialAgreementsOrNotes: FileText,
  contactTenantPurchaser: Phone,
  meterReadingsWater: Droplets,
  conditionOfRooms: ClipboardList,
  photosOfCondition: Image,
  keys: Key,
  contactOwnerLandlord: Phone,
  meterReadingsGas: Flame,
  startTime: Clock,
  endTime: Clock,
  shortAgenda: ListTodo,
  meetingDate: Calendar,
  meetingAddress: MapPin,
  participants: Users,
  accountName: User,
  addressAccountHolder: MapPin,
  otherIdentificationData: FileSearch,
  accountType: CreditCard,
  bankName: Building2,
  interestRates: Percent,
  accountStatementNumber: Hash,
  bankAccountNumber: Hash,
  openingBalance: CircleDollarSign,
  closingBalance: CircleDollarSign,
  iban: Hash,
  cleanliness: Sparkles,
  restPeriods: Clock,
  changesToTheRentalProperty: Home,
  parking: Car,
  behaviorInCaseOfDamage: AlertTriangle,
  animalHusbandry: Dog,
  useOfCommonFacilities: Users,
  specificConditions: FileText,
  measurementsAndObservations: Ruler,
  proceduresAndStandards: ClipboardList,
  data: Database,
  result: CheckCircle,
  summaryOfResults: FileText,
  testEquipmentSettings: Settings,
  typeOfTestedItem: Box,
  manufacturer: Factory,
  idNumber: Hash,
  materialUsed: Package,
  testOrder: ClipboardList,
  waterConsumptionPreviousPeriod: Droplets,
  totalCostsOthers: CircleDollarSign,
  billingPeriod: Calendar,
  heatingConsumptionPreviousPeriod: Flame,
  heatingAndHotWaterCosts: CircleDollarSign,
  fuelConsumption: Gauge,
  paymentOnAccount: Wallet,
  listOfUsers: Users,
  heatingConsumption: Flame,
  distributionIndex: PieChart,
  remainingPayment: CircleDollarSign,
  invoiceNumber: Hash,
  waterConsumption: Droplets,
  totalCostsHeating: CircleDollarSign,
  fuelConsumptionPreviousYear: Gauge,
  fuelType: Fuel,
  totalCostsWater: CircleDollarSign,
  tenant: User,
  allocationKeyPerItem: Key,
  prepaymentAmount: CircleDollarSign,
  serviceChargeStatementPeriod: Calendar,
  additionalPaymentOrRepayment: CircleDollarSign,
  serviceChargeStatement: Receipt,
  unit: Box,
  address: MapPin,
  ownerOfWhichUnit: User,
  contactDetails: Phone,
  representative: User,
  firstName: User,
  lastName: User,
  additionalPayment: CircleDollarSign,
  nonApportionedCosts: CircleDollarSign,
  houseMoneyAmount: CircleDollarSign,
  reserveAmount: CircleDollarSign,
  apportionedCosts: CircleDollarSign,
  tenantName: User,
  shortDescription: FileText,
  expectedSolution: Target,
  legalValidityAndSignatures: FileSignature,
  reasonForTheCancellation: FileText,
  handoverOfKeys: Key,
  serviceChargeSettlement: Receipt,
  waiverOfClaims: Scale,
  depositAndRepayment: Wallet,
  returnOfTheRentedProperty: Home,
  contactInformationForTheProcess: Phone,
  deadlineForVacatingTheProperty: Calendar,
  dateOfTerminationOfTheTenancy: Calendar,
  nameAndAddressOfTheContractingParties: Users,
  dateOfTheCancellationAgreement: Calendar,
  outstandingRentPaymentsOrClaims: CircleDollarSign,
  titleOfTheOperatingInstructions: FileText,
  instructionVersion: Hash,
  productModel: Box,
  amendmentsOrAdditions: FilePlus,
  legalBasesOrOrdinances: Scale,
  secretary: User,
  personResponsibleForImplementation: User,
  deadlinesForImplementation: Clock,
  validityOfTheResolution: Calendar,
  listOfParticipants: Users,
  resolutionNumber: Hash,
  committee: Users,
  dateOfTheResolution: Calendar,
  documentsOrAppendices: Paperclip,
  contentOfTheResolution: FileText,
  votingResult: CheckSquare,
  titleOfTheResolution: Heading,
  addressOfApplicant: MapPin,
  creditLimit: CircleDollarSign,
  keyFinancialFigures: BarChart,
  historicalCreditRatings: LineChart,
  contactDetailsOfTheIssuingBody: Phone,
  dataSource: Database,
  enquiringBody: Building2,
  negativeFeatures: AlertTriangle,
  creditRatingOrSolvencyRating: Star,
  scoreValue: BarChart,
  paymentBehaviour: TrendingUp,
  dateOfIssueOfInformation: Calendar,
  nameOfApplicant: User,
  intendedUse: Target,
  guaranteeContractOrGuaranteeDeclaration: Shield,
  purposeOfGuarantee: Target,
  typeOfGuarantee: Shield,
  guaranteeAmount: CircleDollarSign,
  dateOfGuaranteeAgreement: Calendar,
  legalValidity: Scale,
  conditionsOfUtilisation: FileText,
  guarantorInstitutionOrPerson: Building2,
  nameOfGuarantor: User,
  addressOfGuarantor: MapPin,
  cancellationOptions: X,
  durationOfGuarantee: Clock,
  contactInformationOfGuarantor: Phone,
  legalBasis: Scale,
  quorum: Users,
  agendaItems: ListTodo,
  placeOfTheMeeting: MapPin,
  invitation: Mail,
  contactInformation: Phone,
  preparationMaterials: FileText,
  deadlinesForRegistrationOrSubmissionOfProposals: Clock,
  noteOnTheKeepingOfMinutes: FileText,
  informationOnRepresentation: Users,
  conveningPeriod: Clock,
  dateAndTimeOfTheMeeting: Calendar,
  dateOfInvitation: Calendar,
  formalRequirements: CheckSquare,
  longTermFinancialGoals: Target,
  financialPlanningPeriod: Calendar,
  interestAndReturnForecasts: LineChart,
  taxPlanning: Calculator,
  variableCosts: CircleDollarSign,
  liquidityPlanning: LineChart,
  budgetForLargePurchases: CircleDollarSign,
  sourcesOfIncome: CircleDollarSign,
  savingsAndInvestmentGoals: Target,
  riskProvision: Shield,
  emergencyReserves: CircleDollarSign,
  pensionPlan: FileText,
  debtsAndLiabilities: CircleDollarSign,
  investmentPlanning: LineChart,
  fixedCosts: CircleDollarSign,
  monitoringAndAdjustment: LineChart,
  dateOfNotification: Calendar,
  landTransferTaxAmount: CircleDollarSign,
  purchasePrice: CircleDollarSign,
  dueDateOfPayment: Calendar,
  rightOfAppeal: Scale,
  taxOfficeAndItsAddress: Building2,
  descriptionOfTheProperty: Home,
  buyerAndSeller: Users,
  fileReferenceOrNotificationNumber: Hash,
  taxRate: Percent,
  paymentReferenceOrBankDetails: CreditCard,
  taxBaseAmount: CircleDollarSign,
  typeOfProperty: Home,
  paymentDueDate: Calendar,
  addressOfTheRelevantTaxAuthority: Building2,
  dateOfAssessment: Calendar,
  paymentReferenceOrAccountDetails: CreditCard,
  propertyTaxAmount: CircleDollarSign,
  ownerInformation: User,
  municipalRate: Percent,
  propertyUnitValue: CircleDollarSign,
  referenceNumberOrTaxAssessmentNumber: Hash,
  requiredReactionsOfTheTenant: FileText,
  subjectOfTheCorrespondence: Mail,
  contactInformationOfTheSender: Phone,
  newRentAmount: CircleDollarSign,
  dateOfCorrespondence: Calendar,
  currentRentAmount: CircleDollarSign,
  recipient: User,
  optionsForAgreeingAlternatives: ListTodo,
  deadlineForObjectionOrConsent: Calendar,
  sender: User,
  adjustmentOfAncillaryCosts: CircleDollarSign,
  dateOfChange: Calendar,
  enclosureOrDocuments: Paperclip,
  reasonForTheRentChange: FileText,
  paymentInformation: CircleDollarSign,
  contentOfCorrespondence: Mail,
  subjectOfCorrespondence: Mail,
  referenceToTheTenancyAgreement: FileText,
  documentsOrAttachments: Paperclip,
  specialNotes: FileText,
  acknowledgementOfReceipt: CheckSquare,
  formOfCommunication: MessageSquare,
  landlordResponseOrProposedSolution: MessagesSquare,
  deadlines: Clock,
  tenantEnquiryOrClaim: MessageCircle,
  attachmentsOrReceipts: Paperclip,
  backPaymentOrCreditBalance: CircleDollarSign,
  contactInformationForQueries: Phone,
  totalAmountOfServiceCharges: CircleDollarSign,
  distributionKey: Key,
  notesOnTheStatuteOfLimitations: Scale,
  deadlineForObjectionsOrObjections: Clock,
  requiredResponsesFromTheTenant: MessageSquare,
  detailedListOfServiceCharges: Receipt,
  advanceServiceChargePayments: Wallet,
  consentToAdjustmentOfAdvanceServiceChargePayments: CheckSquare,
  statusOfProceedings: Activity,
  litigationRisk: AlertTriangle,
  lawyersOrRepresentatives: Users,
  legalCosts: CircleDollarSign,
  claims: FileText,
  documentationOfCommunication: FileText,
  settlementNegotiations: MessagesSquare,
  interimJudgementsOrOrders: Gavel,
  court: Building2,
  parties: Users,
  fileNumber: Hash,
  endOfProceedingsForeseeable: Clock,
  objectOfTheProceedings: Target,
  evidence: Search,
  dateOfFiling: Calendar,
  datesAndDeadlines: Calendar,
  subjectMatterOfTheDispute: FileText,
  addressOfTheRentalProperty: MapPin,
  endOfTenancyIfLimitedInTime: Calendar,
  apartmentNumberOrRentalProperty: Hash,
  startOfTenancy: Calendar,
  rentalProperty: Home,
  rentDueDate: Calendar,
  contractTerm: Clock,
  contractingParties: Users,
  changeToAncillaryCosts: CircleDollarSign,
  annexesToTheAddendum: Paperclip,
  changesToThePaymentTerms: Wallet,
  legallyBindingAndSignatures: FileSignature,
  effectiveDateOfTheChanges: Calendar,
  changeToTheRent: CircleDollarSign,
  changesToTheRentalDeposit: Wallet,
  dateOfTheAddendum: Calendar,
  referenceToTheOriginalRentalAgreement: FileText,
  specialAgreements: FileText,
  consentByBothParties: Users,
  changesToTheIntendedUse: Home,
  additionalTenantsOrResidents: Users,
  contractNumberOrReferenceNumber: Hash,
  completionDateAndSignatures: FileSignature,
  deviationsOrProblems: AlertTriangle,
  dateOfServiceProvision: Calendar,
  invoiceNumberAndDate: Receipt,
  statusOfTheOrder: Activity,
  documentationOrPhotos: Image,
  nameOfTheServiceProvider: User,
  addressOfTheServiceProvider: MapPin,
  nextStepsOrFollowUpDates: Calendar,
  performanceEvaluationOrFeedback: Star,
  legalBasisOrContractualTerms: Scale,
  serviceDescription: FileText,
  hourlyRateOrRemuneration: CircleDollarSign,
  nameOfTheDebtorMandateProvider: User,
  consentToDataProcessing: CheckSquare,
  payeeCreditor: User,
  signatureOfTheMandateProvider: FileSignature,
  ibanOfTheMandateProvider: Hash,
  creditorIdentificationNumber: Hash,
  dateOfTheSepaMandate: Calendar,
  notificationObligation: Bell,
  bankOfTheDebtor: Building2,
  creditorAccountInformation: CreditCard,
  typeOfSepaMandate: FileText,
  directDebitAuthorisation: CheckSquare,
  bicOfTheMandateProvider: Hash,
  addressOfTheMandateProvider: MapPin,
  revocationOptions: XCircle,
  mandateReference: Hash,
  amountOfArrears: CircleDollarSign,
  informationOnLegalRemedies: Scale,
  declaredAndFixedTaxAmounts: CircleDollarSign,
  calculationBases: Calculator,
  taxPeriod: Calendar,
  bankDetailsOfTheTaxAuthority: Building2,
  assessmentType: FileText,
  taxType: Receipt,
  repaymentClaim: CircleDollarSign,
  latePaymentSurchargeOrInterest: Percent,
  noticeNumberOrFileReference: Hash,
  taxpayer: User,
  paymentDeadline: Calendar,
  dateOfTheNotice: Calendar,
  refundDate: Calendar,
  fixedAdvancePayments: Wallet,
  contractualPenaltiesOrFees: Gavel,
  responsibilitiesOfTheProvider: ClipboardList,
  specialRightOfCancellation: XCircle,
  renewalClause: RefreshCw,
  billingInterval: Clock,
  scopeOfServices: ClipboardList,
  supplyOrDisposalObject: Box,
  contractAmendments: FilePlus,
  regulationsOnInterruptionOfService: AlertOctagon,
  cancellationPeriods: Calendar,
  liability: Shield,
  priceAndCostStructure: BarChart,
  contractType: FileText,
  responsibleAuthorityOrRegulatoryBody: Building2,
  signaturesOfTheContractingParties: FileSignature,
  contractNumber: Hash,
  serviceAndSupport: HeadphonesIcon,
  contractStartAndTerm: Calendar,
  extensionClause: FilePlus,
  signaturesOfTheContractingPartiesXmlPh0: FileSignature,
  managementOfReserves: PiggyBank,
  remunerationForSpecialTasks: CircleDollarSign,
  takeoverAndHandoverOfManagement: ArrowLeftRight,
  liabilityAndInsurance: Shield,
  implementationOfOwnersMeetings: Users,
  managementObject: Target,
  provisionsInTheEventOfPrematureTermination: AlertTriangle,
  startOfContractAndTerm: Calendar,
  dataProtectionAndConfidentiality: Lock,
  reportingObligations: FileText,
  administratorFee: CircleDollarSign,
  administratorAuthorisation: CheckSquare,
  additionalServicesAndCosts: CircleDollarSign,
  termOfNotice: Clock,
  annexesToTheContract: Paperclip,
  placeOfJurisdictionAndApplicableLaw: Scale,
  dutiesAndObligationsOfTheAdministrator: ClipboardList,
  rightsOfWay: Footprints,
  fixturesAndFittings: Wrench,
  recommendations: Lightbulb,
  testMethods: FlaskConical,
  rulesForRenovationsOrCosmeticRepairs: Wrench,
};

export const getIcon = (itemMetadata) => {
  if (iconMap[itemMetadata.id]) {
    return iconMap[itemMetadata.id];
  }

  if (itemMetadata.type === 'DATE') {
    return Calendar;
  }

  return Circle;
};
