import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Stack, Button, Table, TableBody, TableRow, IconButton, FormControlLabel, Switch,
} from '@mui/material';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import CloseIcon from '@mui/icons-material/Close';

import MetadataBlock from 'components/MetadataBlock';
import FileNameTableCell from 'components/FileNameTableCell';
import FileTypeCell from 'components/FileTypeCell';

import { fileListActions, inboxItemSubDocsActions } from 'redux/actions';

import PdfViewer from './PdfViewer';
import AddToBenchmarkDialog from './AddToBenchmarkDialog';

import useStyles from './FileViewDialogContent.styles';

export default function FileViewDialogContent({
  url, fileItem, onFileItemUpdate, onClose,
}) {
  const classes = useStyles();
  const [isAddToBenchmarkDialogOpen, setIsAddToBenchmarkDialogOpen] = useState(false);
  const [showMetadata, setShowMetadata] = useState(
    localStorage.getItem('immomate.previewDialog.hideMetadata') !== 'true',
  );
  const dispatch = useDispatch();

  const handleAddToBenchmark = () => {
    setIsAddToBenchmarkDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsAddToBenchmarkDialogOpen(false);
  };

  const handleFileItemUpdate = (newFileItem) => {
    dispatch(fileListActions.updateFile({ file: newFileItem }));
    dispatch(inboxItemSubDocsActions.updateFile({ fileItem: newFileItem }));
    onFileItemUpdate(newFileItem);
  };

  const renderFileName = () => (
    <div style={{ flexGrow: 1 }}>
      <Table className={classes.fileNameTable}>
        <TableBody>
          <TableRow sx={{ textWrap: 'nowrap' }}>
            <FileNameTableCell
              absoluteContent
              singleLine
              textCellSx={{ width: '100%' }}
              item={fileItem}
              forceEditMode
              onItemUpdate={handleFileItemUpdate}
            />
            {fileItem.type !== 'scan' && (
              <FileTypeCell
                item={fileItem}
                forceEditMode
                onItemUpdate={handleFileItemUpdate}
              />
            )}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );

  const handleShowMetadataChange = (event) => {
    setShowMetadata(event.target.checked);

    localStorage.setItem('immomate.previewDialog.hideMetadata', !event.target.checked);
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
      }}
    >
      <Stack
        style={{ padding: 8, fontWeight: 500 }}
        direction="row"
        spacing={2}
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        {renderFileName()}
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          {fileItem.type !== 'scan' && (
            <FormControlLabel
              control={<Switch checked={showMetadata} onChange={handleShowMetadataChange} />}
              label="Metadata"
            />
          )}
          {fileItem.type === 'file' && (
            <Button onClick={handleAddToBenchmark}>
              Add to benchmark
            </Button>
          )}
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>
      <PanelGroup autoSaveId="immomate.previewDialog.panelGroup" direction="horizontal">
        <Panel defaultSize={60} minSize={10} maxSize={90}>
          <PdfViewer
            url={url}
            fileItem={fileItem}
          />
        </Panel>
        {showMetadata && fileItem.type !== 'scan' && (
          <>
            <PanelResizeHandle />
            <Panel>
              <MetadataBlock fileItem={fileItem} onItemUpdate={handleFileItemUpdate} />
            </Panel>
          </>
        )}
      </PanelGroup>
      {isAddToBenchmarkDialogOpen && (
        <AddToBenchmarkDialog
          open
          onClose={handleCloseDialog}
          fileItem={fileItem}
        />
      )}
    </div>
  );
}
