import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

import { immoListActions as actions } from 'redux/actions';
import { immoListSelectors as selectors } from 'redux/selectors';

const ImmosFilterChips = () => {
  const dispatch = useDispatch();

  const filters = useSelector(selectors.filtersSelector);

  const chips = [];

  if (filters.only_without_address) {
    chips.push(
      <Chip
        key="only_without_address"
        label="Without address"
        onDelete={() => dispatch(actions.setFilter({ name: 'only_without_address', value: false }))}
      />,
    );
  }

  if (filters.only_with_pending_approvals) {
    chips.push(
      <Chip
        key="only_with_pending_approvals"
        label="With awaiting approvals"
        onDelete={() => dispatch(actions.setFilter({ name: 'only_with_pending_approvals', value: false }))}
      />,
    );
  }

  if (chips.length === 0) {
    return null;
  }

  return (
    <Stack direction="row" spacing="8px">
      {chips}
    </Stack>
  );
};

export default ImmosFilterChips;
