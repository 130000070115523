import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

const TextFieldController = ({
  control,
  name,
  label,
  helperText,
  rules,
  maxLength,
  multiline,
  ...otherProps
}) => {
  const handleKeyPress = (e) => {
    if (!multiline && e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          multiline={multiline}
          value={value}
          label={label}
          maxLength={maxLength}
          error={Boolean(error)}
          helperText={error?.message ? error.message : helperText}
          onKeyPress={handleKeyPress}
          onChange={onChange}
          {...otherProps}
        />
      )}
      rules={{
        required: 'Field is required',
        ...rules,
      }}
    />
  );
};

export default TextFieldController;
