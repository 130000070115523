/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  fileItemId: null,
  pageNumber: null,
};

export const slice = createSlice({
  name: 'fileViewDialog',
  initialState,
  reducers: {
    openDialog: (state, { payload: { fileItemId, pageNumber } }) => {
      state.open = true;
      state.fileItemId = fileItemId;
      state.pageNumber = pageNumber;
    },
    closeDialog: (state) => {
      state.open = false;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
