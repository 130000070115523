import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  howItWorksBlock: {
    marginTop: -10,
    height: 209,
    width: '100%',
    borderRadius: '0px 0px 10px 10px',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.17)',
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  howItWorksTitle: {
    marginTop: 69,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '26px',
  },
  howItWorksDescription: {
    marginTop: 9,
    width: 362,
    color: '#7F7F7F',
    fontSize: 15,
    lineHeight: '20px',
    textAlign: 'center',
  },
  answerBlock: {
    marginTop: 20,
    background: 'linear-gradient(90deg, #FD7B21 0%, #F3C330 40%)',
    color: '#FFF',
    padding: 13,
    width: '100%',
    borderRadius: 10,
    boxSizing: 'border-box',
    transition: 'all 0.5s',

    '&$generating': {
      background: '#F1F0F0',
      color: '#474747',
    },
  },
  generating: {},
  answerBlockTitle: {
    padding: [4, 17, 17, 17],
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '26px',
  },
  answerBlockContent: {
    minHeight: 283,
    background: '#FFF',
    borderRadius: 10,
    boxSizing: 'border-box',
    padding: [27, 17, 17, 17],

    '$generating &': {
      padding: 0,
      paddingTop: 75,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  generatingTitle: {
    marginTop: 15,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '26px',
    color: '#474747',
  },
  generatingDescription: {
    marginTop: 5,
    color: '#7F7F7F',
    fontSize: 15,
    lineHeight: '20px',
    textAlign: 'center',
  },
  answerTitle: {
    color: '#0F0F0F',
    fontSize: 15,
    lineHeight: '20px',
  },
  answerSummary: {
    marginTop: 11,
    color: '#0F0F0F',
    fontSize: 21,
    lineHeight: '24px',
  },
});

export default useStyles;
