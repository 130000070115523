import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationManager as NM } from 'react-notifications';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { fileListActions as actions } from 'redux/actions';

import request from 'api/request';

export default function FileDeletionDialog({ item, onClose }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const { data: { files } } = await request({
        method: 'post',
        url: '/files/_search',
        data: {
          origin_id: item.id,
          limit: 300,
          offset: 0,
        },
      });

      if (files.length !== 0) {
        await Promise.all((files.map((file) => request({
          method: 'delete',
          url: `/files/${file.id}`,
        }))));
      }

      await request({
        method: 'delete',
        url: `/files/${item.id}`,
      });

      dispatch(actions.deleteFile({ fileId: item.id }));
      setIsSubmitting(false);
      onClose();
    } catch (e) {
      NM.error('Something went wrong');
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
    >
      <DialogTitle id="alert-dialog-title">
        Delete file?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to delete the following file:
          <div><b>{item.name}</b></div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="error"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
