import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  workflow: {
    marginRight: 4,
  },
});

export default useStyles;
