import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import FileNameTableCell from 'components/FileNameTableCell/FileNameTableCell';
import formatDate from 'utils/formatDate';

export default function ViewUtilityDocsDialog({ utilityItem, onClose }) {
  return (
    <Dialog
      open
      maxWidth="xl"
      onClose={onClose}
    >
      <DialogTitle>
        {utilityItem.provider_name}
      </DialogTitle>
      <DialogContent>
        <TableContainer sx={{ height: '100%', minWidth: 500 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ paddingRight: 0, width: 40 }}
                  colSpan={2}
                >
                  File name
                </TableCell>
                <TableCell>
                  File type
                </TableCell>
                <TableCell>
                  Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {utilityItem.documents.map((item) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={item.file_item_id}>
                  <FileNameTableCell item={{ id: item.file_item_id, name: item.file_name }} />
                  <TableCell>
                    {item.file_type}
                  </TableCell>
                  <TableCell>
                    {formatDate(item.date)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
