import { formatDuration } from 'utils/dateTime';

const getPipelineStepTitle = (title, startedAt, finishedAt) => {
  let res = title;

  if (finishedAt) {
    res += ` (${formatDuration(startedAt, finishedAt) || '0 sec'})`;
  }

  return res;
};

export default getPipelineStepTitle;
