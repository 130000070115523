import React, { Fragment, useRef, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import SourceIcon from '@mui/icons-material/Source';

import { immoListSelectors as selectors } from 'redux/selectors';
import { immoListActions as actions } from 'redux/actions';

import useTableSettings from 'components/TableSettingsProvider/useTableSettings';
import ColumnVisibilitySelect from 'components/TableSettingsProvider/ColumnVisibilitySelect';
import TableHeadCell from 'components/UIKit/TableHeadCell';

import { energyEfficiencyColumns } from 'constants/immoList';

import EnergyEfficiencyCell from './EnergyEfficiencyCell';
import ImmoNameTableCell from './ImmoNameTableCell';

// eslint-disable-next-line no-unused-vars
import useStyles from './ImmoList.styles';
import ImmoListItemMenu from './ImmoListItemMenu';
import ImmosCharts from './ImmoCharts';

const ImmoList = ({ onSort }) => {
  const dispatch = useDispatch();
  const tableContainerRef = useRef(null);
  const items = useSelector(selectors.itemsSelector) || [];
  const sort = useSelector(selectors.sortSelector);
  const hasMore = useSelector(selectors.hasMoreSelector);
  const loading = useSelector(selectors.isLoadingSelector);
  const { visibleColumns } = useTableSettings();
  const isEfficiencyChartEnabled = visibleColumns.findIndex(({ type }) => type === 'ENERGY_EFFICIENCY') !== -1;

  const renderBodyCell = (column, item) => {
    switch (column.type) {
      case 'ADDRESS':
        return <TableCell>{item.address_details.formatted_address}</TableCell>;
      case 'documents_waiting_approval_count':
        return <TableCell align="right">{item.documents_waiting_approval_count || 0}</TableCell>;
      case 'total_documents':
        return <TableCell align="right">{item.total_documents || 0}</TableCell>;
      case 'ENERGY_EFFICIENCY':
        return energyEfficiencyColumns.map((col) => <EnergyEfficiencyCell key={col.type} column={col} item={item} />);
      default:
        return <TableCell />;
    }
  };

  const handleSortChange = (newSort) => {
    dispatch(actions.setSort({ sort: newSort }));
    dispatch(actions.getItemsRequest({}));
    onSort(newSort);
  };

  useLayoutEffect(() => {
    const tableEl = tableContainerRef.current;

    const handleScroll = () => {
      const bottom = tableEl.scrollHeight - tableEl.clientHeight;

      if (tableEl.scrollTop > bottom - 1000 && hasMore && !loading) {
        dispatch(actions.getItemsRequest({ loadMore: true }));
      }
    };

    tableEl.addEventListener('scroll', handleScroll);
    return () => {
      tableEl.removeEventListener('scroll', handleScroll);
    };
  }, [hasMore, loading, dispatch]);

  const renderHeadCell = (column) => {
    if (column.type === 'ENERGY_EFFICIENCY') {
      return energyEfficiencyColumns.map((energyColumn) => (
        <TableCell
          key={energyColumn.type}
          align={energyColumn.align}
        >
          {energyColumn.title}
        </TableCell>
      ));
    }

    return (
      <TableHeadCell
        key={column.type}
        align={column.align}
        column={column}
        sort={sort}
        onSort={handleSortChange}
      >
        {column.title}
      </TableHeadCell>
    );
  };

  return (
    <TableContainer sx={{ height: '100%' }} ref={tableContainerRef}>
      {isEfficiencyChartEnabled && <ImmosCharts />}
      <Table stickyHeader aria-label="sticky table" style={{ padding: 16 }}>
        <TableHead>
          <TableRow>
            <TableHeadCell
              style={{ paddingLeft: 0, paddingRight: 0, width: 40 }}
              colSpan={2}
              column={{ type: 'name', isSortable: true }}
              sort={sort}
              onSort={handleSortChange}
            >
              Name
            </TableHeadCell>
            {visibleColumns.map(renderHeadCell)}
            <TableCell align="right" sx={{ width: 0 }}>
              <ColumnVisibilitySelect />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={item.id}>
              <TableCell style={{ paddingRight: 0, height: 36 }}>
                <SourceIcon sx={{ color: 'rgb(95, 99, 104)', position: 'relative', top: '2px' }} />
              </TableCell>
              <ImmoNameTableCell item={item} />
              {visibleColumns.map((column) => (
                <Fragment key={column.type}>
                  {renderBodyCell(column, item)}
                </Fragment>
              ))}
              <TableCell>
                <ImmoListItemMenu immo={item} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ImmoList;
