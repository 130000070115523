const isNumber = function isNumber(value) {
  return typeof value === 'number' && Number.isFinite(value);
};

const getBarChartData = (items) => {
  const values = items.map(
    (item) => (isNumber(item.energy_efficiency?.heating_system_age) ? item.energy_efficiency.heating_system_age : null),
  );

  const validData = values.filter((v) => v !== null);

  const minX = Math.min(...validData.map((v) => v));
  const maxX = Math.max(...validData.map((v) => v));

  const midPoint = Math.round((minX + maxX) / 2);

  const groups = {
    unknown: 0,
    firstHalf: 0,
    secondHalf: 0,
  };

  values.forEach((v) => {
    if (v === null) {
      groups.unknown += 1;
    } else if (v >= minX && v <= midPoint) {
      groups.firstHalf += 1;
    } else if (v > midPoint && v <= maxX) {
      groups.secondHalf += 1;
    }
  });

  const res = [];

  if (groups.firstHalf) {
    res.push({
      name: `[${minX}, ${midPoint}]`,
      value: groups.firstHalf,
    });
  }

  if (groups.secondHalf) {
    res.push({
      name: `[${midPoint + 1}, ${maxX}]`,
      value: groups.secondHalf,
    });
  }

  if (groups.unknown) {
    res.push({
      name: 'Unknown',
      value: groups.unknown,
    });
  }

  return res;
};

export default getBarChartData;
