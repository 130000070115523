import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import useColumnsVisibility from './hooks/columnsVisibility';

export const TableSettingsContext = createContext();

const TableSettingsProvider = ({ children, tableId, columns }) => {
  const { visibleColumns, setColumnsVisibility } = useColumnsVisibility(tableId, columns);

  return (
    <TableSettingsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        columns,
        visibleColumns,
        setColumnsVisibility,
      }}
    >
      {children}
    </TableSettingsContext.Provider>
  );
};

TableSettingsProvider.propTypes = {
  children: PropTypes.any.isRequired,
  tableId: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
};

export default TableSettingsProvider;
