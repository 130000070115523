import React from 'react';

import { Box, FormGroup, Typography } from '@mui/material';

import {
  TextFieldController,
  SwitchController,
} from 'components/formControllers';

import ModelSelect from '../ModelSelect';

function AnswersCommonRulesForm({ control, watch }) {
  const isInputExpansionEnabled = watch('input_expansion_on');

  return (
    <Box sx={{ mx: 3 }}>
      <Typography variant="h6">Input expansion</Typography>
      <FormGroup>
        <SwitchController
          sx={{ mt: 1 }}
          control={control}
          name="input_expansion_on"
          label="Enabled"
        />
      </FormGroup>
      {isInputExpansionEnabled && (
        <TextFieldController
          sx={{ mb: 3, mt: 3, display: 'block' }}
          fullWidth
          control={control}
          name="input_expansion_prompt_template"
          multiline
          label="Prompt template"
          helperText="Placeholder '<question>' must be present in the template"
          rules={{
            pattern: {
              value: /(?=.*<question>)/s,
            },
          }}
        />
      )}
      <Typography variant="h6" sx={{ mt: 5 }}>Final summary</Typography>
      <ModelSelect control={control} name="summary_prompt_model" />
      <TextFieldController
        sx={{ mb: 3, mt: 3, display: 'block' }}
        fullWidth
        control={control}
        name="prompt_template"
        multiline
        label="Prompt template"
        // eslint-disable-next-line max-len
        helperText="Placeholders '<question>', '<answers>', '<number>' must be present in the template"
        rules={{
          pattern: {
            value: /(?=.*<question>)(?=.*<answers>)(?=.*<number>)/s,
          },
        }}
      />
    </Box>
  );
}

export default AnswersCommonRulesForm;
