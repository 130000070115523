import React from 'react';

import TableCell from '@mui/material/TableCell';

import { energyCertificatesColors } from 'constants/energyCertificates';
import { Chip } from '@mui/material';

const EnergyEfficiencyCell = ({ column, item }) => {
  switch (column.type) {
    case 'energy_efficient_class': {
      const value = item?.energy_efficiency[column.type];

      return (
        <TableCell>
          {value !== 'Unknown' && <Chip label={value} sx={{ backgroundColor: energyCertificatesColors[value] }} />}
        </TableCell>
      );
    }
    default:
      return (
        <TableCell>
          {item?.energy_efficiency[column.type]}
        </TableCell>
      );
  }
};

export default EnergyEfficiencyCell;
