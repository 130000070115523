import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    marginRight: 4,

    '&:not(:last-child):after': {
      content: '""',
      display: 'block',
      borderBottom: '2px solid #bfbfbf',
      position: 'absolute',
      right: -4,
      top: 13,
      width: 4,
      zIndex: 0,
    },
  },
  stepButton: {
    cursor: 'pointer',
    all: 'unset',
    display: 'block',
    height: 26,

    '& svg': {
      width: 24,
      height: 24,
    },
  },
  icon: {
    position: 'relative',
    display: 'inline-flex',
    width: 24,
    height: 24,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '50%',
    zIndex: 1,
    transition: 'all 0.1s',
  },
  created: {
    borderColor: '#B8B8B8',

    '& svg': {
      fill: '#B8B8B8',
    },

    '&:hover': {
      boxShadow: '0 0 0 1px rgb(184, 184, 184)',
      background: 'rgba(184, 184, 184, 0.2)',
    },
  },
  started: {
    borderColor: '#0F4DEE',

    '& svg': {
      fill: '#0F4DEE',
    },

    '&:hover': {
      boxShadow: '0 0 0 1px rgb(15, 77, 238)',
      background: 'rgba(15, 77, 238, 0.2)',
    },
  },
  success: {
    borderColor: '#00875A',

    '& svg': {
      fill: '#00875A',
    },

    '&:hover': {
      boxShadow: '0 0 0 1px #00875A',
      background: '#c3e6cd',
    },
  },
  error: {
    borderColor: '#DE350B',

    '& svg': {
      fill: '#DE350B',
    },

    '&:hover': {
      boxShadow: '0 0 0 1px rgb(222, 53, 11)',
      background: 'rgba(222, 53, 11, 0.2)',
    },
  },
  skipped: {
    borderColor: '#B8B8B8',

    '& svg': {
      fill: '#B8B8B8',
    },

    '&:hover': {
      boxShadow: '0 0 0 1px rgb(184, 184, 184)',
      background: 'rgba(184, 184, 184, 0.2)',
    },
  },
});

export default useStyles;
