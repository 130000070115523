import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  pages: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 16,
    margin: '8px 16px 0',
    paddingLeft: 4,
    paddingRight: 4,
    boxSizing: 'border-box',
  },
  draggableDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  highlightedPage: {
    outline: '2px solid #1976d2',
  },
  pageBackground: {
    cursor: 'pointer',
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: 'opacity 0.3s',
    background: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      opacity: 1,
    },
  },
  overlayButton: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    color: 'white',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
  zoomButton: {
    top: 10,
    right: 10,
  },
  deleteButton: {
    top: 10,
    left: 10,
  },
  rotateLeft: {
    bottom: 10,
    left: 10,
  },
  rotateRight: {
    bottom: 10,
    right: 10,
  },
  pageSplitConfidence: {
    position: 'absolute',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    top: -56,
    left: 8,
  },
  deletedIcon: {
    fontSize: 64,
    color: 'red',
    position: 'absolute',
    top: 'calc(50% - 32px)',
    left: 'calc(50% - 32px)',
    pointerEvents: 'none',
  },
});

export default useStyles;
