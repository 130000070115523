import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import throttle from 'lodash.throttle';
import cx from 'classnames';
import Highlighter from 'react-highlight-words';

import AppSearchBox from 'components/SearchBox';

import Box from '@mui/material/Box';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import HomeWork from '@mui/icons-material/HomeWork';

import { request } from 'api';

import { searchActions } from 'redux/actions';
import { searchSelectors as selectors, filtersSelectors } from 'redux/selectors';

import useFilePreview from 'hooks/useFilePreview';

import useStyles from './SearchBox.styles';

const SearchBox = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalSearchTerm = useSelector(selectors.searchTermSelector);
  const group = useSelector(filtersSelectors.groupSelector);
  const typeFilter = useSelector(filtersSelectors.typesSelector);
  const immosFilter = useSelector(filtersSelectors.immosSelector);
  const { orgName } = useParams();
  const [inputValue, setInputValue] = useState(globalSearchTerm);
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const { openFilePreview } = useFilePreview();

  const immoIds = immosFilter.map(({ id }) => id);

  useEffect(() => {
    setSuggestions([]);
  }, [group, typeFilter]);

  const searchSuggestions = useCallback(async (searchTerm) => {
    try {
      const [immosResponse, filesResponse] = await Promise.all([
        request({
          method: 'post',
          url: 'immos/_search',
          data: {
            name: searchTerm,
          },
        }),
        request({
          method: 'post',
          url: 'files/_text-search',
          data: {
            search_term: searchTerm,
            parent_ids: group ? [group] : undefined,
            document_class_ids: typeFilter.length ? typeFilter : undefined,
            immo_ids: immoIds.length ? immoIds : undefined,
          },
        }),
      ]);

      const immos = immosResponse.data.immos.map((data) => ({
        type: 'immo',
        data,
      }));

      const files = filesResponse.data.file_items.map((data) => ({
        type: 'file',
        data,
      }));

      setSuggestions([...immos, ...files]);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [setSuggestions, group, typeFilter, immoIds]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSearchSuggestions = useCallback(throttle(searchSuggestions, 1000), [group, typeFilter]);

  const handleInputChange = (e, newInputValue, reason) => {
    if (reason === 'input' || reason === 'clear') {
      setInputValue(newInputValue);

      if (newInputValue.length < 3) {
        setSuggestions([]);
      } else {
        throttledSearchSuggestions(newInputValue);
      }
    }
  };

  const getOptionContent = (option) => {
    switch (option.type) {
      case 'immo':
        return {
          name: option.data.name,
          icon: <HomeWork />,
        };
      case 'file':
        return {
          name: `${option.data.name}`,
          icon: <TextSnippetIcon />,
        };

      default:
        return null;
    }
  };

  const renderOption = (props, option) => {
    const { key, ...optionProps } = props;

    const optionContent = getOptionContent(option);

    return (
      <Box
        component="li"
        {...optionProps}
        key={option.type + option.data.id}
        className={(cx(optionProps.className, classes.option))}
      >
        {optionContent.icon}
        <Highlighter
          searchWords={inputValue.split(' ')}
          textToHighlight={optionContent.name}
        />
      </Box>
    );
  };

  const handleChange = (e, option, reason) => {
    if (reason === 'clear') {
      return;
    }

    if (typeof option === 'string') {
      e.target.blur();
      dispatch(searchActions.setSearchTerm({ value: option }));
      dispatch(searchActions.search());
      return;
    }

    switch (option.type) {
      case 'immo':
        navigate(`/${orgName}/${option.data.id}/docs`);
        break;
      case 'file':
        openFilePreview({ fileId: option.data.id });
        break;

      default:
        break;
    }
  };

  const handleKeyDown = (e) => {
    if (document.querySelector('.MuiAutocomplete-option.Mui-focused')) {
      return;
    }

    if (e.keyCode === 27 || e.code === 'Escape') {
      setInputValue('');
      setSuggestions([]);
    }
  };

  return (
    <AppSearchBox
      className={classes.root}
      inputValue={inputValue}
      suggestions={suggestions}
      renderOption={renderOption}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onInputChange={handleInputChange}
    />
  );
};

export default SearchBox;
