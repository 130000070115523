import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  superWrapper: {
    position: 'relative',
    width: 56,
    transition: 'width 0.3s ease',
    height: '100%',
    boxSizing: 'border-box',
  },
  expanded: {
    width: 204,
  },
  wrapper: {
    background: '#ffffff',
    height: '100%',
    flexShrink: 0,
    padding: [16, 8],
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.17), 0px 3px 8px 0px rgba(0, 0, 0, 0.17)',
    overflow: 'hidden',
  },
  divider: {
    height: 1,
    marginBottom: 24,
    backgroundColor: '#D4D4D4',
    margin: [0, 8],
  },
  content: {
    padding: [0, 8],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'calc(100% - 65px)',
  },
  topLinks: {
    display: 'flex',
    flexDirection: 'column',
    color: '#788293',
    fontSize: 12,
  },
  bottomLinks: {
    display: 'flex',
    flexDirection: 'column',
  },
  expandCollapseButton: {
    position: 'absolute',
    top: -15,
    right: -15,
    boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.17), 0px 3px 8px 0px rgba(0, 0, 0, 0.17)',
    background: 'white',

    '&:hover': {
      backgroundColor: '#F9FAFB',
    },

    '& svg': {
      width: 20,
      height: 20,
      transition: 'transform 0.3s',
      transform: 'rotate(180deg)',

      '$expanded &': {
        transform: 'rotate(0)',
      },
    },
  },
});

export default useStyles;
