import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import cx from 'classnames';

import { Stack, Box } from '@mui/material';

import Layout from 'components/Layout';

import { searchSelectors as selectors } from 'redux/selectors';
import { searchActions as actions } from 'redux/actions';

import useDidMount from 'hooks/useDidMount';

import SearchCard from 'pages/SearchPage/SearchCard';
import { useWillUnmount } from 'hooks';
import DocsTypeFilter from './DocsTypeFilter';
import DocsGroupFilter from './DocsGroupFilter';
import ImmosFilter from './ImmosFilter';

import DocsFilterChips from './DocsFilterChips';
import SearchTypeSwitcher from './SearchTypeSwitcher';
import SearchResultsViewTypeSwitcher from './SearchResultsViewTypeSwitcher';
import SearchResultsTable from './SearchResultsTable';

import SearchBox from './SearchBox';

import useStyles from './SearchPage.styles';

const SearchPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const results = useSelector(selectors.resultsSelector);
  const searchResultId = useSelector(selectors.searchResultIdSelector);
  const appliedSearchTerm = useSelector(selectors.appliedSearchTermSelector);
  const isLoading = useSelector(selectors.isLoadingSelector);
  const viewType = useSelector(selectors.viewTypeSelector);

  useDidMount(() => {
    const resultId = searchParams.get('r');

    if (resultId) {
      dispatch(actions.loadSearchResult({ id: resultId }));
    } else {
      dispatch(actions.search());
    }
  });

  useWillUnmount(() => {
    dispatch(actions.reset());
  });

  useEffect(() => {
    if (searchResultId) {
      searchParams.set('r', searchResultId);

      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResultId]);

  const renderResults = () => {
    if (!results) {
      return null;
    }

    if (results.length === 0) {
      return <Box sx={{ margin: '16px' }}>No results</Box>;
    }

    if (viewType === 'cards') {
      return (
        <Stack spacing="16px" className={classes.resultList}>
          {results.map((result) => (
            <SearchCard key={result.file_item_id} result={result} searchTerm={appliedSearchTerm} />
          ))}
        </Stack>
      );
    }

    return <SearchResultsTable results={results} />;
  };

  return (
    <Layout
      mainInnerClassName={cx(classes.mainInner, viewType === 'cards' && classes.mainInnerCardsView)}
      filterChipsClassName={classes.filterChips}
      app="search"
      isInitialLoading={isLoading}
      searchBox={(
        <div className={classes.searchBoxContainer}>
          <Stack direction="row" spacing="16px" sx={{ alignItems: 'center' }}>
            <SearchBox />
            <ImmosFilter />
            <DocsTypeFilter />
            <DocsGroupFilter />
          </Stack>
          <Stack direction="row" sx={{ justifyContent: 'space-between', mt: '16px' }}>
            <SearchTypeSwitcher />
            <SearchResultsViewTypeSwitcher />
          </Stack>
        </div>
      )}
      filterChips={<DocsFilterChips />}
    >
      {renderResults()}
    </Layout>
  );
};

export default SearchPage;
