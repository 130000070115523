/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  onSuccess: null,
};

export const slice = createSlice({
  name: 'currentImmo',
  initialState,
  reducers: {
    openDialog: (state, { payload: { onSuccess } = {} }) => {
      state.open = true;
      if (onSuccess) {
        state.onSuccess = onSuccess;
      }
    },
    closeDialog: () => initialState,
  },
});

export const { actions } = slice;

export default slice.reducer;
