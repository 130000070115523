import { all, fork } from 'redux-saga/effects';

import filesCreation from './files/filesCreation/filesCreationSaga';
import fileList from './files/fileList/fileListSaga';
import inboxList from './inbox/inboxList/inboxListSaga';
import inboxCreation from './inbox/inboxCreation/inboxCreationSaga';
import fileSplitting from './files/fileSplitting/fileSplittingSaga';
import immoList from './immos/immoList/immoListSaga';
import inboxItemSubDocs from './inbox/inboxItemSubDocs/inboxItemSubDocsSaga';
import approvalList from './approvals/approvalList/approvalListSaga';
import search from './search/searchSaga';
import processingPopup from './processingPopup/processingPopupSaga';

export default function* root() {
  yield all([
    fork(filesCreation),
    fork(fileList),
    fork(fileSplitting),
    fork(immoList),
    fork(inboxList),
    fork(inboxCreation),
    fork(inboxItemSubDocs),
    fork(approvalList),
    fork(search),
    fork(processingPopup),
  ]);
}
