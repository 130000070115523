export const COLUMN_LIST = [
  {
    type: 'DESCRIPTION',
    title: 'Description',
    isDefaultVisible: true,
  },
  {
    type: 'TYPE',
    title: 'Type',
    isDefaultVisible: true,
  },
  {
    type: 'PAGES',
    title: 'Pages',
    align: 'right',
  },
  {
    type: 'CREATOR',
    title: 'Creator',
  },
  {
    type: 'DATE_ADDED',
    title: 'Date added',
  },
  {
    type: 'SIZE',
    title: 'Size',
    align: 'right',
  },
  {
    type: 'IMMO',
    title: 'Immo',
    align: 'left',
  },
  {
    type: 'APPROVAL',
    title: 'Approval',
    align: 'left',
  },
];
