import React, { useRef } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import useTableSettings from 'components/TableSettingsProvider/useTableSettings';
import ColumnVisibilitySelect from 'components/TableSettingsProvider/ColumnVisibilitySelect';

import useMetadataColumns from 'hooks/filesTable/useMetadataColumns';

import FileRaw from './FileRaw';

// eslint-disable-next-line no-unused-vars
import useStyles from './SearchResultsTable.styles';

const FilesTable = ({ results }) => {
  const tableContainerRef = useRef(null);

  const { visibleColumns } = useTableSettings();

  const metadataColumns = useMetadataColumns();

  const renderColumn = (column) => {
    if (column.type === 'METADATA') {
      return metadataColumns.map(((metadataColumn) => (
        <TableCell key={metadataColumn.id}>
          {metadataColumn.name}
        </TableCell>
      )));
    }

    return (
      <TableCell key={column.type} align={column.align} sx={{ minWidth: column.minWidth }}>
        {column.title}
      </TableCell>
    );
  };

  const getItem = (item) => ({
    ...item,
    id: item.file_item_id,
    name: item.file_item_name,
    ai_generated_type: item.document_class_name,
    document_type_de: item.document_class_name_de,
  });

  return (
    <TableContainer sx={{ height: '100%' }} ref={tableContainerRef}>
      <Table stickyHeader style={{ padding: 16 }}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{ paddingLeft: 0, paddingRight: 0, width: 40 }}
              colSpan={2}
            >
              Name
            </TableCell>
            {visibleColumns.map((column) => renderColumn(column))}
            <TableCell sx={{ width: 0 }} align="right">
              <ColumnVisibilitySelect />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((item) => (
            <FileRaw
              key={item.file_item_id}
              item={getItem(item)}
              metadataColumns={metadataColumns}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FilesTable;
