import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from 'components/UIKit';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { NotificationManager as NM } from 'react-notifications';

import { JsonView, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import {
  LinearProgress,
} from '@mui/material';

import makeClassificationTestBenchRequests from './utils/makeClassificationTestBenchRequests';
import fillFilesInfo from './utils/fillFilesInfo';
import createSheetWithSuccessRateByExpectedType from './utils/createSheetWithSuccessRateByExpectedType';
import fillMainSheetWithResults from './utils/fillMainSheetWithResults';

function getCurrentDateTimeInYYYYMMDDHHMM() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  return `${year}${month}${day}_${hours}${minutes}`;
}

const EvidenceClassificationTestBenchDialog = ({ requestsData: { docs, configs, workbook }, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [maxParallelRequests, setMaxParallelRequests] = useState('10');
  const { orgName } = useParams();

  const [successRequestCount, setSuccessRequestCount] = useState(0);
  const [failedRequestCount, setFailedRequestCount] = useState(0);

  const requests = useMemo(() => configs.map(({ id, ...config }) => docs.map((doc) => ({
    doc,
    config,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }))), []);

  const { user } = useAuth0();

  const userName = user?.name;

  const handleSubmit = async () => {
    setIsLoading(true);

    const sheet = workbook.getWorksheet(1);

    await fillFilesInfo(sheet, docs, orgName);

    const results = await makeClassificationTestBenchRequests(
      requests,
      Number(maxParallelRequests),
      () => setSuccessRequestCount((n) => n + 1),
      () => setFailedRequestCount((n) => n + 1),
    );

    fillMainSheetWithResults(sheet, results);

    createSheetWithSuccessRateByExpectedType(workbook, configs, results);

    try {
      // eslint-disable-next-line no-param-reassign
      workbook.views[0] = {
        ...workbook.views[0],
        activeTab: 0,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    const modifiedBuffer = await workbook.xlsx.writeBuffer();
    // eslint-disable-next-line max-len
    const blob = new Blob([modifiedBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const blobUrl = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    const safeUserName = userName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    downloadLink.download = `Classification_result_${getCurrentDateTimeInYYYYMMDDHHMM()}_${safeUserName}.xlsx`;
    downloadLink.click();

    NM.success('Success');

    setIsLoading(false);

    onClose();
  };

  const formValid = maxParallelRequests;

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {`Classification requests (configs: ${configs.length}, docs: ${docs.length})`}
      </DialogTitle>
      <DialogContent>
        {isLoading && <LinearProgress />}
        {isLoading && (
          <div>
            Total:
            {' '}
            {configs.length * docs.length}
            ; Success:
            {' '}
            {successRequestCount}
            ; Failure:
            {' '}
            {failedRequestCount}
          </div>
        )}
        <TextField
          sx={{ display: 'flex', mb: 2 }}
          label="Max parallel requests"
          type="number"
          variant="standard"
          value={maxParallelRequests}
          onChange={(e) => setMaxParallelRequests(e.target.value)}
        />
        <JsonView data={requests} style={defaultStyles} shouldInitiallyExpand={(level) => level < 2} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>Cancel</Button>
        <Button variant="contained" disabled={isLoading || !formValid} onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EvidenceClassificationTestBenchDialog;
