import {
  all, put, takeLatest, select,
} from 'redux-saga/effects';

import { actions } from './processingPopupReducer';
import * as selectors from './processingPopupSelectors';

import request from '../utils/sagas/request';

export function* fetchProcessingTasks() {
  const filesById = yield select(selectors.filesByIdSelector);

  try {
    const { data } = yield request({
      method: 'post',
      url: 'tasks/_search',
      data: {
        processing_task_types: ['split_preparation'],
        processing_task_statuses: ['processing'],
        my_only: true,
      },
    });

    const tasks = [];
    const fileIds = [];

    data.task_groups.forEach((item) => {
      tasks.push(item.tasks[0]);

      if (!filesById[item.file_item_id]) {
        fileIds.push(item.file_item_id);
      }
    });

    const newFilesById = {};

    if (fileIds.length > 0) {
      const { data: filesData } = yield request({
        method: 'post',
        url: '/files/_search',
        data: {
          file_item_ids: fileIds,
        },
      });

      filesData.files.forEach((file) => {
        newFilesById[file.id] = file;
      });
    }

    yield put(actions.fetchProcessingTasksSuccess({ tasks, newFilesById }));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.fetchProcessingTasks.type, fetchProcessingTasks),
  ]);
}
