import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { CurrentScanContext } from 'context/currentScanContext';
import { useDidMount } from 'hooks';
import { NotificationManager as NM } from 'react-notifications';

import { request } from 'api';

export const withCurrentScan = (Component) => (props) => {
  const [scan, setScan] = useState(null);
  const { scanId, orgName } = useParams();
  const navigate = useNavigate();

  const fetchScan = async () => {
    try {
      const { data } = await request({
        method: 'get',
        url: `files/${scanId}`,
      });
      setScan(data);
    } catch (e) {
      if (e.response?.status === 404) {
        NM.error('Scan not found');
        navigate(`/${orgName}`);
      } else {
        NM.error('Something went wrong');
      }
    }
  };

  useDidMount(() => {
    fetchScan();
  });

  return (
    <CurrentScanContext.Provider value={scan}>
      <Component {...props} scan={scan} />
    </CurrentScanContext.Provider>
  );
};
