import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { NotificationManager as NM } from 'react-notifications';

import { useParams } from 'react-router-dom';

import request from 'api/request';

import { immoListActions as actions } from 'redux/actions';

import EditableTextCell from 'components/UIKit/EditableCell';

const ImmoNameTableCell = ({ item }) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { orgName } = useParams();

  const handleSubmit = async (newName, { onSuccess }) => {
    setIsSubmitting(true);

    try {
      const { data } = await request({
        method: 'patch',
        url: `/immos/${item.id}`,
        data: {
          name: newName,
        },
      });
      dispatch(actions.updateItem({ immo: data }));
      onSuccess();
    } catch (error) {
      NM.error('Something went wrong');
    }

    setIsSubmitting(false);
  };

  return (
    <EditableTextCell
      style={{ fontWeight: 500 }}
      value={item.name}
      displayValue={item.name}
      isSubmitting={isSubmitting}
      to={`/${orgName}/${item.id}/overview`}
      onChange={handleSubmit}
    />
  );
};

export default ImmoNameTableCell;
