import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  downloadButton: {
    visibility: 'hidden',

    '.MuiTableRow-root &': {
      visibility: 'visible',
    },
  },
});

export default useStyles;
