import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function FileViewDialogContent({ url, fileItem }) {
  const [searchParams] = useSearchParams();

  const highlight = searchParams.get('fp_highlight');
  const pageNumber = searchParams.get('fp_page');

  const getKeywords = () => {
    if (!highlight) {
      return [];
    }

    return highlight.split(' ');
  };

  const transform = (slot) => ({
    ...slot,
    ShowPropertiesMenuItem: () => null,
    Open: () => null,
    OpenMenuItem: () => null,
  });

  // eslint-disable-next-line no-use-before-define
  const renderToolbar = (Toolbar) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      getFilePlugin: {
        fileNameGenerator: () => fileItem.name,
      },
      searchPlugin: {
        keyword: getKeywords(),
      },
    },
    renderToolbar,
  });

  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

  return (
    <Viewer
      fileUrl={url}
      initialPage={pageNumber}
      plugins={[defaultLayoutPluginInstance]}
    />
  );
}
