import { useContext } from 'react';
import { TableSettingsContext } from './TableSettingsProvider';

const useTableSettings = () => {
  const columnsVisibilityObj = useContext(TableSettingsContext);

  return columnsVisibilityObj;
};

export default useTableSettings;
