import { COLUMN_LIST as FILE_LIST_COLUMN_LIST } from './fileList';

export const COLUMN_LIST = [
  ...FILE_LIST_COLUMN_LIST,
  {
    type: 'APPROVAL',
    title: 'Approval',
    align: 'left',
    isAlwaysVisible: true,
  },
];
