import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

import FileNameTableCell from 'components/FileNameTableCell/FileNameTableCell';

import formatDate from 'utils/formatDate';

const RentInfoTable = ({ items }) => (
  <TableContainer sx={{ height: '100%' }}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          <TableCell
            style={{ paddingRight: 0, width: 40 }}
            colSpan={2}
          >
            File name
          </TableCell>
          <TableCell>
            Renters
          </TableCell>
          <TableCell>
            Start date
          </TableCell>
          <TableCell>
            End date
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
            <FileNameTableCell item={{ id: item.file_item_id, name: item.file_name }} />
            <TableCell>
              <Stack sx={{ mt: 1 }} direction="row">
                {item.renters?.map((renter) => (
                  <Chip key={renter} label={renter} />
                ))}
              </Stack>
            </TableCell>
            <TableCell>
              {formatDate(item.start_date)}
            </TableCell>
            <TableCell>
              {formatDate(item.end_date)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default RentInfoTable;
