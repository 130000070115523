/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import {
  Menu, MenuItem, Avatar,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import useStyles from './AvatarMenu.styles';

const AvatarMenu = () => {
  const classes = useStyles();

  const { logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleClose();
    logout({ returnTo: window.location.origin });
  };

  return (
    <>
      <a className={classes.root} onClick={handleClick}>
        <Avatar
          sx={{ padding: 0, width: 32, height: 32 }}
          src={user.picture}
        />
      </a>
      <Menu disableAutoFocusItem anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default AvatarMenu;
