import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

const AskImmoPage = () => {
  const { immoId, orgName } = useParams();

  return (
    <Navigate to={`/${orgName}/${immoId}/scans`} replace />
  );
};

export default AskImmoPage;
