import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    maxWidth: 'none',
  },
  option: {
    '& svg': {
      marginRight: 8,
    },
  },
});

export default useStyles;
