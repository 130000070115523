/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import {
  IconButton, Typography, Chip,
} from '@mui/material';

import LinkOffIcon from '@mui/icons-material/LinkOff';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import Thumbnail from './Thumbnail';

import useStyles from './DraggableSubDocPage.styles';

const DraggableSubDocPage = ({
  page,
  pageIndex,
  confidence,
  highlighted,
  selected,
  isSelectedDragging,
  countSelectedPages,
  isLastPageInSubDoc,
  base64,
  preparationTaskId,
  pdfObj,
  metaKeyPressed,
  zoom,
  onZoomClick,
  onDeleteClick,
  onRotateLeftClick,
  onRotateRightClick,
  onSplitClick,
  onSelectToggle,
}) => {
  const classes = useStyles();

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteClick();
  };

  const handleRotateLeftClick = (e) => {
    e.stopPropagation();
    onRotateLeftClick();
  };

  const handleRotateRightClick = (e) => {
    e.stopPropagation();
    onRotateRightClick();
  };

  const handlePageBackgroundClick = () => {
    if (!metaKeyPressed) {
      onZoomClick();
    } else {
      onSelectToggle();
    }
  };

  const renderDraggableInner = (dragProvided, dragSnapshot, { isDragging }) => (
    <div
      ref={dragProvided.innerRef}
      key={page.number}
      className={classes.draggableDiv}
      {...dragProvided.draggableProps}
      {...dragProvided.dragHandleProps}
    >
      <div
        id={`page-number-${page.number}`}
        className={cx(
          classes.pageWrapper,
          highlighted && classes.highlightedPage,
          selected && classes.selectedPage,
          isSelectedDragging && !isDragging && classes.isDragging,
        )}
      >
        <Thumbnail
          pdfObj={pdfObj}
          pageNumber={page.number}
          rotation={page.rotation}
          taskId={preparationTaskId}
          base64={base64}
          zoom={zoom}
        />
        <div
          className={classes.pageBackground}
          onClick={handlePageBackgroundClick}
        >
          {!metaKeyPressed && (
            <>
              <IconButton
                className={cx(classes.overlayButton, classes.zoomButton)}
                onClick={() => onZoomClick()}
              >
                <ZoomInIcon />
              </IconButton>
              <IconButton
                className={cx(classes.overlayButton, classes.deleteButton)}
                onClick={(e) => handleDeleteClick(e)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                className={cx(classes.overlayButton, classes.rotateLeft)}
                onClick={(e) => handleRotateLeftClick(e)}
              >
                <RotateLeftIcon />
              </IconButton>
              <IconButton
                className={cx(classes.overlayButton, classes.rotateRight)}
                onClick={(e) => handleRotateRightClick(e)}
              >
                <RotateRightIcon />
              </IconButton>
            </>
          )}
        </div>
        {isDragging && countSelectedPages > 1 && (
          <Chip
            className={classes.countSelectedPages}
            color="primary"
            label={`+${countSelectedPages - 1}`}
          />
        )}
        {page.marked_for_deletion && (
          <CloseIcon className={classes.deletedIcon} />
        )}
      </div>
      <div
        style={{
          height: 40,
          position: 'relative',
          visibility: (dragSnapshot.isDragging || isLastPageInSubDoc) ? 'hidden' : 'visible',
        }}
      >
        <Typography className={classes.pageSplitConfidence}>
          {confidence}
        </Typography>
        <IconButton onClick={() => onSplitClick()}>
          <LinkOffIcon />
        </IconButton>
      </div>
      {dragProvided.placeholder}
    </div>
  );

  return (
    <Draggable key={page.number} draggableId={String(page.number)} index={pageIndex}>
      {(dragProvided, dragSnapshot) => {
        if (dragSnapshot.isDragging) {
          return createPortal(
            renderDraggableInner(dragProvided, dragSnapshot, { isDragging: true }),
            document.body,
          );
        }

        return renderDraggableInner(dragProvided, dragSnapshot, {});
      }}
    </Draggable>
  );
};

export default DraggableSubDocPage;
