import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { Tooltip } from '@mui/material';

import useStyles from './NavigationItem.styles';

const NavigationItem = ({
  name, icon, active, linkTo, isExpanded, onClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tooltip title={isExpanded ? '' : name} placement="right">
        <Link
          to={linkTo || undefined}
          className={cx(classes.link, active && classes.activeLink)}
          onClick={onClick}
        >
          <div className={classes.icon}>{icon}</div>
          <div className={classes.name}>{name}</div>
        </Link>
      </Tooltip>
    </div>
  );
};

NavigationItem.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  active: PropTypes.bool,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
};

NavigationItem.defaultProps = {
  active: undefined,
  linkTo: undefined,
  onClick: undefined,
};

export default NavigationItem;
