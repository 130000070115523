import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';

import {
  Modal, IconButton, Button, ButtonGroup,
  Stack,
} from '@mui/material';

import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Page as PdfPage } from 'react-pdf';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { fileSplittingSelectors } from 'redux/selectors';
import { fileSplittingActions as actions } from 'redux/actions';

import Thumbnail from './Thumbnail';

import useStyles from './PreviewPageDialog.styles';

const PreviewPageDialog = ({
  subDocs, pdfObj, defaultSubDocIndex, defaultPageIndex, base64ByPageNumber, onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const pagesInfoByNumber = useSelector(fileSplittingSelectors.pagesInfoByNumberSelector);
  const [subDocIndex, setSubDocIndex] = useState(defaultSubDocIndex);
  const [pageIndex, setPageIndex] = useState(defaultPageIndex);

  const subDoc = subDocs[subDocIndex];

  const isFirstPageInSubDoc = pageIndex === 0;
  const isLastPageInSubDoc = pageIndex === subDoc.pages.length - 1;

  const isFirstPage = subDocIndex === 0 && pageIndex === 0;
  const isLastPage = subDocIndex === subDocs.length - 1 && isLastPageInSubDoc;

  const page = subDoc.pages[pageIndex];
  const pageNumber = page.number;

  const handleLeftClick = () => {
    if (isFirstPage) {
      return;
    }
    if (!isFirstPageInSubDoc) {
      setPageIndex(pageIndex - 1);
    } else {
      const newSubDocIndex = subDocIndex - 1;

      setSubDocIndex(newSubDocIndex);
      setPageIndex(subDocs[newSubDocIndex].pages.length - 1);
    }
  };

  const handleRightClick = () => {
    if (isLastPage) {
      return;
    }
    if (!isLastPageInSubDoc) {
      setPageIndex(pageIndex + 1);
    } else {
      setSubDocIndex(subDocIndex + 1);
      setPageIndex(0);
    }
  };

  const handleJoinBeforeClick = () => {
    dispatch(actions.joinSubDocs({ subDocIndex: subDocIndex - 1 }));

    const newSubDocIndex = subDocIndex - 1;

    setSubDocIndex(newSubDocIndex);
    setPageIndex(subDocs[newSubDocIndex].pages.length);
  };

  const handleSplitBeforeClick = () => {
    dispatch(actions.splitPages({ subDocIndex, pageIndex: pageIndex - 1 }));

    setSubDocIndex(subDocIndex + 1);
    setPageIndex(0);
  };

  const handleJoinAfterClick = () => {
    dispatch(actions.joinSubDocs({ subDocIndex }));
  };

  const handleSplitAfterClick = () => {
    dispatch(actions.splitPages({ subDocIndex, pageIndex }));
  };

  const handleDeleteClick = () => {
    dispatch(actions.deletePage({ subDocIndex, pageIndex }));
  };

  const handleRotateLeftClick = () => {
    dispatch(actions.rotateLeftPage({ subDocIndex, pageIndex }));
  };

  const handleRotateRightClick = () => {
    dispatch(actions.rotateRightPage({ subDocIndex, pageIndex }));
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      event.preventDefault();
      event.stopPropagation();

      switch (event.keyCode) {
        case 37: // Left arrow key code
          handleLeftClick();
          break;
        case 39: // Right arrow key code
          handleRightClick();
          break;
        case 32:
          if (!isFirstPage) {
            if (isFirstPageInSubDoc) {
              handleJoinBeforeClick();
            } else {
              handleSplitBeforeClick();
            }
          }
          break;
        case 8:
          dispatch(actions.deletePage({ subDocIndex, pageIndex }));
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subDocs, subDocIndex, pageIndex, page]);

  useEffect(() => {
    dispatch(actions.setHighlightedPageNumber({ pageNumber }));

    document.getElementById(`page-number-${pageNumber}`).scrollIntoView({ behavior: 'smooth', block: 'center' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  return (
    <Modal className={classes.root} open disableRestoreFocus onClose={handleClose}>
      <div>
        <TransformWrapper key={pageNumber}>
          <TransformComponent>
            {/* eslint-disable-next-line */}
            <div className={classes.zoomContainer} onClick={handleClose}>
              {/* eslint-disable-next-line */}
              <div className={classes.pageWrapper} onClick={(e) => { e.stopPropagation(); }}>
                {!isFirstPage && (
                  <Thumbnail
                    className={classes.prevPage}
                    base64={base64ByPageNumber[pageNumber - 1]}
                    rotation={pagesInfoByNumber[pageNumber - 1]?.rotation || 0}
                    isAnotherDoc={isFirstPageInSubDoc}
                  />
                )}
                {pdfObj && (
                  <PdfPage
                    pdf={pdfObj}
                    pageNumber={pageNumber}
                    rotate={pagesInfoByNumber[pageNumber]?.rotation || 0}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                )}
                {!isLastPage && pdfObj && (
                  <Thumbnail
                    className={classes.nextPage}
                    base64={base64ByPageNumber[pageNumber + 1]}
                    rotation={pagesInfoByNumber[pageNumber + 1]?.rotation || 0}
                    isAnotherDoc={isLastPageInSubDoc}
                  />
                )}
              </div>
            </div>
          </TransformComponent>
        </TransformWrapper>
        <IconButton
          className={cx(classes.overlayButton, classes.closeButton)}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {page?.marked_for_deletion && (
          <CloseIcon className={classes.markedForDeletion} />
        )}
        <Stack className={classes.toolbox} direction="row" spacing="16px">
          <ButtonGroup variant="contained" className={classes.buttonGroup}>
            <Button variant="contained" disabled={isFirstPage} onClick={handleLeftClick}>
              <KeyboardArrowLeftIcon />
            </Button>
          </ButtonGroup>
          <ButtonGroup variant="contained" className={classes.buttonGroup}>
            <Button
              sx={{ width: '130px' }}
              disabled={isFirstPage}
              onClick={isFirstPageInSubDoc ? handleJoinBeforeClick : handleSplitBeforeClick}
            >
              {isFirstPageInSubDoc ? 'Join' : 'Split'}
              {' before'}
            </Button>
            <Button onClick={handleRotateLeftClick}>
              <RotateLeftIcon />
            </Button>
            <Button onClick={handleRotateRightClick}>
              <RotateRightIcon />
            </Button>
            <Button onClick={handleDeleteClick}>
              <DeleteIcon />
            </Button>
            <Button
              sx={{ width: '130px' }}
              disabled={isLastPage}
              onClick={isLastPageInSubDoc ? handleJoinAfterClick : handleSplitAfterClick}
            >
              {isLastPageInSubDoc ? 'Join' : 'Split'}
              {' after'}
            </Button>
          </ButtonGroup>
          <ButtonGroup variant="contained" className={classes.buttonGroup}>
            <Button variant="contained" disabled={isLastPage} onClick={handleRightClick}>
              <KeyboardArrowRightIcon />
            </Button>
          </ButtonGroup>
        </Stack>
      </div>
    </Modal>
  );
};

export default PreviewPageDialog;
