import React, { useState, useEffect } from 'react';
import { Thumbnail as ReactPdfThumbnail } from 'react-pdf';

import { request } from 'api';
import { useDidMount } from 'hooks';
import { Buffer } from 'buffer';
import rotateBase64Image from 'utils/base64/rotateBase64Image';

const Thumbnail = ({
  pageNumber, taskId, rotation, base64: base64Prop, pdfObj, zoom,
}) => {
  const [base64, setBase64] = useState(base64Prop);
  const [finalBase64, setFinalBase64] = useState();
  const [isError, setIsError] = useState(false);

  const resolveBase64 = async () => {
    try {
      const response = await request({
        method: 'get',
        url: `tasks/${taskId}/images/${pageNumber}`,
        params: {
          height: 360,
        },
        cache: true,
        responseType: 'arraybuffer',
      });

      // eslint-disable-next-line no-buffer-constructor, max-len
      setBase64(`data:${response.headers['content-type']};base64,${new Buffer(response.data).toString('base64')}`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setIsError(true);
    }
  };

  useDidMount(() => {
    if (!base64Prop) {
      resolveBase64();
    }
  });

  useEffect(() => {
    if (base64) {
      if (rotation === 0) {
        setFinalBase64(base64);
      } else {
        rotateBase64Image(base64, rotation, (newBase64) => {
          setFinalBase64(newBase64);
        });
      }
    }
  }, [base64, rotation]);

  if (isError) {
    if (!pdfObj) {
      return null;
    }

    return (
      <ReactPdfThumbnail
        pdf={pdfObj}
        height={180 * zoom}
        pageNumber={pageNumber}
        rotate={rotation}
        loading={null}
      />
    );
  }

  if (!finalBase64) {
    return null;
  }

  return <img alt="thumbnail" style={{ height: 180 * zoom }} src={finalBase64} />;
};

export default Thumbnail;
