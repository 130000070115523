import React from 'react';

import Stack from '@mui/material/Stack';

import MetadataCell from 'components/MetadataCell';

import SimpleBar from 'components/UIKit/SimpleBar';

import { getIcon } from './utils/getIcon';

export default function MainMetadata({ fileItem, onItemUpdate }) {
  const metadata = fileItem.ai_metadata;

  const renderItem = (itemMetadata) => {
    const Icon = getIcon(itemMetadata);

    return (
      <div key={itemMetadata.id} className="flex items-center gap-3">
        {Icon && <Icon className="w-5 h-5 text-gray-500 flex-shrink-0" />}
        <div className="flex-1">
          <div className="text-sm font-medium text-gray-500 capitalize">
            {itemMetadata.name}
          </div>
          <MetadataCell
            sx={{
              padding: 0, mt: '4px', display: 'block', border: 'none',
            }}
            forceEditMode
            alwaysEditMode
            fileItem={fileItem}
            metadata={itemMetadata}
            max3Lines={false}
            withPlaceholder
            onFileItemUpdate={onItemUpdate}
          />
        </div>
      </div>
    );
  };

  return (
    <SimpleBar style={{ height: '100%' }}>
      <Stack sx={{ mx: 2, py: 2 }} spacing="16px">
        {[...metadata.metadata_list]
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map(renderItem)}
      </Stack>
    </SimpleBar>
  );
}
