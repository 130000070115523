export const idToName = {
  overview: 'Overview',
  approvals: 'Approvals',
  docs: 'Documents',
  scans: 'Scans',
  ask: 'Ask ImmoMate',
};

export const documentFilters = {
  EIGENTUMER: 'Eigentümer',
  STAMMADATEN_ZUR_IMMOBILIE: 'Stammdaten zur Immobilie',
  VERTRAGE_AND_VOLLMACHTEN: 'Verträge & Vollmachten',
  OBJEKTBEGEHUNGED_ZAHLERSTANDE: 'Objektbegehungen Zählerstände',
  JAHRESABRECHNUNG_WIRTSCHAFTSPLAN: 'Jahresabrechnung & Wirtschaftsplan',
  VERSAMMLUNG_AND_BESCHLUSSE: 'Versammlung & Beschlüsse',
  DIENSTLEISTER: 'Dienstleister',
  BUCHHALTUNG: 'Buchhaltung',
  INSTANDSETZUNG_UND_HALTUNG: 'Instandsetzung und haltung & Rechtsstreitigkeiten',
  OTHER: 'Other',
};
