import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, FormControlLabel, Switch } from '@mui/material';

const SwitchController = ({
  control,
  name,
  label,
  ...otherProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <FormControl {...otherProps}>
        <FormControlLabel
          label={label}
          control={(
            <Switch
              size="large"
              checked={value}
              onChange={onChange}
            />
          )}
        />
      </FormControl>
    )}
  />
);

export default SwitchController;
