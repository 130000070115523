import request from 'api/request';

import pLimit from 'p-limit';

async function classifyDocument(req, onSuccess, onFailure) {
  try {
    const { data } = await request({
      method: 'post',
      url: `/ai-test/files/${req.doc.file_id}/_get-ai-answer`,
      data: req.config,
    });

    const { suggestedType } = JSON.parse(data.answer);
    const { expectedType } = req.doc;

    onSuccess();

    return {
      expectedType,
      suggestedType,
      isSuccess: expectedType === suggestedType,
    };
  } catch (error) {
    onFailure();
    // eslint-disable-next-line no-console
    console.error(error);

    return null;
  }
}

const makeClassificationTestBenchRequests = (requests, limit, onSuccess, onFailure) => {
  const limitInst = pLimit(limit);

  const classifyDocsForOneConfig = (arr) => Promise.all(
    arr.map((item) => limitInst(() => classifyDocument(item, onSuccess, onFailure))),
  );

  return Promise.all(requests.map((group) => classifyDocsForOneConfig(group)));
};

export default makeClassificationTestBenchRequests;
