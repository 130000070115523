import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import AddIcon from '@mui/icons-material/Add';

import Layout from 'components/Layout';
import SearchBox from 'components/SearchBox';
import TableSettingsProvider from 'components/TableSettingsProvider';

import { immoListActions as actions, creationImmoDialogActions } from 'redux/actions';
import { immoListSelectors as selectors } from 'redux/selectors';

import { COLUMN_LIST } from 'constants/immoList';

import { useDidMount } from 'hooks';

import ImmosAddressFilter from './ImmosAddressFilter';
import ImmosApprovalsFilter from './ImmosApprovalsFilter';
import ImmosFilterChip from './ImmosFilterChips';
import ImmoListPageInner from './ImmoListPageInner';

import useStyles from './ImmoListPage.styles';

const ImmoListPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useSelector(selectors.filtersSelector);

  useDidMount(() => {
    dispatch(actions.setFilters({
      value: {
        only_without_address: Boolean(searchParams.get('only_without_address')),
        only_with_pending_approvals: Boolean(searchParams.get('only_with_pending_approvals')),
      },
    }));
  });

  useEffect(() => {
    try {
      const sortFromLocalStorage = localStorage.getItem('immomate.immolist.sort');

      if (sortFromLocalStorage) {
        const sort = JSON.parse(sortFromLocalStorage);

        dispatch(actions.setSort({ sort }));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    dispatch(actions.getItemsRequest({}));

    if (filters.only_without_address) {
      searchParams.set('only_without_address', 1);
    } else {
      searchParams.delete('only_without_address');
    }

    if (filters.only_with_pending_approvals) {
      searchParams.set('only_with_pending_approvals', 1);
    } else {
      searchParams.delete('only_with_pending_approvals');
    }

    setSearchParams(searchParams);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleSearch = (searchTerm) => {
    dispatch(actions.setSearchTerm({ value: searchTerm }));
    dispatch(actions.getItemsRequest({}));
  };

  const handleCreateClick = () => {
    dispatch(creationImmoDialogActions.openDialog({
      onSuccess: (newImmo) => dispatch(actions.addItem({ newImmo })),
    }));
  };

  return (
    <Layout
      app="immos"
      searchBox={(
        <Stack direction="row" spacing="16px" sx={{ alignItems: 'center' }}>
          <SearchBox onSearch={handleSearch} />
          <ImmosAddressFilter />
          <ImmosApprovalsFilter />
        </Stack>
      )}
      filterChips={<ImmosFilterChip />}
      newButton={(
        <Button
          className={classes.createButton}
          component="label"
          startIcon={<AddIcon />}
          onClick={handleCreateClick}
        >
          Create
        </Button>
      )}
      withEditMode
    >
      <TableSettingsProvider tableId="immos" columns={COLUMN_LIST}>
        <ImmoListPageInner />
      </TableSettingsProvider>
    </Layout>
  );
};

export default ImmoListPage;
