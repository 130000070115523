import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material';

import FileNameTableCell from 'components/FileNameTableCell';

import formatDate from 'utils/formatDate';
import { getChangeEvents } from '../../../api/services/coreService';

const FileItemMetadataChangeEventsTable = () => {
  const [changeEvents, setChangeEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    const loadChangeEvents = async () => {
      try {
        const response = await getChangeEvents({
          changeSubjectTypes: ['METADATA_FIELD'],
          offset: currentPage * pageSize,
          limit: pageSize,
        });
        setChangeEvents(response.data.items);
        setTotalCount(response.data.total);
      } catch (error) {
        console.error('Error fetching change events:', error);
      }
    };

    loadChangeEvents();
  }, [currentPage]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ paddingLeft: 0, paddingRight: 0, width: 40 }}
                colSpan={2}
              >
                Name
              </TableCell>
              <TableCell>
                Field
              </TableCell>
              <TableCell>
                From
              </TableCell>
              <TableCell>
                To
              </TableCell>
              <TableCell>
                Triggered by
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                Triggered at
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {changeEvents.map((item) => (
              <TableRow key={item.id}>
                <FileNameTableCell
                  item={{
                    id: item.file_item_id,
                    name: item.file_name,
                  }}
                />
                <TableCell>
                  {item.metadata_field_description_name}
                </TableCell>
                <TableCell>
                  {item.value_from}
                </TableCell>
                <TableCell>
                  {item.value_to}
                </TableCell>
                <TableCell>
                  {item.triggered_by}
                </TableCell>
                <TableCell>
                  {formatDate(item.triggered_at)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[pageSize]}
      />
    </>
  );
};

export default FileItemMetadataChangeEventsTable;
