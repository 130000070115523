/* eslint-disable max-len */

export const semanticClassificationPrompt = `"Analyze the provided text from a document related to real estate in Germany. You are to:
1. Determine the most fitting document type from the following options:
'Long-term rental invoice' (A long-term rental invoice refers to a regularly recurring invoice for the rent of a commercial property and represents a continuous financial obligation that is typically paid monthly. It does not differ from a normal invoice in terms of formal requirements, but is only issued once for several service periods (usually rental months).)
'Fieldmap' (A cadastral map, also known as a property map, parcel map or cadastral map, is an official register that shows the property boundaries, location and size of parcels of land within a cadastral district in detail.)
'Register of building encumbrances' (A building charge is a voluntary obligation of a property owner, entered in the register of building charges, to the building authority to provide certain services, tolerations or omissions that go beyond public building law and affect the property. These obligations are usually aimed at complying with certain building regulations or ensuring that another property can be built on.)
'Change of use applications' (Change of use applications are formal applications that are submitted to the relevant building supervisory authority if a property or part of it is to be used in a way that deviates from the originally authorised use.)
'Declaration of partition' (A declaration of division is a legal document that is in the context of ownership in a condominium owners' association (WEG) and serves to divide a property into individual condominium units, typically flats or commercial units, and common property, such as stairwells, gardens or roof areas.)
'Insurance policies' (is a contractual document that formalises an insurance agreement between a policyholder and an insurance company. This document sets out the exact terms and conditions, sums insured, premiums, terms and scope of the insurance.)
'Handover protocol' (A handover protocol is a written document drawn up between the previous owner or landlord and the new owner or tenant when a property, be it a flat, a house or a commercial property, is handed over. It serves to precisely document the condition of the property at the time of handover and to record which fixtures and fittings are handed over.)
'Meter report' (A document in which measured values, such as the consumption of electricity, gas, water or heating, which are read at home, are recorded.)
'Bankaccount Statement' (A statement of account is a document produced by banks or other financial institutions that provides an overview of all transactions in a bank account for a specific period of time)
'Technical Check Report' (An inspection report is a documented record that summarizes the results of inspections, tests or assessments of installations or systems in a building.)
'Rentcontract' (A rental agreement is an agreement between a landlord and a tenant that regulates the right to use a property or object in return for payment of rent for a certain period of time; this can involve a wide variety of objects, such as flats, houses, commercial premises or even vehicles, and often includes details on conditions such as the rental period, notice periods, ancillary costs and regulations on maintenance and repairs.)
'Service Contract' (A service contract is a contractual agreement between two parties in which one party (the service provider) undertakes to provide certain services for the other party (the client), while the client undertakes to pay remuneration for these services in return. The contract specifies the type of services to be provided, the remuneration, the term of the agreement and the rights and obligations of both parties. Examples of service contracts in connection with a property are management contracts for property management, construction and managemenservice contracts for repairs and maintenance, maintenance contracts for regular maintenance of technical systems and equipment such as heating or lifts, contracts for cleaning and maintenance of outdoor facilities and energy supply contracts for gas, oil, district heating and photovoltaics.)
'Invoice' (Accounting records include all documents and records required for the financial accounting of the property. Typical accounting records include invoices, cash receipts, handwritten receipts and proof of performance.)
'Buycontract' (A property purchase agreement is a legally binding document that governs the purchase and sale of a property between a seller and a buyer. This contract sets out the conditions under which the property is transferred and includes essential details such as price, descriptions of the property, payment terms, transfer date and any warranties or representations. The contract must always be notarised.)
'Calculation of living space' (A living space calculation is a procedure for determining the total area that can be used as living space in a flat or house.  In Germany, for example, the Living Space Ordinance (WoFlV) is an important basis for calculating living space.)
'Real estate cadastre extract' (A real estate cadastre extract is an official document that originates from the real estate cadastre and provides detailed information about a specific property.)
'Energy certificate' (The energy performance certificate assesses the energy requirements or energy consumption of a building and contains important information about the energy efficiency class, the primary energy requirement, the heating and energy source systems used and recommended measures to improve energy efficiency.)
'Meeting minutes' (Minutes of a meeting are a written report that records the main contents, decisions and events of a meeting, assembly, conference or other formal meeting.)
'Houserules' (House rules are a set of rules that regulate the use of a residential building or residential complex by residents and their guests and form part of the tenancy agreement or owners' association agreement.)
'Other' (None of the provided document types are suitable for this document)
'Consumption Invoice (Consumption Statement)' (The billing service provider prepares consumption-based bills for the property manager or owner, primarily for heating, hot water, cold water or other consumption-based uses. These bills are used for the service charge settlement.  Well-known service providers in Germany for preparing bills are ISTA and Brunata. There are many external service providers who prepare such bills.)
'Service Charge Statement' (The service charge statement is used to distribute the costs for operating costs and other expenses associated with the property between the tenant and landlord. This detailed statement provides transparent information about the various cost items and how they are distributed among the tenants according to the apportionment key. In the service charge statement, all apportionable costs are distributed among the contractual partners of a property. Apportionable costs are; water and waste water costs; heating costs and costs for the central hot water supply, garbage collection, street cleaning and garbage collection, house cleaning and vermin control, garden maintenance, lighting, chimney cleaning, insurance, janitor, elevator costs, communal antenna or broadband cable connection:. According to legal requirements, the annual service charge statement is mandatory for landlords. )
'Master Data Form (Property)' (Real estate master data is basic data that remains unchanged over a longer period of time. Real estate master data primarily includes: property data (address, plot size, type of use); building data (year of construction, number of units, storeys, features); technical data (heating type, energy certificate, sanitary facilities); financial data (purchase price, current market value, taxes, rental income); legal data (owner, land register data, rights of use))
'Master Data Form (Owner)' (Owner master data is basic data that remains unchanged over a longer period of time. Owner master data is primarily: legal owner (person, community or a company); contact details of the owner (address, telephone number, contact person); ownership details (ownership shares, rights of use, acquisition date, purchase price); legal data (land register excerpt, commercial register, passport documents, construction plans and permits))
'Master Data Form (Tenant)' (Tenant master data is basic data that remains unchanged over a longer period of time. Tenant master data primarily includes: name, contact details, nationality, occupation, previous address, employer, proof of income, proof of creditworthiness, tenant exemptions.)
'House Money Statement' (The house allowance statement is the service charge statement of a condominium owners' association (WEG). The house allowance, also known as housing allowance, is the amount that each condominium owner pays regularly to cover the ongoing operating costs and the costs of maintaining and managing the common property.  All costs are distributed among the owners of a condominium owners' association (WEG) in the house allowance statement. Such costs are consumption-related costs such as: water and sewage costs; heating costs and costs for central hot water supply, garbage collection, street cleaning and garbage collection, house cleaning and vermin control, garden maintenance, lighting, chimney cleaning, insurance, janitor, elevator costs, communal antenna or broadband cable connection. In addition to consumption-related costs, maintenance, renovation and extension costs are also distributed. )
'House Money Plan' (A business plan for a condominium owners' association (WEG) is a predetermined financial plan that lists the expected income and expenditure of the association for the coming financial year. This document is a central management tool for the administration of the common property and is used to determine the financial requirements and the contribution amount for each member of the community.)
'Complaint' (A property complaint is a document where tenants, owners, or neighbors express dissatisfaction about conditions or behavior. Tenants typically complain about maintenance issues, contract breaches, or noisy neighbors. Owners may complain about unpaid rent, property damage, or rule violations by tenants. Neighbors often complain about noise or disruptive behavior. The purpose of these complaints is to resolve issues and improve the quality of living for all parties involved.)
'Reminder' (In accounting, a dunning letter is a document or message sent to a customer or business partner to remind them of an overdue payment. A dunning letter is usually sent when an invoice has not been settled within the agreed payment period. It serves as a formal notice that the debtor is in default and is obliged to pay the outstanding amount. Typical features of a reminder in accounting are: reference to the original invoice or claim; reminder of the amount due and the original due date; indication of a new deadline for payment; often with a reference to possible further steps, such as default interest or legal action. Synonyms in an accounting context: reminder, payment reminder, payment request. Reminders are often organised in several stages (1st reminder, 2nd reminder, etc.), with the tone and consequences becoming more severe at each stage.)
Please return the value 'Other' if none of the above types are suitable
2. Generate a suggested file name that accurately summarizes the document's content. The file name should follow this format: ""Date-Description"" with ""Description"" giving insight into the document's content, and ""Date"" in the YYYYMMDD format. Only use alphanumeric characters and hyphens. Return the result in German
3. Generate summary of the document in 2-3 sentences in English language
4. Generate summary of the document in 2-3 sentences in German language

Based on your analysis, provide the output in this structured json format (please use only double quotes). Answer only with json, do not use any comments:

{
  ""suggestedName"": ""<Suggested File Name>"",
  ""suggestedType"": ""<Suggested Document Type>"",
  ""summary"": ""<Summary in English>"",
  ""summaryDe"": ""<Summary in German>""
}

{text}"`;
