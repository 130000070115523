import React, { useState } from 'react';

import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';

import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

import EditableTextCell from 'components/UIKit/EditableCell';

import useFilePreview from 'hooks/useFilePreview';

import Pipeline from './Pipeline';

import pdfIcon from './pdf-icon.png';

const FileNameTableCell = ({
  absoluteContent,
  singleLine,
  textCellSx,
  item,
  forceEditMode,
  hiddenCapabilities,
  pipeline,
  onItemUpdate,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isGenerating = item.isNameGenerating;
  const { openFilePreview } = useFilePreview();

  const handleSubmit = async (newName, { onSuccess }) => {
    setIsSubmitting(true);

    try {
      const { data } = await request({
        method: 'patch',
        url: `/files/${item.id}`,
        data: {
          name: `${newName}.pdf`,
        },
      });
      onItemUpdate(data);
      onSuccess();
    } catch (error) {
      NM.error('Something went wrong');
    }

    setIsSubmitting(false);
  };

  const handleClick = () => {
    openFilePreview({ fileId: item.file_item_id || item.id });
  };

  const renderPipeline = () => {
    if (!item.status || item.status === 'COMPLETED' || !pipeline) {
      return null;
    }

    return (
      <Pipeline pipeline={pipeline} />
    );
  };

  const renderContent = () => {
    if (hiddenCapabilities?.name) {
      return (
        <TableCell>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation={item.inProgress ? 'wave' : false} />
          {renderPipeline()}
        </TableCell>
      );
    }

    return (
      <EditableTextCell
        absoluteContent={absoluteContent}
        singleLine={singleLine}
        sx={textCellSx}
        style={{ fontWeight: 500 }}
        value={item.name.replace(/\.[^/.]+$/, '')}
        displayValue={item.name}
        isSubmitting={isSubmitting}
        isProcessing={isGenerating}
        forceEditMode={forceEditMode}
        onChange={handleSubmit}
        onClick={handleClick}
      />
    );
  };

  return (
    <>
      <TableCell style={{
        paddingRight: 0, height: 36, width: 0, paddingTop: 17,
      }}
      >
        <img alt="" src={pdfIcon} style={{ width: 16, height: 16, display: 'block' }} />
      </TableCell>
      {renderContent()}
    </>
  );
};

export default FileNameTableCell;
