import React, { useState } from 'react';

import { Dialog } from 'components/UIKit';

import { resetAnswerSettings } from 'utils/answersSettings';
import SettingsDialogContent from './SettingsDialogContent';

function AdvisorSettingsDialog({ open, onClose }) {
  const [contentRenderKey, setContentRenderKey] = useState(0);

  const handleReset = () => {
    resetAnswerSettings();
    setContentRenderKey((prev) => prev + 1);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          height: '100%',
        },
      }}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <SettingsDialogContent key={contentRenderKey} onReset={handleReset} onClose={onClose} />
    </Dialog>
  );
}

export default AdvisorSettingsDialog;
