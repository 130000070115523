export const COLUMN_LIST = [
  {
    type: 'PAGES',
    title: 'Pages',
    align: 'right',
  },
  {
    type: 'CREATOR',
    title: 'Creator',
  },
  {
    type: 'DATE_ADDED',
    title: 'Date added',
  },
  {
    type: 'SIZE',
    title: 'Size',
    align: 'right',
  },
];
