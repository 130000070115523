import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Autocomplete,
} from '@mui/material';
import { NotificationManager as NM } from 'react-notifications';
import { request } from 'api';
import useDocumentClasses from 'hooks/documentClasses/useDocumentClasses';

const BENCHMARK_TYPES = ['CLASSIFICATION', 'METADATA_EXTRACTION'];

export default function AddToBenchmarkDialog({ open, onClose, fileItem }) {
  const [benchmarkType, setBenchmarkType] = useState('CLASSIFICATION');
  const [expectedType, setExpectedType] = useState(null);
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const documentClasses = useDocumentClasses() || [];

  const handleSubmit = async () => {
    if (!expectedType) {
      NM.error('Expected type is required');
      return;
    }

    setIsSubmitting(true);
    try {
      await request({
        url: `/ai-test/files/${fileItem.id}/_add-to-benchmark`,
        method: 'POST',
        data: {
          benchmark_type: benchmarkType,
          document_class_id: expectedType.id,
          comment,
        },
      });
      NM.success('Successfully added to benchmark');
      onClose();
    } catch (error) {
      NM.error('Something went wrong during adding to benchmark');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add to Benchmark</DialogTitle>
      <DialogContent>
        <Box sx={{
          display: 'flex', flexDirection: 'column', gap: 2, minWidth: 300, mt: 2,
        }}
        >
          <FormControl fullWidth>
            <InputLabel>Benchmark Type</InputLabel>
            <Select
              value={benchmarkType}
              onChange={(e) => setBenchmarkType(e.target.value)}
              label="Benchmark Type"
            >
              {BENCHMARK_TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {benchmarkType === 'CLASSIFICATION' && (
            <Autocomplete
              options={documentClasses}
              getOptionLabel={(option) => option.name}
              value={expectedType}
              onChange={(event, newValue) => {
                setExpectedType(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Expected Type"
                  required
                  error={!expectedType}
                  helperText={!expectedType ? 'This field is required' : ''}
                />
              )}
            />
          )}
          <TextField
            label="Comment"
            multiline
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            inputProps={{ maxLength: 1000 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting || !expectedType}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
