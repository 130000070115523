export default function fillMainSheetWithResults(sheet, results) {
  results.forEach((resultItems, index) => {
    // eslint-disable-next-line no-param-reassign
    sheet.getColumn(3 + index).width = 30;

    let successCount = 0;

    resultItems.forEach((result, resultItemIndex) => {
      const row = sheet.getRow(2 + resultItemIndex);
      const valueCell = row.getCell(4 + index * 2);
      const statusCell = row.getCell(4 + index * 2 + 1);

      valueCell.font = { bold: false };
      statusCell.font = { bold: false };

      if (result === null) {
        valueCell.value = 'ERROR';
      } else {
        const { suggestedType, isSuccess } = result;

        valueCell.value = suggestedType;

        statusCell.value = isSuccess ? 'Success' : 'Failure';

        statusCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: isSuccess ? 'D9EAD3' : 'F5CCCC' },
        };

        if (isSuccess) {
          successCount += 1;
        }
      }
    });

    const successRate = ((successCount / resultItems.length) * 100).toFixed(2);
    // eslint-disable-next-line no-param-reassign
    sheet.getRow(1).getCell(4 + index * 2 + 1).value = `${successRate}%`;
  });
}
