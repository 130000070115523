import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  creationImmoDialogSelectors,
  fileViewDialogSelectors,
  regenerateFileNameConfirmationDialogSelectors,
} from 'redux/selectors';

import CreationImmoDialog from './CreationImmoDialog';
import FileViewDialog from './FileViewDialog';
import RegenerateFileNameConfirmationDialog from './RegenerateFileNameConfirmationDialog';

function CommonDialogs() {
  const isCreationImmoDialogOpen = useSelector(creationImmoDialogSelectors.openSelector);
  const fileViewDialog = useSelector(fileViewDialogSelectors.rootSelector);
  const regenerateFileNameConfirmationDialog = useSelector(regenerateFileNameConfirmationDialogSelectors.rootSelector);

  const [searchParams] = useSearchParams();

  const previewFileId = searchParams.get('preview_file');

  return (
    <>
      {isCreationImmoDialogOpen && <CreationImmoDialog />}
      {previewFileId && (
        <FileViewDialog
          fileItemId={previewFileId}
          pageNumber={fileViewDialog.pageNumber}
        />
      )}
      {regenerateFileNameConfirmationDialog.open && (
        <RegenerateFileNameConfirmationDialog />
      )}
    </>
  );
}

export default CommonDialogs;
