import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    boxShadow: 'none',
    borderRadius: 10,
  },
  img: {
    width: 120,
    flexShrink: 0,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  cardTitle: {
    marginTop: 12,
    fontWeight: 500,
  },
  resultList: {
    marginBottom: '16px',
  },
  searchBoxContainer: {
    maxWidth: 1100,
    margin: 'auto',
    alignItems: 'center',
  },
});

export default useStyles;
