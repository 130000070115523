import React from 'react';
import { useSelector } from 'react-redux';

import MarkdownLib from 'markdown-to-jsx';

import { Chip, Stack } from '@mui/material';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import useFileItemBase64 from 'hooks/useFileItemBase64';
import useFilePreview from 'hooks/useFilePreview';

import { mainSelectors } from 'redux/selectors';

import useStyles from './FileItemAnswer.styles';

const FileItemAnswer = ({ fileItemAnswer, index }) => {
  const classes = useStyles();
  const { openFilePreview } = useFilePreview();

  const referenceId = `0${index + 1}`;

  const isEn = useSelector(mainSelectors.isEnSelector);

  const { fileItem } = fileItemAnswer;

  const base64 = useFileItemBase64(fileItemAnswer.file_item_id);

  const handlePageClick = (page) => {
    openFilePreview({ fileId: fileItemAnswer.file_item_id, pageNumber: page.page_number });
  };

  const renderCardActions = () => fileItemAnswer.pages.map((page) => (
    <Button key={page.id} size="small" onClick={() => handlePageClick(page)}>
      Page
      {' '}
      {page.page_number}
    </Button>
  ));

  const documentType = isEn ? fileItem.ai_generated_type : fileItem.document_type_de;

  return (
    <Card id={`reference-${referenceId}`} className={classes.root}>
      <div className={classes.img} style={{ backgroundImage: `url(${base64})` }} />
      <div>
        <CardContent>
          <Stack direction="row" spacing="8px">
            <Chip label={referenceId} />
            {documentType && <Chip label={documentType} />}
          </Stack>
          <Typography gutterBottom variant="h7" component="div" className={classes.cardTitle}>
            {fileItem.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <MarkdownLib>
              {fileItemAnswer.answer}
            </MarkdownLib>
          </Typography>
        </CardContent>
        <CardActions>
          {renderCardActions()}
        </CardActions>
      </div>
    </Card>
  );
};

export default FileItemAnswer;
