const rootSelector = (state) => state.fileList;

export const isLoadingSelector = (state) => rootSelector(state).isLoading;

export const itemsSelector = (state) => {
  const { items } = rootSelector(state);

  if (!items) {
    return items;
  }

  return items.map((item) => ({
    ...item,
    inProgress: item.status && item.status !== 'COMPLETED' && !item.status.includes('_FAILED'),
  }));
};

export const totalItemsSelector = (state) => rootSelector(state).totalItems;

export const limitSelector = (state) => rootSelector(state).limit;

export const originIdSelector = (state) => rootSelector(state).originId;

export const sortSelector = (state) => rootSelector(state).sort;

export const itemTaskSelector = (itemId) => (state) => rootSelector(state).tasksPerItem[itemId];

export const searchTermSelector = (state) => rootSelector(state).searchTerm;

export const offsetSelector = (state) => rootSelector(state).offset;

export const hasMoreSelector = (state) => rootSelector(state).hasMore;

export const fileTypesSelector = (state) => rootSelector(state).fileTypes;

export const pipelinesPerItemSelector = (state) => rootSelector(state).pipelinesPerItem;
