const rootSelector = (state) => state.inboxItemSubDocs;

export const inboxTypeSelector = (state) => rootSelector(state).inboxType;

export const itemIdSelector = (state) => rootSelector(state).itemId;

export const isLoadingSelector = (state) => rootSelector(state).isLoading;

export const totalItemsSelector = (state) => rootSelector(state).totalItems;

export const pageSelector = (state) => rootSelector(state).page;

export const limitSelector = (state) => rootSelector(state).limit;

export const sortSelector = (state) => rootSelector(state).sort;

export const searchTermSelector = (state) => rootSelector(state).searchTerm;

export const itemsSelector = (state) => rootSelector(state).items || [];

export const transformedItemsSelector = (state) => {
  const items = itemsSelector(state);

  return items.map((item) => ({
    ...item,
    canBePushedToImmo: item.approval?.suggested_immo_id && item.approval?.status === 'CREATED' && !item.isPushing,
  }));
};
