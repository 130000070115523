import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';
import useTask from 'hooks/useTask';

import { inboxItemSubDocsActions as actions } from 'redux/actions';

import PushDocsToImmosButton from './PushDocsToImmosButton';

import useExportSubDocs from './hooks/useExportSubDocs';

const InboxItemSubDocsProcessButton = ({ inboxItem }) => {
  const dispatch = useDispatch();
  const { isExporting, exportSubDocs } = useExportSubDocs(inboxItem);
  const [searchParams] = useSearchParams();

  const { itemId } = useParams();
  const fileItemId = searchParams.get('file_item_id');

  const handleTaskComplete = (newTask) => {
    if (newTask.type === 'name_generation') {
      dispatch(actions.getItemsRequest({}));
    }
  };

  const { task, fetchTask } = useTask({ fileItemId, onTaskComplete: handleTaskComplete });
  const [isSubmitting, setIsSubmitting] = useState();

  if (!task) {
    return null;
  }

  const handleGenerateNames = async () => {
    setIsSubmitting(true);
    try {
      await request({
        method: 'post',
        url: `/inbox/${itemId}/_generate_names`,
        data: {},
      });
      dispatch(actions.generateNames());
      fetchTask();
    } catch (e) {
      NM.error('Something went wrong');
    }
    setIsSubmitting(false);
  };

  if (task.type === 'classification' && task.status === 'completed') {
    return (
      <Button
        variant="text"
        disabled={isSubmitting}
        onClick={() => handleGenerateNames()}
      >
        Generate names
      </Button>
    );
  }

  if (task.type === 'name_generation') {
    if (task.status === 'processing') {
      return <Button disabled variant="text">Generating names...</Button>;
    }
  }

  if (task.status === 'completed') {
    return (
      <>
        <Button
          variant="text"
          disabled={isExporting}
          onClick={() => exportSubDocs()}
        >
          Export docs
        </Button>
        {task.type === 'immo_detection' && (
          <PushDocsToImmosButton />
        )}
      </>
    );
  }

  return null;
};

export default InboxItemSubDocsProcessButton;
