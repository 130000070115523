import React from 'react';

import { Typography, Box } from '@mui/material';

import MarkdownLib from 'markdown-to-jsx';

const Reference = ({ referenceId }) => {
  const handleClick = () => {
    const element = document.getElementById(`reference-${referenceId}`);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Box
      sx={{
        color: 'primary.main', display: 'inline', fontWeight: 500, cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      {referenceId}
    </Box>
  );
};

const Markdown = ({ text }) => (
  <Typography>
    <MarkdownLib
      options={{
        overrides: {
          Reference: {
            component: Reference,
          },
        },
      }}
    >
      {text.replaceAll(/F(\d{2})/g, '<Reference referenceId="$1" />')}
    </MarkdownLib>
  </Typography>
);

export default Markdown;
