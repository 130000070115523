import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import Layout from 'components/Layout';
import TableSettingsProvider from 'components/TableSettingsProvider';

import { COLUMN_LIST as INBOX_ITEM_SUB_DOCS_COLUMN_LIST } from 'constants/inboxItemSubDocs';

import { inboxItemSubDocsActions as actions } from 'redux/actions';

import withCurrentInboxItem from 'hocs/withCurrentInboxItem';

import InboxTable from './InboxItemSubDocsTable';
import InboxItemSubDocsProcessButton from './InboxItemSubDocsProcessButton';

const InboxItemSubDocsPage = ({ inboxItem, inboxType }) => {
  const dispatch = useDispatch();
  const { itemId } = useParams();

  useEffect(() => {
    dispatch(actions.setInboxType({ value: inboxType }));
    dispatch(actions.setItemId({ itemId }));
    dispatch(actions.getItemsRequest({}));

    return () => {
      dispatch(actions.reset());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      app={inboxType === 'DEFAULT' ? 'inbox' : 'balanceImport'}
      breadCrumbsContent={[
        <Typography key="4" color="text.primary">
          {inboxItem.name}
        </Typography>,
        <Typography key="5" color="text.primary">
          Sub docs
        </Typography>,
      ]}
      searchBox={(
        <div style={{ display: 'flex', justifyContent: 'flex-end', height: '100%' }}>
          <InboxItemSubDocsProcessButton inboxItem={inboxItem} inboxType={inboxType} />
        </div>
      )}
      withEditMode
    >
      <TableSettingsProvider
        tableId="inbox-subdocs"
        columns={INBOX_ITEM_SUB_DOCS_COLUMN_LIST}
      >
        <InboxTable />
      </TableSettingsProvider>
    </Layout>
  );
};

export default withCurrentInboxItem(InboxItemSubDocsPage);
