import React from 'react';
import { useDispatch } from 'react-redux';

import Checkbox from '@mui/material/Checkbox';
import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

import { inboxListActions as actions } from 'redux/actions';

const InboxTableDoneCheckbox = ({ item }) => {
  const dispatch = useDispatch();

  const handleChange = async (event) => {
    try {
      const { data } = await request({
        method: 'patch',
        url: `/inbox/${item.id}`,
        data: {
          completed: event.target.checked,
        },
      });
      dispatch(actions.updateItem({ item: data }));
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  return (
    <Checkbox
      defaultChecked={item.completed}
      sx={{
        ml: '-10px',
        '& .MuiSvgIcon-root': { fontSize: 28 },
      }}
      onChange={handleChange}
    />
  );
};

export default InboxTableDoneCheckbox;
