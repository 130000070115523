/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  inboxType: null,
  items: null,
  isLoading: false,
  totalItems: null,
  page: 1,
  sort: 'DATE_DESC',
  showCompleted: false,
  approvalStats: {},
};

export const slice = createSlice({
  name: 'inboxList',
  initialState,
  reducers: {
    setInboxType: (state, { payload: { value } }) => {
      state.inboxType = value;
    },
    setShowCompleted: (state, { payload: { value } }) => {
      state.showCompleted = value;
    },
    getItemsRequest: (state, { payload: { page } }) => {
      state.isLoading = true;
      if (page) {
        state.page = page;
      }
    },
    getItemsSuccess: (state, { payload: { data } }) => {
      state.items = data.inbox_items;
      state.isLoading = false;
      state.totalItems = data.total_items;
    },
    getItemsFailure: (state) => {
      state.isLoading = false;
    },
    updateFile: (state, { payload: { fileItem } }) => {
      if (state.items) {
        const index = state.items.findIndex((item) => item.file_item_id === fileItem.id);

        if (index !== -1) {
          const item = state.items[index];

          item.document_type_de = fileItem.document_type_de;
          item.name = fileItem.name;
        }
      }
    },
    updateItem: (state, { payload: { item: newItem } }) => {
      if (state.items) {
        const index = state.items.findIndex((item) => item.id === newItem.id);

        if (index !== -1) {
          state.items[index] = newItem;
        }
      }
    },
    updateItems: (state, { payload: { items } }) => {
      if (state.items) {
        const itemsObj = {};

        items.forEach((item) => {
          itemsObj[item.id] = item;
        });

        state.items.forEach((item, index) => {
          const newItem = itemsObj[item.id];

          if (newItem) {
            state.items[index] = newItem;
          }
        });
      }
    },
    getApprovalStatsRequest: () => {},
    getApprovalStatsSuccess: (state, { payload: { stats } }) => {
      state.approvalStats = stats;
    },
    reset: () => initialState,
  },
});

export const { actions } = slice;

export default slice.reducer;
