import useDocumentClasses from './useDocumentClasses';

const useDocumentClassesById = () => {
  const items = useDocumentClasses();

  if (!items) {
    return null;
  }

  const res = {};

  items.forEach((item) => {
    res[item.id] = item;
  });

  return res;
};

export default useDocumentClassesById;
