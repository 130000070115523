import request from 'api/request';

export const getChangeEvents = ({
  offset, limit, changeSubjectTypes,
}) => request({
  method: 'post',
  url: '/file-item-change-events/_search',
  data: {
    offset,
    limit,
    change_subject_types: changeSubjectTypes,
  },
});
