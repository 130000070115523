import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { inboxListSelectors as selectors } from 'redux/selectors';
import { inboxListActions as actions } from 'redux/actions';

import { request } from 'api';

const usePollInboxData = (inboxType) => {
  const dispatch = useDispatch();

  const items = useSelector(selectors.itemsSelector) || [];
  const itemsRef = useRef();
  itemsRef.current = items;

  const fetchItems = async (ids) => {
    try {
      const { data } = await request({
        method: 'post',
        url: '/inbox/_search',
        data: {
          ids,
          limit: 300,
          inbox_type: inboxType,
        },
      });
      dispatch(actions.updateItems({ items: data.inbox_items }));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const itemsInProgress = itemsRef.current.filter(
        (item) => item.step === 'name_generation' && item.status !== 'completed',
      );

      if (itemsInProgress.length > 0) {
        fetchItems(itemsInProgress.map((item) => item.id));
      }
    }, 5000);

    return () => { clearInterval(intervalId); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default usePollInboxData;
