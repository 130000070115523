import React from 'react';
import { MenuItem } from '@mui/material';
import { SelectController } from 'components/formControllers';

const models = [
  // 'gpt-4',
  // 'gpt-4-1106-preview',
  // 'gpt-4-0613',
  // 'gpt-4-32k',
  // 'gpt-4-32k-0613',
  // 'gpt-3.5-turbo',
  // 'gpt-3.5-turbo-0613',
  // 'gpt-3.5-turbo-16k',
  // 'gpt-3.5-turbo-16k-0613',
  'gpt-4o',
  'gpt-4o-mini',
];

const ModelSelect = ({ control, name }) => (
  <SelectController control={control} name={name} label="AI model">
    {models.map((model) => (
      <MenuItem key={model} value={model}>{model}</MenuItem>
    ))}
  </SelectController>
);

export default ModelSelect;
