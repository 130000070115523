import React from 'react';

import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import ViewColumnIcon from '@mui/icons-material/ViewColumn';

import MenuItemWithSwitch from 'components/UIKit/MenuItemWithSwitch';

import useTableSettings from 'components/TableSettingsProvider/useTableSettings';

const ColumnVisibilitySelect = () => {
  const { columns, visibleColumns, setColumnsVisibility } = useTableSettings();

  const renderItem = (column) => {
    const value = Boolean(visibleColumns.find(({ type }) => column.type === type));

    const handleChange = () => {
      const obj = {};

      visibleColumns.forEach((visibleColumn) => {
        obj[visibleColumn.type] = true;
      });

      obj[column.type] = !value;

      setColumnsVisibility(obj);
    };

    return (
      <MenuItemWithSwitch
        key={column.type}
        selected={value}
        value={column.type}
        disabled={column.isAlwaysVisible}
        onClick={handleChange}
      >
        {column.title}
      </MenuItemWithSwitch>
    );
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <>
          <IconButton variant="contained" {...bindTrigger(popupState)}>
            <ViewColumnIcon />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {columns.map((column) => renderItem(column))}
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default ColumnVisibilitySelect;
