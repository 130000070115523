import React from 'react';

import { Stack } from '@mui/material';

import AvatarMenu from './AvatarMenu';
import LanguageSwitcher from './LanguageSwitcher';

import ImmosBreadcrumbs from './breadcrumbs/ImmosBreadcrumbs';
import InboxBreadcrumbs from './breadcrumbs/InboxBreadcrumbs';
import BalanceImportBreadcrumbs from './breadcrumbs/BalanceImportBreadcrumbs';
import SearchBreadcrumbs from './breadcrumbs/SearchBreadcrumbs';
import AdminBreadcrumbs from './breadcrumbs/AdminBreadcrumbs';

import useStyles from './HeaderContent.styles';

function HeaderContent({ app, breadCrumbsContent }) {
  const classes = useStyles();

  const renderBreadCrumbs = () => {
    switch (app) {
      case 'immos':
        return <ImmosBreadcrumbs content={breadCrumbsContent} />;
      case 'inbox':
        return <InboxBreadcrumbs content={breadCrumbsContent} />;
      case 'balanceImport':
        return <BalanceImportBreadcrumbs content={breadCrumbsContent} />;
      case 'search':
        return <SearchBreadcrumbs content={breadCrumbsContent} />;
      case 'admin':
        return <AdminBreadcrumbs content={breadCrumbsContent} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className={classes.breadcrumbsWrapper}>
        {renderBreadCrumbs()}
      </div>
      <Stack direction="row" sx={{ alignItems: 'center' }} spacing={3}>
        <LanguageSwitcher />
        <AvatarMenu />
      </Stack>
    </>
  );
}

export default HeaderContent;
