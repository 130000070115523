import React, { useState, useEffect } from 'react';
import { request } from 'api';
import { Buffer } from 'buffer';

const ImageWithJWT = ({ src, ...otherProps }) => {
  const [base64, setBase64] = useState(null);

  const didMount = async () => {
    try {
      const response = await request({
        method: 'get',
        url: src,
        cache: true,
        responseType: 'arraybuffer',
      });

      // eslint-disable-next-line no-buffer-constructor, max-len
      setBase64(`data:${response.headers['content-type']};base64,${new Buffer(response.data).toString('base64')}`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    didMount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!src || !base64) {
    <div {...otherProps} />;
  }

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={base64} {...otherProps} />;
};

export default ImageWithJWT;
