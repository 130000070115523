import { request } from 'api';
import useDidMount from 'hooks/useDidMount';
import { useState } from 'react';

const useTenantConfig = () => {
  const [config, setConfig] = useState({
    askImmoMate: false,
    immoCardOverview: false,
  });

  const loadConfig = async () => {
    try {
      const { data: { tariff: { features } } } = await request({
        method: 'get',
        url: '/tenant-config',
        cache: true,
      });

      setConfig({
        askImmoMate: features.includes('ask_immomate'),
        immoCardOverview: true,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useDidMount(() => {
    loadConfig();
  });

  return config;
};

export default useTenantConfig;
