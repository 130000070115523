import React, { useState } from 'react';
import Layout from 'components/Layout';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import AdminSubNavigation from 'components/Layout/Navigation/AdminSubNavigation';

import FileItemChangeEventsTable from './FileItemTypeChangeEventsTable';
import FileItemMetadataChangeEventsTable from './FileItemMetadataChangeEventsTable';

const ChangeEventsPage = () => {
  const [activeTab, setActiveTab] = useState('type');

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getTableComponent = () => {
    switch (activeTab) {
      case 'type':
        return <FileItemChangeEventsTable />;
      case 'metadata':
        return <FileItemMetadataChangeEventsTable />;
      default:
        return <FileItemChangeEventsTable />;
    }
  };

  return (
    <Layout
      app="admin"
      breadCrumbsContent={[
        <Typography key="3" color="text.primary">
          File item change events
        </Typography>,
      ]}
      subNav={<AdminSubNavigation />}
    >
      <Box sx={{ padding: 2 }}>
        <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
          <Tab value="type" label="Type" />
          <Tab value="metadata" label="Metadata" />
        </Tabs>
        {getTableComponent()}
      </Box>
    </Layout>
  );
};

export default ChangeEventsPage;
