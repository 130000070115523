/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  fileItem: null,
  onRegenerateSuccess: null,
};

export const slice = createSlice({
  name: 'regenerateFileNameConfirmationDialog',
  initialState,
  reducers: {
    openDialog: (state, { payload: { fileItem, onRegenerateSuccess } }) => {
      state.open = true;
      state.fileItem = fileItem;
      state.onRegenerateSuccess = onRegenerateSuccess;
    },
    closeDialog: (state) => {
      state.open = false;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
