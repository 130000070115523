import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    '&:hover $editButton': {
      visibility: 'visible',
    },
  },
  viewMode: {
    display: 'flex',
    alignItems: 'center',
  },
  editButton: {
    visibility: 'hidden',
  },
  immoName: {
    marginRight: 8,
  },
});

export default useStyles;
