import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    backgroundColor: 'white',
    boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.17), 0px 3px 8px 0px rgba(0, 0, 0, 0.17)',
    display: 'flex',
    flexDirection: 'column',
  },
  logoBlock: {
    padding: [16, 8],
    paddingBottom: 20,
    backgroundColor: 'white',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    width: 0,
  },
  logoImg: {
    marginLeft: 6,
    width: 32,
    height: 32,
  },
  logoNameWrapper: {
    marginLeft: 12,
    color: '#444746',
    width: 128,
    flexShrink: 0,
  },
  logoName: {
    fontSize: 22,
  },
  navigation: {
    display: 'flex',
    flexGrow: 1,
  },
  subNav: {
    width: 170,
    backgroundColor: 'white',
    padding: [0, 16],
    boxSizing: 'border-box',
    borderLeft: '1px solid #D4D4D4',
  },
  divider: {
    height: 1,
    backgroundColor: '#D4D4D4',
  },
});

export default useStyles;
