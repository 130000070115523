import React from 'react';

import { Tooltip } from '@mui/material';

const ApprovalStatsCell = ({ approvalStats }) => {
  if (!approvalStats) {
    return null;
  }

  const approved = approvalStats.APPROVED || 0;
  const rejected = approvalStats.REJECTED || 0;
  const awaiting = approvalStats.SENT || 0;
  const created = approvalStats.CREATED || 0;

  return (
    <Tooltip title={(
      <div>
        <div>
          Approved:
          {' '}
          {approved}
        </div>
        <div>
          Rejected:
          {' '}
          {rejected}
        </div>
        <div>
          Awaiting:
          {' '}
          {awaiting}
        </div>
        <div>
          Not sent:
          {' '}
          {created}
        </div>
      </div>
    )}
    >
      <div>
        <span style={{ color: '#4caf50' }}>
          {approved}
        </span>
        /
        <span style={{ color: '#ef5350' }}>
          {rejected}
        </span>
        /
        <span style={{ color: '#42A5F5' }}>
          {awaiting}
        </span>
        /
        <span>
          {created}
        </span>
      </div>
    </Tooltip>
  );
};

export default ApprovalStatsCell;
