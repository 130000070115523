export const energyCertificatesColors = {
  'A+': '#4CAF50',
  A: '#8BC34A',
  B: '#CDDC39',
  C: '#FFEB3B',
  D: '#FFC107',
  E: '#FF9800',
  F: '#FF5722',
  G: '#F44336',
  H: '#D32F2F',
  Unknown: '#D3D3D3',
};

export const certificateClasses = ['A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'Unknown'];
