import React from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import AdvisorConfigForm from 'components/AdvisorConfigForm';

import { getAnswersSettings } from 'utils/answersSettings';

import useStyles from './SettingsDialogContent.styles';

function SettingsDialogContent({ onReset, onClose }) {
  const classes = useStyles();

  const isSubmitDisabled = false;

  return (
    <>
      <DialogTitle>Answers settings</DialogTitle>
      <DialogContent className={classes.content}>
        <AdvisorConfigForm defaultValues={getAnswersSettings()} onSuccess={onClose} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onReset} color="error">Reset</Button>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="submit"
          form="answers-config"
          variant="contained"
          disabled={isSubmitDisabled}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
}

export default SettingsDialogContent;
