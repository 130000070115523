import { useDispatch, useSelector } from 'react-redux';

import {
  fileListSelectors, inboxListSelectors, inboxItemSubDocsSelectors, searchSelectors,
} from 'redux/selectors';

import { fileListActions } from 'redux/actions';

import useFilePreview from 'hooks/useFilePreview';

const useFilePreviewNavigation = (fileItemId) => {
  const dispatch = useDispatch();

  const filesItems = useSelector(fileListSelectors.itemsSelector);
  const inboxItems = useSelector(inboxListSelectors.itemsSelector);
  const inboxItemSubDocsItems = useSelector(inboxItemSubDocsSelectors.itemsSelector);
  const searchItems = useSelector(searchSelectors.resultsSelector);

  const { openFilePreview } = useFilePreview();

  const getItemTypes = () => {
    if (filesItems) {
      return 'files';
    }

    if (inboxItems) {
      return 'inbox';
    }

    if (searchItems) {
      return 'search';
    }

    if (inboxItemSubDocsItems) {
      return 'inboxItemSubDocs';
    }

    return null;
  };

  const fileItemTypes = getItemTypes();

  const getItemIds = () => {
    switch (fileItemTypes) {
      case 'files':
        return filesItems.map((item) => item.id);
      case 'inbox':
        return inboxItems.map((item) => item.file_item_id);
      case 'inboxItemSubDocs':
        return inboxItemSubDocsItems.map((item) => item.file_item_id);
      case 'search':
        return searchItems.map((item) => item.file_item_id);
      default:
        return null;
    }
  };

  const itemIds = getItemIds();

  const itemIndex = itemIds.findIndex((id) => id === fileItemId);

  const goPrev = () => {
    openFilePreview({ fileId: itemIds[itemIndex - 1] });
  };

  const goNext = () => {
    openFilePreview({ fileId: itemIds[itemIndex + 1] });

    if (fileItemTypes === 'files' && itemIds.length - itemIndex < 10) {
      dispatch(fileListActions.getFileListRequest({ loadMore: true }));
    }
  };

  return {
    isNavigationVisible: itemIndex !== -1,
    hasPrev: itemIndex > 0,
    hasNext: itemIndex < itemIds.length - 1,
    goPrev,
    goNext,
  };
};

export default useFilePreviewNavigation;
