import React from 'react';
import { useDispatch } from 'react-redux';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { fileListActions as actions } from 'redux/actions';

import FileNameTableCell from 'components/FileNameTableCell';
import FileRawMenu from 'components/FileRawActions';
import FileCell from 'components/FileCell';
import MetadataCell from 'components/MetadataCell';

import useTableSettings from 'components/TableSettingsProvider/useTableSettings';

import useFileTransformedMetadata from 'hooks/filesTable/useFileTransformedMetadata';

const FileRaw = ({
  item, metadataColumns, highlighted,
}) => {
  const dispatch = useDispatch();
  const { visibleColumns } = useTableSettings();
  const transformedMetadata = useFileTransformedMetadata(item);

  const handleFileUpdate = (newItem) => {
    dispatch(actions.updateFile({ file: newItem }));
  };

  const renderCell = (column) => {
    if (column.type === 'METADATA') {
      return metadataColumns.map((metadataColumn) => {
        const metadata = transformedMetadata[metadataColumn.name];

        if (!metadata) {
          return <TableCell />;
        }

        return (
          <MetadataCell
            key={metadata.id}
            fileItem={item}
            metadata={metadata}
            onFileItemUpdate={handleFileUpdate}
          />
        );
      });
    }

    return <FileCell key={column.type} item={item} columnType={column.type} onItemUpdate={handleFileUpdate} />;
  };

  return (
    <TableRow hover selected={highlighted} role="checkbox" tabIndex={-1}>
      <FileNameTableCell item={item} onItemUpdate={handleFileUpdate} />
      {visibleColumns.map((column) => renderCell(column))}
      <TableCell align="right">
        <FileRawMenu item={item} withMoreMenu={false} />
      </TableCell>
    </TableRow>
  );
};

export default FileRaw;
