import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    marginTop: 30,
    width: '100%',
    padding: [0, 30],
    height: 68,
    background: 'linear-gradient(90deg, #FD7B21 0%, #F3C330 40%)',
    filter: 'drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.17)) drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.28))',
    borderRadius: 10,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  description: {
    color: '#FFF',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '26px',
  },
  searchBox: {
    flexGrow: 1,
    marginLeft: 17,
    display: 'flex',
  },
  input: {
    background: 'white',
    height: 39,
    fontSize: 15,
    border: 'none !important',
    borderRadius: 10,
    outline: 'none',
    marginRight: -20,
    paddingRight: 20,

    '& fieldset': {
      border: 'none !important',
      outline: 'none',
    },
  },
  submitButton: {
    background: '#525252',
    borderRadius: 10,
    padding: [10, 11, 10, 17],
    height: 39,

    '& svg': {
      color: '#FFF',
      height: 23,
      width: 'auto',
    },

    '&:hover': {
      background: '#3b3b3b',
    },
  },
});

export default useStyles;
