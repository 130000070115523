const attachApprovalsToItems = (items, approvals, immos) => {
  const approvalsObj = {};
  const immosObj = {};

  immos.forEach((immo) => {
    immosObj[immo.id] = immo;
  });

  approvals.forEach((approval) => {
    // eslint-disable-next-line no-param-reassign
    approval.suggestedImmoName = immosObj[approval.suggested_immo_id]?.name;

    approvalsObj[approval.file_item_id] = approval;
  });

  items.forEach((item) => {
    // eslint-disable-next-line no-param-reassign
    item.approval = approvalsObj[item.file_item_id];
  });
};

export default attachApprovalsToItems;
