import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { immoListActions as actions } from 'redux/actions';
import { immoListSelectors as selectors } from 'redux/selectors';

import { useDidMount, useWillUnmount } from 'hooks';

import ImmosTable from './ImmoList';

const ImmoListPage = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useSelector(selectors.filtersSelector);

  useDidMount(() => {
    dispatch(actions.setFilters({
      value: {
        only_without_address: Boolean(searchParams.get('only_without_address')),
        only_with_pending_approvals: Boolean(searchParams.get('only_with_pending_approvals')),
      },
    }));
  });

  useEffect(() => {
    try {
      const sortFromLocalStorage = localStorage.getItem('immomate.immolist.sort');

      if (sortFromLocalStorage) {
        const sort = JSON.parse(sortFromLocalStorage);

        dispatch(actions.setSort({ sort }));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    dispatch(actions.getItemsRequest({}));

    if (filters.only_without_address) {
      searchParams.set('only_without_address', 1);
    } else {
      searchParams.delete('only_without_address');
    }

    if (filters.only_with_pending_approvals) {
      searchParams.set('only_with_pending_approvals', 1);
    } else {
      searchParams.delete('only_with_pending_approvals');
    }

    setSearchParams(searchParams);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleSort = (newSort) => {
    localStorage.setItem('immomate.immolist.sort', JSON.stringify(newSort));
  };

  useWillUnmount(() => {
    dispatch(actions.reset());
  });

  return (
    <ImmosTable onSort={handleSort} />
  );
};

export default ImmoListPage;
