import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Layout from 'components/Layout';
import UploadButton from 'components/UploadButton';
import TableSettingsProvider from 'components/TableSettingsProvider';

import { COLUMN_LIST } from 'constants/inboxList';

import { inboxListActions as actions, inboxCreationActions } from 'redux/actions';
import { inboxListSelectors as selectors } from 'redux/selectors';

import FilesDndZone from 'components/FilesDndZone';
import InboxTable from './InboxTable';

const InboxPage = ({ inboxType }) => {
  const dispatch = useDispatch();

  const showCompleted = useSelector(selectors.showCompletedSelector);

  useEffect(() => {
    dispatch(actions.setInboxType({ value: inboxType }));
    dispatch(actions.getItemsRequest({}));

    return () => {
      dispatch(actions.reset());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpload = (files) => {
    dispatch(inboxCreationActions.createFilesRequest({ files }));
  };

  const handleDropFiles = (files) => {
    dispatch(inboxCreationActions.createFilesRequest({ files }));
  };

  const handleShowCompletedChange = (event) => {
    dispatch(actions.setShowCompleted({ value: event.target.checked }));
    dispatch(actions.getItemsRequest({}));
  };

  return (
    <Layout
      app={inboxType === 'DEFAULT' ? 'inbox' : 'balanceImport'}
      newButton={(
        <UploadButton onUpload={handleUpload} />
      )}
      searchBox={(
        <div style={{ display: 'flex', justifyContent: 'flex-end', height: '100%' }}>
          <FormControlLabel
            control={<Switch checked={showCompleted} onChange={handleShowCompletedChange} />}
            label="Show completed"
          />
        </div>
      )}
      withEditMode
    >
      <FilesDndZone onDrop={handleDropFiles}>
        <TableSettingsProvider tableId="inbox" columns={COLUMN_LIST}>
          <InboxTable inboxType={inboxType} />
        </TableSettingsProvider>
      </FilesDndZone>
    </Layout>
  );
};

export default InboxPage;
