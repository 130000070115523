import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import { Stack } from '@mui/material';
import { fileListActions as actions, filtersActions } from 'redux/actions';
import { filtersSelectors } from 'redux/selectors';

import Layout from 'components/Layout';
import FilesTable from 'components/FilesTable';
import AdminSubNavigation from 'components/Layout/Navigation/AdminSubNavigation';
import SearchBox from 'components/SearchBox';

import { useDidMount, useWillUnmount } from 'hooks';
import useDocumentClassesById from 'hooks/documentClasses/useDocumentClassesById';
import BenchmarkTypeCell from './BenchmarkTypeCell';
import TypeFilter from './TypeFilter';

import useStyles from './BenchmarkFilesPage.styles';

const BenchmarkFilesPage = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();

  const documentClassesById = useDocumentClassesById();

  const benchmarkTypes = useSelector(filtersSelectors.benchmarkTypesSelector);

  useDidMount(() => {
    dispatch(actions.resetSort());
  });

  useWillUnmount(() => {
    dispatch(actions.reset());
  });

  useEffect(() => {
    dispatch(actions.setFileTypes({ value: ['benchmark'] }));
    dispatch(actions.getFileListRequest({}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTypeCell = (item) => {
    if (!item.add_to_benchmark_request?.document_class_id || !documentClassesById) {
      return <TableCell />;
    }

    const documentClass = documentClassesById[item.add_to_benchmark_request.document_class_id];

    if (!documentClass) {
      return <TableCell />;
    }

    return <BenchmarkTypeCell item={item} documentClass={documentClass} />;
  };

  const handleSearch = (searchTerm) => {
    dispatch(actions.setSearchTerm({ value: searchTerm }));
    dispatch(actions.getFileListRequest({ }));
  };

  const handleTypesFilterChange = (value) => {
    dispatch(filtersActions.setBenchmarkTypeFilter({ value }));
  };

  useEffect(() => {
    dispatch(actions.getFileListRequest({}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benchmarkTypes]);

  return (
    <Layout
      app="admin"
      searchBox={(
        <Stack direction="row" spacing="16px" sx={{ alignItems: 'center' }}>
          <SearchBox onSearch={handleSearch} />
          <TypeFilter onChange={handleTypesFilterChange} />
        </Stack>
      )}
      breadCrumbsContent={[
        <Typography key="3" color="text.primary">
          Benchmark files
        </Typography>,
      ]}
      subNav={<AdminSubNavigation />}
      withEditMode
    >
      <FilesTable
        tableId="benchmark-files"
        renderTypeCell={(item) => renderTypeCell(item)}
        withRowProcessingActionColumn={false}
        disableBulkProcessingActions
      />
    </Layout>
  );
};

export default BenchmarkFilesPage;
