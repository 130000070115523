const getItemStats = (approvalQuantities) => {
  const res = {};

  approvalQuantities.forEach((item) => {
    res[item.status] = item.count;
  });

  return res;
};

const transformApprovalStats = (items) => {
  const res = {};

  items.forEach((item) => {
    res[item.inbox_id] = getItemStats(item.approval_quantities);
  });

  return res;
};

export default transformApprovalStats;
