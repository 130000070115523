const transformSubDocs = (subDocs) => subDocs.map((subDoc) => ({
  ...subDoc,
  pages: subDoc.pages_details || subDoc.pages.map((pageNumber) => ({
    number: pageNumber,
    rotation: 0,
  })),
}));

const getSubDocs = (documentSplitDetails) => {
  const {
    split_point_confidences: splitPointConfidences,
  } = documentSplitDetails;

  const subDocuments = transformSubDocs(documentSplitDetails.sub_documents);

  const res = [];

  subDocuments.forEach((subDoc) => {
    res.push({
      localId: Math.random(),
      pages: [],
    });

    subDoc.pages.forEach((page) => {
      res.at(-1).pages.push({
        ...page,
        splitConfidence: splitPointConfidences?.[page.number - 1],
      });
    });
  });

  return res;
};

export default getSubDocs;
