const rootSelector = (state) => state.main;

export const isLoadingSelector = (state) => rootSelector(state).isLoading;

export const languageSelector = (state) => rootSelector(state).language;

export const isEnSelector = (state) => languageSelector(state) === 'EN';

export const isNavigationExpandedSelector = (state) => rootSelector(state).isNavigationExpanded;

export const highlightedItemIdSelector = (state) => rootSelector(state).highlightedItemId;

export const isEditModeSelector = (state) => rootSelector(state).isEditMode;
