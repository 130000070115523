import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager as NM } from 'react-notifications';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import request from 'api/request';
import { regenerateFileNameConfirmationDialogSelectors } from 'redux/selectors';
import { regenerateFileNameConfirmationDialogActions } from 'redux/actions';

export default function RegenerateNameConfirmationDialog() {
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    fileItem,
    onRegenerateSuccess = () => {},
  } = useSelector(regenerateFileNameConfirmationDialogSelectors.rootSelector);

  const handleClose = () => {
    dispatch(regenerateFileNameConfirmationDialogActions.closeDialog());
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await request({
        method: 'post',
        url: `/files/${fileItem.id}/_generate-name`,
        data: {},
      });

      onRegenerateSuccess(data);

      setIsSubmitting(false);
      handleClose();
    } catch (e) {
      NM.error('Something went wrong');
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      open
      onClose={handleClose}
    >
      <DialogTitle>
        Regenerate filename
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to regenerate the filename?
          <div><b>{fileItem.name}</b></div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Skip</Button>
        <Button
          variant="contained"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          Regenerate
        </Button>
      </DialogActions>
    </Dialog>
  );
}
