/* eslint-disable no-buffer-constructor */
import { useState } from 'react';

import { Buffer } from 'buffer';

import { request } from 'api';

import { useDidMount } from 'hooks';

const useFileItemBase64 = (fileItemId) => {
  const [base64, setBase64] = useState(null);

  const resolveImage = async () => {
    try {
      const response = await request({
        method: 'get',
        url: `/files/${fileItemId}/pages/1/thumbnail?width=10&height=10`,
        responseType: 'arraybuffer',
      });

      setBase64(`data:${response.headers['content-type']};base64,${new Buffer(response.data).toString('base64')}`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useDidMount(() => {
    resolveImage();
  });

  return base64;
};

export default useFileItemBase64;
