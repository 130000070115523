import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { inboxListSelectors as selectors, mainSelectors } from 'redux/selectors';

import FileNameTableCell from 'components/FileNameTableCell';
import FileRawActions from 'components/FileRawActions';
import ColumnVisibilitySelect from 'components/TableSettingsProvider/ColumnVisibilitySelect';
import useTableSettings from 'components/TableSettingsProvider/useTableSettings';

import formatDate from 'utils/formatDate';

import ApprovalStatsCell from 'pages/InboxPage/InboxTable/ApprovalStatsCell';
import InboxTableDoneCheckbox from './InboxTableDoneCheckbox';
import SplitButton from './InboxItemButton';

import usePollInboxData from './hooks/usePollInboxData';

// eslint-disable-next-line no-unused-vars
import useStyles from './InboxTable.styles';

function bytesToMB(bytes) {
  return bytes / 1024 / 1024;
}

const InboxTable = ({ inboxType }) => {
  const items = useSelector(selectors.itemsSelector) || [];
  const approvalStats = useSelector(selectors.approvalStatsSelector);
  const { visibleColumns } = useTableSettings();
  usePollInboxData(inboxType);

  const highlightedItemId = useSelector(mainSelectors.highlightedItemIdSelector);

  const renderSubDocsInfo = (item) => {
    if (item.sub_items_total_amount) {
      return (
        <div>
          <span style={{ color: '#4caf50' }}>
            {item.sub_items_processed || 0}
          </span>
          /
          <span style={{ color: '#ef5350' }}>
            {item.sub_items_failed || 0}
          </span>
          /
          {item.sub_items_total_amount || 0}
        </div>
      );
    }
    return null;
  };

  const renderBodyCell = (column, item) => {
    switch (column.type) {
      case 'PAGES':
        return <TableCell align="right">{item.pages_count}</TableCell>;
      case 'CREATOR':
        return <TableCell>{item.created_by}</TableCell>;
      case 'DATE_ADDED':
        return <TableCell>{formatDate(item.created_at)}</TableCell>;
      case 'SIZE':
        return (
          <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
            {bytesToMB(Math.max(item.size, 0.1)).toFixed(3)}
            {' '}
            MB
          </TableCell>
        );
      case 'COMPLETED_AT':
        return <TableCell>{formatDate(item.completed && item.completed_at)}</TableCell>;
      case 'COMPLETED_BY':
        return <TableCell>{item.completed && item.completed_by}</TableCell>;
      case 'SUB_DOCS':
        return (
          <TableCell>{renderSubDocsInfo(item)}</TableCell>
        );
      case 'APPROVALS':
        return (
          <TableCell><ApprovalStatsCell approvalStats={approvalStats[item.id]} /></TableCell>
        );
      default:
        return <TableCell />;
    }
  };

  return (
    <TableContainer sx={{ height: '100%' }}>
      <Table stickyHeader aria-label="sticky table" style={{ padding: 16 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 46 }}>
              Done
            </TableCell>
            <TableCell
              style={{ paddingRight: 0, width: 40 }}
              colSpan={2}
            >
              Name
            </TableCell>
            {visibleColumns.map((column) => (
              <TableCell key={column.type} align={column.align}>
                {column.title}
              </TableCell>
            ))}
            <TableCell />
            <TableCell align="right" sx={{ width: 0 }}>
              <ColumnVisibilitySelect />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow
              key={item.id}
              hover
              selected={highlightedItemId === item.file_item_id}
              role="checkbox"
              tabIndex={-1}
            >
              <TableCell>
                <InboxTableDoneCheckbox item={item} />
              </TableCell>
              <FileNameTableCell item={item} />
              {visibleColumns.map((column) => (
                <Fragment key={column.type}>
                  {renderBodyCell(column, item)}
                </Fragment>
              ))}
              <TableCell align="right">
                <SplitButton item={item} inboxType={inboxType} />
              </TableCell>
              <TableCell>
                <FileRawActions
                  disabled
                  item={item}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InboxTable;
