import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { NotificationManager as NM } from 'react-notifications';

import { approvalListActions as actions } from 'redux/actions';

import { request } from 'api';
import { Chip } from '@mui/material';

import RejectApprovalDialog from './RejectApprovalDialog';

const ApprovalCellContent = ({ item }) => {
  const dispatch = useDispatch();
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);

  const handleApprove = async () => {
    try {
      const { data } = await request({
        method: 'POST',
        url: `/approval-requests/${item.id}/_resolve`,
        data: {
          approved: true,
          comment: null,
        },
      });

      dispatch(actions.updateApproval({ approval: data }));
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  if (item.status === 'SENT') {
    return (
      <>
        <Stack direction="row" spacing={0}>
          <Button color="success" onClick={handleApprove}>Approve</Button>
          <Button color="error" onClick={() => setIsRejectDialogOpen(true)}>Reject</Button>
        </Stack>
        {isRejectDialogOpen && (
          <RejectApprovalDialog
            item={item}
            onClose={() => setIsRejectDialogOpen(false)}
          />
        )}
      </>
    );
  }

  if (item.status === 'APPROVED') {
    return (
      <Chip label={item.status} color="success" />
    );
  }

  if (item.status === 'REJECTED') {
    return (
      <Chip label={item.status} color="error" />
    );
  }

  return null;
};

export default ApprovalCellContent;
