import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { IconButton } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';

import { mainSelectors } from 'redux/selectors';

import FileTypeCellEditMode from './FileTypeCellEditMode';

// eslint-disable-next-line no-unused-vars
import useStyles from './FileTypeCell.styles';

const FileTypeCell = ({
  item, hiddenCapabilities, forceEditMode, onItemUpdate,
}) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const isEn = useSelector(mainSelectors.isEnSelector);

  const isEditMode = useSelector(mainSelectors.isEditModeSelector) || forceEditMode;

  const handleEditClick = (e) => {
    setIsEdit(true);
    e.preventDefault();
    e.stopPropagation();
  };

  const renderContent = () => {
    if (hiddenCapabilities?.type) {
      return (
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation={item.inProgress ? 'wave' : false} />
      );
    }

    if (!isEdit) {
      return (
        <div className={classes.viewMode}>
          {isEn ? item.ai_generated_type : item.document_type_de}
          <IconButton
            size="small"
            className={classes.editButton}
            sx={{
              visibility: isEditMode ? 'visible' : 'hidden',
            }}
            onClick={handleEditClick}
          >
            <ModeEditIcon />
          </IconButton>
        </div>
      );
    }

    return (
      <FileTypeCellEditMode
        item={item}
        onItemUpdate={onItemUpdate}
        onClose={() => setIsEdit(false)}
      />
    );
  };

  return (
    <TableCell className={classes.root}>
      {renderContent()}
    </TableCell>
  );
};

export default FileTypeCell;
