import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  breadcrumbsWrapper: {
    marginRight: 16,

    '& li > *': {
      fontSize: 18,
    },
  },
});

export default useStyles;
