import { createSelector } from '@reduxjs/toolkit';

export const rootSelector = (state) => state.fileSplitting;

export const subDocSelector = (state) => rootSelector(state).subDocs;

export const pagesInfoByNumberSelector = createSelector(
  [subDocSelector],
  (subDocs) => {
    const res = {};

    subDocs.forEach((subDoc) => subDoc.pages.forEach((page) => {
      res[page.number] = page;
    }));

    return res;
  },
);

export const selectedPagesSelector = (state) => rootSelector(state).selectedPages;

export const countSelectedPagesSelector = (state) => Object.keys(selectedPagesSelector(state)).length;

export const zoomSelector = (state) => rootSelector(state).zoom;
