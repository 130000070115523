import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import jwtDecode from 'jwt-decode';
import { useAuth0 } from '@auth0/auth0-react';
import { LinearProgress } from '@mui/material';
import { useDidMount } from 'hooks';

import * as apiConfig from 'api/config';
import { setTokenGenerator } from 'api/request';

import { userActions as actions } from 'redux/actions';

const withAccessTokenRequired = (WrappedComponent) => (props) => {
  const [isAccessTokenResolved, setIsAccessTokenResolved] = useState(Boolean(apiConfig.getAccessToken()));
  const [searchParams, setSearchParams] = useSearchParams();

  const subscriptionSuccess = searchParams.get('subscription') === 'success';

  const auth0 = useAuth0();
  const dispatch = useDispatch();

  const resolveAccessToken = async () => {
    try {
      if (subscriptionSuccess) {
        searchParams.delete('subscription');
        setSearchParams(searchParams);
      }

      const accessToken = await auth0.getAccessTokenSilently({ cacheMode: 'off' });
      apiConfig.setAccessToken(accessToken);

      const decodedToken = jwtDecode(accessToken);

      dispatch(actions.setUser({
        user: {
          id: auth0.user.sub,
          role: decodedToken.portalRole || 'CONTENT_CONSUMER',
          hasActiveSubscription: decodedToken.has_active_subscription,
          hasCanceledSubscription: decodedToken.has_canceled_subscription,
          newCustomer: decodedToken.new_customer,
          orgId: decodedToken.org_id,
          permissions: decodedToken.permissions,
          orgDisplayName: decodedToken.org_display_name,
        },
      }));

      setIsAccessTokenResolved(true);
    } catch (e) {
      if ([
        'login_required',
        'consent_required',
        'interaction_required',
        'missing_refresh_token',
        'invalid_grant',
      ].includes(e.error)) {
        auth0.loginWithRedirect();
      }
      throw e;
    }
  };

  useDidMount(() => {
    setTokenGenerator(auth0.getAccessTokenSilently);
    if (!isAccessTokenResolved) {
      resolveAccessToken();
    }
  });

  if (!isAccessTokenResolved) {
    return <LinearProgress />;
  }

  return <WrappedComponent {...props} />;
};

export default withAccessTokenRequired;
