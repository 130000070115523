import {
  all, put, takeLatest, takeEvery, select,
} from 'redux-saga/effects';
import { NotificationManager as NM } from 'react-notifications';

import { setIsGlobalLoading } from 'redux/actions';

import { actions } from './inboxListReducer';

import request from '../../utils/sagas/request';

import * as selectors from './inboxListSelectors';

import transformApprovalStats from './utils/transformApprovalStats';

export function* getItemsFlow() {
  yield put(setIsGlobalLoading(true));

  const showCompleted = yield select(selectors.showCompletedSelector);
  const inboxType = yield select(selectors.inboxTypeSelector);

  try {
    const { data } = yield request({
      method: 'post',
      url: 'inbox/_search',
      data: {
        inbox_type: inboxType,
        limit: 300,
        offset: 0,
        sort_options: 'DATE_DESC',
        show_completed: showCompleted,
      },
    });

    yield put(actions.getItemsSuccess({ data }));

    yield put(actions.getApprovalStatsRequest({ inboxIds: data.inbox_items.slice(0, 50).map((item) => item.id) }));
  } catch (e) {
    yield put(actions.getItemsFailure());
    NM.error('Something went wrong');
  }
  yield put(setIsGlobalLoading(false));
}

export function* getApprovalStatsFlow({ payload: { inboxIds } }) {
  if (inboxIds.length === 0) {
    return;
  }
  try {
    const { data } = yield request({
      method: 'post',
      url: 'inbox/_approval-stats',
      data: {
        inbox_ids: inboxIds,
      },
    });

    yield put(actions.getApprovalStatsSuccess({ stats: transformApprovalStats(data.items) }));
  } catch (e) {
    yield put(actions.getItemsFailure());
    NM.error('Something went wrong');
  }
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.getItemsRequest.type, getItemsFlow),
    takeEvery(actions.getApprovalStatsRequest.type, getApprovalStatsFlow),
  ]);
}
