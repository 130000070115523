import { useState } from 'react';
import { request } from 'api';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { NotificationManager as NM } from 'react-notifications';

import { mainActions } from 'redux/actions';

import useDownloadExportZip from 'hooks/export/useDownloadExportZip';

const useExportSubDocs = (inboxItem) => {
  const [isExported, setIsExported] = useState();
  const [isExporting, setIsExporting] = useState(false);
  const dispatch = useDispatch();
  const downloadExportZip = useDownloadExportZip();
  const { itemId } = useParams();

  const exportSubDocs = async () => {
    setIsExporting(true);
    dispatch(mainActions.setIsGlobalLoading(true));

    try {
      const { data: { inbox_items: items, total } } = await request({
        method: 'post',
        url: `/inbox/${itemId}/_list_sub_items`,
        data: {
          limit: 300,
          offset: 0,
        },
      });

      if (total === 0) {
        NM.error('No sub documents');
      } else {
        const { data: task } = await request({
          method: 'post',
          url: 'files/_export_zip',
          data: {
            target_file_name: inboxItem.name,
            file_ids: items.map((item) => item.file_item_id),
          },
        });
        await downloadExportZip(task.id, inboxItem.name);
      }
    } catch (e) {
      NM.error('Something went wrong');
    }

    dispatch(mainActions.setIsGlobalLoading(false));
    setIsExporting(false);
    setIsExported(true);
  };

  return { isExporting, isExported, exportSubDocs };
};

export default useExportSubDocs;
