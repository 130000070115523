import React, { useState, useEffect } from 'react';
import rotateBase64Image from 'utils/base64/rotateBase64Image';

const Thumbnail = ({
  className, base64: base64Prop, rotation, isAnotherDoc,
}) => {
  const [base64, setBase64] = useState(null);

  useEffect(() => {
    if (rotation === 0) {
      setBase64(base64Prop);
    } else {
      rotateBase64Image(base64Prop, rotation, (newBase64) => {
        setBase64(newBase64);
      });
    }
  }, [base64Prop, rotation]);

  if (!base64) {
    return null;
  }

  return (
    <div className={className}>
      <img alt="thumbnail" style={{ width: '100%', height: '100%', position: 'relative' }} src={base64} />
      {isAnotherDoc && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.4)',
          }}
        />
      )}
    </div>
  );
};

export default Thumbnail;
