import { useState, useEffect } from 'react';

import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

const useFileItemAnswers = (answer) => {
  const [fileItemById, setFileItemById] = useState(null);

  const fetchCourtDecisions = async () => {
    try {
      const { data: { files } } = await request({
        method: 'post',
        url: '/files/_search',
        data: {
          file_item_ids: answer.file_item_based_answers.map((item) => item.file_item_id),
          limit: 300,
        },
      });

      const obj = {};

      files.forEach((file) => {
        obj[file.id] = file;
      });

      setFileItemById(obj);
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  useEffect(() => {
    if (answer?.file_item_based_answers?.length > 0) {
      fetchCourtDecisions();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer?.id, answer?.file_item_based_answers?.length]);

  useEffect(() => {
    setFileItemById(null);
  }, [answer?.id]);

  if (!fileItemById || !answer?.file_item_based_answers) {
    return null;
  }

  return answer.file_item_based_answers.map((fileItemAnswer) => ({
    ...fileItemAnswer,
    fileItem: fileItemById[fileItemAnswer.file_item_id],
  })).filter(({ fileItem }) => fileItem);
};

export default useFileItemAnswers;
