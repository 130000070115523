import React, { useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import { useDidMount } from 'hooks';
import { NotificationManager as NM } from 'react-notifications';

import { request } from 'api';
import { LinearProgress } from '@mui/material';

const withCurrentInboxItem = (Component) => (props) => {
  const [item, setItem] = useState(null);
  const [searchParams] = useSearchParams();
  const { orgName } = useParams();
  const navigate = useNavigate();

  const fetchScan = async () => {
    try {
      const { data } = await request({
        method: 'get',
        url: `files/${searchParams.get('file_item_id')}`,
      });
      setItem(data);
    } catch (e) {
      if (e.response?.status === 404) {
        NM.error('File not found');
        // eslint-disable-next-line react/destructuring-assignment
        navigate(props.inboxType === 'DEFAULT' ? `/${orgName}/inbox` : `/${orgName}/balance-import`);
      } else {
        NM.error('Something went wrong');
      }
    }
  };

  useDidMount(() => {
    fetchScan();
  });

  if (!item) {
    return <LinearProgress />;
  }

  return (
    <Component {...props} inboxItem={item} />
  );
};

export default withCurrentInboxItem;
