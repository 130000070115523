import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { NotificationManager as NM } from 'react-notifications';

import { InputBase } from '@mui/material';

import request from 'api/request';

import { currentImmoSelectors } from 'redux/selectors';

// eslint-disable-next-line no-unused-vars
import useStyles from './AddressFieldValueEditMode.styles';

const AddressFieldValueEditMode = ({ value: propValue, onChange, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState(propValue);
  const currentImmo = useSelector(currentImmoSelectors.immoSelector);

  const handleSubmit = async () => {
    if (value === propValue) {
      onClose();
      return;
    }

    setIsSubmitting(true);

    try {
      const { data } = await request({
        method: 'patch',
        url: `/immos/${currentImmo.id}`,
        data: {
          name: currentImmo.name,
          address: value,
        },
      });
      onChange(data.address_details);
      onClose();
    } catch (error) {
      NM.error('Something went wrong');
    }

    setIsSubmitting(false);
  };

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case 13:
        handleSubmit();
        break;
      case 27:
        onClose();
        break;
      default:
        break;
    }
  };

  return (
    <InputBase
      disabled={isSubmitting}
      fullWidth
      sx={{ fontWeight: 400, fontSize: 16 }}
      autoFocus
      value={value}
      onKeyDown={handleKeyDown}
      onBlur={handleSubmit}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

export default AddressFieldValueEditMode;
