import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { IconButton } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import Link from '@mui/material/Link';

import SuggestedImmoCellEditMode from './SuggestedImmoCellEditMode';

import useStyles from './SuggestedImmoCell.styles';

const SuggestedImmoCell = ({ item }) => {
  const { orgName } = useParams();
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);

  if (!item.approval) {
    return null;
  }

  const handleEditClick = (e) => {
    setIsEdit(true);
    e.preventDefault();
    e.stopPropagation();
  };

  const renderContent = () => {
    if (!isEdit) {
      const isEditable = item.approval.status === 'CREATED'
        || item.approval.status === 'REJECTED'
        || item.approval.status === 'SENT';

      return (
        <div className={classes.viewMode}>
          {item.approval.suggestedImmoName && (
            <Link
              href={`/${orgName}/${item.approval.suggested_immo_id}/approvals`}
              target="_blank"
              rel="noreferrer"
              className={classes.immoName}
            >
              {item.approval.suggestedImmoName}
            </Link>
          )}
          {isEditable && (
            <IconButton size="small" className={classes.editButton} onClick={handleEditClick}>
              <ModeEditIcon />
            </IconButton>
          )}
        </div>
      );
    }

    return (
      <SuggestedImmoCellEditMode
        item={item}
        onClose={() => setIsEdit(false)}
      />
    );
  };

  return (
    <TableCell className={classes.root}>
      {renderContent()}
    </TableCell>
  );
};

export default SuggestedImmoCell;
