/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload: { user } }) => {
      state.user = user;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
