import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { approvalListSelectors as selectors } from 'redux/selectors';
import { approvalListActions as actions } from 'redux/actions';

import FileNameTableCell from 'components/FileNameTableCell';
import ColumnVisibilitySelect from 'components/TableSettingsProvider/ColumnVisibilitySelect';
import useTableSettings from 'components/TableSettingsProvider/useTableSettings';
import FileCell from 'components/FileCell';
import FileRawActions from 'components/FileRawActions';

import ApprovalCellContent from './ApprovalCellContent';

// eslint-disable-next-line no-unused-vars
import useStyles from './ImmoApprovalsTable.styles';

const ImmoApprovalsTable = () => {
  const dispatch = useDispatch();
  const items = useSelector(selectors.itemsSelector) || [];
  const { visibleColumns } = useTableSettings();

  const handleItemUpdate = (fileItem) => {
    dispatch(actions.updateFile({ fileItem }));
  };

  const renderBodyCell = (column, item) => {
    const { file } = item;

    switch (column.type) {
      case 'APPROVAL':
        return (
          <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
            <ApprovalCellContent item={item} />
          </TableCell>
        );
      default:
        return <FileCell item={file} columnType={column.type} onItemUpdate={handleItemUpdate} />;
    }
  };

  return (
    <TableContainer sx={{ height: '100%' }}>
      <Table stickyHeader aria-label="sticky table" style={{ padding: 16 }}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{ paddingLeft: 0, paddingRight: 0, width: 40 }}
              colSpan={2}
            >
              Name
            </TableCell>
            {visibleColumns.map((column) => (
              <TableCell key={column.type} align={column.align}>
                {column.title}
              </TableCell>
            ))}
            <TableCell align="right" sx={{ width: 0 }}>
              <ColumnVisibilitySelect />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={item.id}>
              <FileNameTableCell item={item.file} onItemUpdate={handleItemUpdate} />
              {visibleColumns.map((column) => (
                <Fragment key={column.type}>
                  {renderBodyCell(column, item)}
                </Fragment>
              ))}
              <TableCell align="right">
                <FileRawActions item={item.file} withMoreMenu={false} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ImmoApprovalsTable;
