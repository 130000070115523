import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { format, parseISO } from 'date-fns';

import { DatePicker } from '@mui/x-date-pickers';

import useStyles from './EditableDateCellEditMode.styles';

const EditableDateCellEditMode = ({
  initialValue,
  onSubmit,
  onFocus,
  onBlur,
  onClose,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const valueRef = useRef(initialValue ? parseISO(initialValue) : null);

  const handleSubmit = async () => {
    onSubmit(
      valueRef.current ? format(valueRef.current, 'yyyy-MM-dd') : valueRef.current,
    );
  };

  const handleFocus = () => {
    onFocus();
    setIsFocused(true);
  };

  const handleBlur = () => {
    onBlur();
    handleSubmit();
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    onClose();
    handleSubmit();
    setIsOpen(false);
  };

  const handleChange = (newValue) => {
    valueRef.current = newValue;
  };

  const showOpenButton = isFocused || isOpen;

  return (
    <DatePicker
      className={cx(classes.root, !showOpenButton && classes.hideOpenButton)}
      defaultValue={initialValue ? new Date(initialValue) : undefined}
      format="yyyy-MM-dd"
      slotProps={{
        textField: {
          variant: 'standard',
          InputProps: {
            disableUnderline: true,
          },
          inputProps: {
            style: {
              padding: 0,
            },
          },
          onFocus: handleFocus,
          onBlur: handleBlur,
        },
      }}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleChange}
    />
  );
};

export default EditableDateCellEditMode;
