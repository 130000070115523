import React, { useState } from 'react';
import { NotificationManager as NM } from 'react-notifications';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';

import FileNameTableCell from 'components/FileNameTableCell';

import useDidMount from 'hooks/useDidMount';

import request from 'api/request';

export default function RecentFilesDialog({ onSelect, onClose }) {
  const [files, setFiles] = useState(null);

  const handleClose = () => {
    onClose();
  };

  const loadFiles = async () => {
    try {
      const { data } = await request({
        method: 'post',
        url: '/files/_search',
        data: {
          sort_options: 'DATE_DESC',
          file_types: ['single_document'],
        },
      });

      setFiles(data.files);
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  useDidMount(() => {
    loadFiles();
  });

  const renderContent = () => {
    if (!files) {
      return null;
    }

    if (files.length === 0) {
      return 'No files';
    }

    return (
      <TableContainer>
        <Table stickyHeader style={{ padding: 16 }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ paddingLeft: 0, paddingRight: 0, width: 40 }}
                colSpan={3}
              >
                Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((item) => (
              <TableRow>
                <FileNameTableCell item={item} />
                <TableCell>
                  <Button onClick={() => onSelect(item)}>Select</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Dialog
      open
      maxWidth="xl"
      onClose={handleClose}
    >
      <DialogTitle>
        Select a file
      </DialogTitle>
      <DialogContent style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        {renderContent()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
