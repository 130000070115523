import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  paper: {
    overflow: 'visible',
  },
  fileViewDialogWithNavigation: {
    marginBottom: 64,
    height: 'calc(100% - 96px)',
  },
  navigationButtonGroup: {
    '& .MuiButton-root': {
      backgroundColor: 'rgba(50, 50, 50)',
      borderColor: '#bdbdbd !important',

      '&:hover': {
        backgroundColor: 'rgba(80, 80, 80)',
      },

      '&.Mui-disabled': {
        backgroundColor: 'rgba(120, 120, 120)',

        color: '#fff',
      },
    },

    position: 'absolute',
    bottom: -36,
    left: '50%',
    transform: 'translateX(-50%)',
  },
});

export default useStyles;
