import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import qs from 'query-string';
import { Auth0Provider } from '@auth0/auth0-react';

const queryParams = qs.parse(window.location.search);

const withAuth0Provider = (WrappedComponent) => (props) => {
  const { orgName } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    const returnTo = appState?.returnTo;

    if (returnTo) {
      navigate(returnTo, { replace: true });
    }
  };

  const getOrganization = () => {
    if (searchParams.get('error')) {
      return undefined;
    }

    return orgName || searchParams.get('organization') || undefined;
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: 'secretairy-service-api',
        invitation: queryParams.invitation || undefined,
        organization: getOrganization(),
      }}
      cacheLocation="localstorage"
      useRefreshTokens
      onRedirectCallback={onRedirectCallback}
    >
      <WrappedComponent {...props} />
    </Auth0Provider>
  );
};

export default withAuth0Provider;
