import React from 'react';

import Layout from 'components/Layout';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

import { LinearProgress } from '@mui/material';

import ImmoSubNavigation from 'components/Layout/Navigation/ImmoSubNavigation';

import ImageWithJWT from 'components/ImageWithJwt/ImageWithJwt';
import AddressFieldValue from 'pages/ImmoOverviewPage/AddressFieldValue';
import useImmoOverview from './hooks/useImmoOverview';
import RentInfoTable from './RentInfoTable';
import UtilitiesInfoSection from './UtilitiesInfoSection';
import EnergyEfficiency from './EnergyEfficiency';

import useStyles from './ImmoOverviewPage.styles';

const ImmoOverviewPage = () => {
  const classes = useStyles();
  const { data, updateAddress } = useImmoOverview();
  const { isLoaded: isMapLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAqNuUkpl0qEjXXd8uGRromI1ptF1VynXo',
  });

  const renderMap = () => {
    if (!isMapLoaded) {
      return null;
    }

    const coordinates = {
      lat: data.address_details.lat,
      lng: data.address_details.lon,
    };

    return (
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={coordinates}
        zoom={15}
        Marker
      >
        <Marker position={coordinates} />
      </GoogleMap>
    );
  };

  const renderTitle = (title) => <Typography sx={{ fontWeight: 500 }}>{title}</Typography>;

  const renderContent = () => {
    if (!data) {
      return <LinearProgress />;
    }

    return (
      <div style={{
        overflow: 'auto', height: '100%', boxSizing: 'border-box',
      }}
      >
        {data.address_details?.lat && (
          <Stack direction="row" spacing={1}>
            <ImageWithJWT
              style={{ width: 400, height: 400, flexShrink: 0 }}
              // eslint-disable-next-line max-len
              src={`${process.env.REACT_APP_STREET_VIEW_FUNCTION}?lat=${data.address_details.lat}&lng=${data.address_details.lon}`}
            />
            <div style={{ height: '400px', width: '100%' }}>
              {renderMap()}
            </div>
          </Stack>
        )}
        <div className={classes.field}>
          {renderTitle('Address')}
          <AddressFieldValue value={data.address_details?.formatted_address} onChange={updateAddress} />
        </div>
        {data.address_details?.formatted_address && (
          <>
            <div className={classes.field}>
              {renderTitle('Property type')}
              <Typography>{data.property_type}</Typography>
            </div>
            <div className={classes.field}>
              {renderTitle('Owners')}
              <Stack sx={{ mt: 1 }} direction="row" spacing={1}>
                {data.owners.map((owner) => (
                  <Chip key={owner} label={owner} />
                ))}
              </Stack>
            </div>
            {data.energy_efficiency && (
              <div className={classes.field}>
                {renderTitle('Energy efficiency')}
                <EnergyEfficiency data={data.energy_efficiency} />
              </div>
            )}
            <div className={classes.field}>
              {renderTitle('Rent info')}
              <RentInfoTable items={data.rent_info} />
            </div>
            <div className={classes.field}>
              {renderTitle('Utilities info')}
              <UtilitiesInfoSection utilitiesInfo={data.utilities_info} />
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <Layout
      app="immos"
      breadCrumbsContent={[
        <Typography key="3" color="text.primary">
          Overview
        </Typography>,
      ]}
      subNav={<ImmoSubNavigation />}
    >
      <div style={{ padding: 16, height: '100%', boxSizing: 'border-box' }}>
        {renderContent()}
      </div>
    </Layout>
  );
};

export default ImmoOverviewPage;
