import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import ViewListIcon from '@mui/icons-material/ViewList';

import { searchActions as actions } from 'redux/actions';
import { searchSelectors as selectors } from 'redux/selectors';

const SearchResultsViewTypeSwitcher = () => {
  const dispatch = useDispatch();

  const viewType = useSelector(selectors.viewTypeSelector);

  const handleChange = (event, newAlignment) => {
    if (newAlignment) {
      dispatch(actions.setViewType({ value: newAlignment }));
    }
  };

  return (
    <ToggleButtonGroup
      value={viewType}
      size="small"
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="cards">
        <ViewAgendaIcon />
      </ToggleButton>
      <ToggleButton value="table">
        <ViewListIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default SearchResultsViewTypeSwitcher;
