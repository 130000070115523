/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';

import {
  Chip,
  Divider, IconButton,
} from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { fileSplittingActions as actions } from 'redux/actions';

import DraggableSubDocPage from 'components/SplittingView/SplittingPageRow/DraggableSubDocPage';
import SimpleBar from 'components/UIKit/SimpleBar';

import PreviewPageDialog from './PreviewPageDialog';

import useStyles from './SplittingPageRow.styles';

const roundConfidence = (conf) => (Math.floor(conf * 100000) / 100000).toFixed(5);

const SplittingPageRow = ({
  index: subDocIndex, data, metaKeyPressed, selectedPages, countSelectedPages, isDragging,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isPreviewDialogOpened, setIsPreviewDialogOpened] = useState(false);
  const [previewPageIndex, setPreviewPageIndex] = useState(0);

  const {
    subDocs, preparationTaskId, showConfidences, base64ByPageNumber, pdfObj, highlightedPageNumber, zoom,
  } = data;

  const subDoc = subDocs[subDocIndex];

  const renderConfidence = (splitConfidence) => (
    (typeof splitConfidence === 'number') && showConfidences ? roundConfidence(splitConfidence) : null
  );

  const handleZoomClick = (pageIndex) => {
    setIsPreviewDialogOpened(true);
    setPreviewPageIndex(pageIndex);
  };

  const handleDeleteClick = (pageIndex) => {
    dispatch(actions.deletePage({ subDocIndex, pageIndex }));
  };

  const handleRotateLeftClick = (pageIndex) => {
    dispatch(actions.rotateLeftPage({ subDocIndex, pageIndex }));
  };

  const handleRotateRightClick = (pageIndex) => {
    dispatch(actions.rotateRightPage({ subDocIndex, pageIndex }));
  };

  const handleSplit = (pageIndex) => {
    dispatch(actions.splitPages({ subDocIndex, pageIndex }));
  };

  const handleSubDocsJoin = () => {
    dispatch(actions.joinSubDocs({ subDocIndex }));
  };

  const handlePreviewDialogClose = () => {
    setIsPreviewDialogOpened(false);
  };

  const handleSelectToggle = (pageNumber) => {
    dispatch(actions.selectPage({ pageNumber }));
  };

  const dividerConfidence = renderConfidence(subDoc.pages.at(-1).splitConfidence);

  return (
    <div>
      <div style={{ marginTop: '-8px' }}>
        <Chip label={`#${subDocIndex + 1}`} sx={{ ml: '16px' }} />
        <SimpleBar>
          <Droppable droppableId={`${subDocIndex}`} direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                className={classes.pages}
                {...provided.droppableProps}
              >
                {subDoc.pages.map((page, pageIndex) => (
                  <DraggableSubDocPage
                    key={page.number}
                    page={page}
                    pageIndex={pageIndex}
                    confidence={renderConfidence(page.splitConfidence)}
                    highlighted={highlightedPageNumber === page.number}
                    selected={selectedPages[page.number]}
                    isSelectedDragging={isDragging && selectedPages[page.number]}
                    countSelectedPages={countSelectedPages}
                    isLastPageInSubDoc={pageIndex === subDoc.pages.length - 1}
                    base64={base64ByPageNumber && base64ByPageNumber[page.number]}
                    preparationTaskId={preparationTaskId}
                    pdfObj={pdfObj}
                    metaKeyPressed={metaKeyPressed}
                    zoom={zoom}
                    onZoomClick={() => handleZoomClick(pageIndex)}
                    onDeleteClick={() => handleDeleteClick(pageIndex)}
                    onRotateLeftClick={() => handleRotateLeftClick(pageIndex)}
                    onRotateRightClick={() => handleRotateRightClick(pageIndex)}
                    onSplitClick={() => handleSplit(pageIndex)}
                    onSelectToggle={() => handleSelectToggle(page.number)}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </SimpleBar>
        {(subDocIndex < subDocs.length - 1) && (
        <Divider sx={{ p: '0 32px' }}>
          <IconButton
            sx={{ color: 'rgba(0, 0, 0, 0.54)', position: 'relative', zIndex: 10 }}
            onClick={() => handleSubDocsJoin(subDocIndex)}
          >
            <AddCircleIcon sx={{ width: 32, height: 32 }} />
          </IconButton>
          {dividerConfidence && `(${dividerConfidence})`}
        </Divider>
        )}
        {isPreviewDialogOpened && (
          <PreviewPageDialog
            subDocs={subDocs}
            defaultSubDocIndex={subDocIndex}
            defaultPageIndex={previewPageIndex}
            pdfObj={pdfObj}
            base64ByPageNumber={base64ByPageNumber}
            onClose={handlePreviewDialogClose}
          />
        )}
      </div>
    </div>
  );
};

export default memo(SplittingPageRow);
