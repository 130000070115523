import { media } from 'breakpoints';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  content: {
    [media.mobile]: {
      padding: 0,
    },
  },
});

export default useStyles;
