import { request } from 'api';
import { useDispatch } from 'react-redux';

import { NotificationManager as NM } from 'react-notifications';

import { mainActions } from 'redux/actions';

import useDownloadExportZip from 'hooks/export/useDownloadExportZip';

const useExportSubDocs = () => {
  const dispatch = useDispatch();
  const downloadExportZip = useDownloadExportZip();

  const exportSubDocs = async (fileItem) => {
    dispatch(mainActions.setIsGlobalLoading(true));

    try {
      const { data: { files, total } } = await request({
        method: 'post',
        url: '/files/_search',
        data: {
          origin_id: fileItem.id,
          limit: 300,
          offset: 0,
        },
      });

      if (total === 0) {
        NM.error('No sub documents');
      } else {
        const { data: task } = await request({
          method: 'post',
          url: 'files/_export_zip',
          data: {
            target_file_name: fileItem.name,
            file_ids: files.map((file) => file.id),
          },
        });
        await downloadExportZip(task.id, fileItem.name);
      }
    } catch (e) {
      NM.error('Something went wrong');
    }

    dispatch(mainActions.setIsGlobalLoading(false));
  };

  return exportSubDocs;
};

export default useExportSubDocs;
