import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { request } from 'api';
import { NotificationManager as NM } from 'react-notifications';

import { creationImmoDialogActions } from 'redux/actions';
import { creationImmoDialogSelectors } from 'redux/selectors';

import AddressAutocomplete from 'components/AddressAutocomplete';

export default function CreationImmoDialog() {
  const dispatch = useDispatch();
  const onSuccess = useSelector(creationImmoDialogSelectors.onSuccessSelector);
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [nameTouched, setNameTouched] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isFormValid = name.length > 0 && address.length > 0;

  const handleClose = () => {
    dispatch(creationImmoDialogActions.closeDialog());
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const { data } = await request({
        method: 'post',
        url: '/immos',
        data: {
          name,
          address,
        },
      });

      handleClose();

      if (onSuccess) {
        onSuccess(data);
      }
    } catch (e) {
      NM.error('Something went wrong');
    }

    setIsSubmitting(false);
  };

  const handleAddressChange = (res) => {
    setAddress(res?.formatted_address || '');

    if (!nameTouched && res?.formatted_address) {
      setName(res?.formatted_address);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setNameTouched(true);
  };

  return (
    <Dialog
      open
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle>
        Create immo
      </DialogTitle>
      <DialogContent>
        <AddressAutocomplete
          sx={{ mt: 2 }}
          label="Address"
          onChange={handleAddressChange}
        />
        <TextField fullWidth sx={{ mt: 2 }} value={name} label="Name" onChange={handleNameChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          disabled={!isFormValid || isSubmitting}
          onClick={handleSubmit}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
