import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { fileListActions as actions } from 'redux/actions';
import { fileListSelectors } from 'redux/selectors';

import FileNameTableCell from 'components/FileNameTableCell';
import FileRawActions from 'components/FileRawActions';
import FileCell from 'components/FileCell';
import MetadataCell from 'components/MetadataCell';

import useTableSettings from 'components/TableSettingsProvider/useTableSettings';
import useFileTransformedMetadata from 'hooks/filesTable/useFileTransformedMetadata';

import useFileItemHiddenCapabilities from './hooks/useFileItemHiddenCapabilities';

import LegacySubDocProcessButton from './LegacySubDocProcessButton';

const FileRaw = ({
  item,
  metadataColumns,
  highlighted,
  renderTypeCell,
  withRowProcessingActionColumn,
  onViewMetadata,
}) => {
  const dispatch = useDispatch();
  const { visibleColumns } = useTableSettings();
  const transformedMetadata = useFileTransformedMetadata(item);

  const hiddenCapabilities = useFileItemHiddenCapabilities(item);

  const pipelines = useSelector(fileListSelectors.pipelinesPerItemSelector);

  const handleFileUpdate = (newItem) => {
    dispatch(actions.updateFile({ file: newItem }));
  };

  const renderCell = (column) => {
    if (column.type === 'TYPE' && renderTypeCell) {
      return renderTypeCell(item);
    }

    if (column.type === 'METADATA') {
      return metadataColumns.map((metadataColumn) => {
        const metadata = transformedMetadata[metadataColumn.name];

        if (!metadata) {
          return <TableCell />;
        }

        return (
          <MetadataCell
            key={metadata.id}
            fileItem={item}
            metadata={metadata}
            hiddenCapabilities={hiddenCapabilities}
            onFileItemUpdate={handleFileUpdate}
          />
        );
      });
    }

    return (
      <FileCell
        key={column.type}
        item={item}
        hiddenCapabilities={hiddenCapabilities}
        columnType={column.type}
        onItemUpdate={handleFileUpdate}
      />
    );
  };

  const pipeline = pipelines[item.id];

  return (
    <TableRow hover selected={highlighted} role="checkbox" tabIndex={-1}>
      <FileNameTableCell
        item={item}
        hiddenCapabilities={hiddenCapabilities}
        pipeline={pipeline}
        onItemUpdate={handleFileUpdate}
      />
      {visibleColumns.map((column) => <Fragment key={column.type}>{renderCell(column)}</Fragment>)}
      {withRowProcessingActionColumn && (
        <TableCell align="right">
          {item.status === null && <LegacySubDocProcessButton item={item} onViewMetadata={onViewMetadata} />}
        </TableCell>
      )}
      <TableCell align="right">
        <FileRawActions item={item} />
      </TableCell>
    </TableRow>
  );
};

export default FileRaw;
