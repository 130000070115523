import React from 'react';
import { useSelector } from 'react-redux';

import TableSettingsProvider from 'components/TableSettingsProvider';
import { COLUMN_LIST } from 'constants/searchFileList';

import { filtersSelectors } from 'redux/selectors';

import FilesTable from './SearchResultsTable';

const FilesTableContainer = (props) => {
  const fileTypes = useSelector(filtersSelectors.typesSelector);

  const columnList = [...COLUMN_LIST];

  if (fileTypes.length === 1) {
    columnList.push({
      type: 'METADATA',
      title: 'Metadata',
    });
  }

  return (
    <TableSettingsProvider tableId="searchResults" columns={columnList}>
      <FilesTable {...props} />
    </TableSettingsProvider>
  );
};

export default FilesTableContainer;
