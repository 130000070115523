import React from 'react';
import { useParams } from 'react-router-dom';

import SplittingView from 'components/SplittingView';

const ScanSplittingPage = () => {
  const { orgName, immoId, scanId } = useParams();

  return (
    <SplittingView
      fileItemId={scanId}
      backUrl={`/${orgName}/${immoId}/scans`}
    />
  );
};

export default ScanSplittingPage;
