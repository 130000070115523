import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Dialog, DialogTitle, LinearProgress, DialogContent, DialogContentText, DialogActions, Button,
} from '@mui/material';

const withAuthRequired = (WrappedComponent) => (props) => {
  const { orgName } = useParams();
  const [searchParams] = useSearchParams();
  const isInvitationLink = searchParams.get('invitation');
  const error = searchParams.get('error');

  const {
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const hasAccess = !isInvitationLink && isAuthenticated && (!orgName || orgName === user.org_name);

  useEffect(() => {
    if (isLoading || hasAccess || error) {
      return;
    }
    (async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: window.location.pathname + (isInvitationLink ? '' : window.location.search),
        },
      });
    })();
  }, [
    isInvitationLink,
    hasAccess,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    error,
  ]);

  const handleLogoutClick = () => {
    logout({ returnTo: window.location.origin });
  };

  if (error) {
    return (
      <Dialog open>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{searchParams.get('error_description')}</DialogContentText>
          <DialogActions>
            <Button onClick={handleLogoutClick}>Logout</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  return hasAccess ? <WrappedComponent {...props} /> : <LinearProgress />;
};

export default withAuthRequired;
