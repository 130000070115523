import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    position: 'fixed',
    zIndex: 10,
    bottom: 0,
    width: 360,
    right: 24,
    boxShadow: '0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15)',
    borderRadius: 16,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    overflow: 'hidden',
  },
  collapsed: {
    width: 200,
  },
  header: {
    height: 54,
    display: 'flex',
    alignItems: 'center',
    padding: [0, 16],
    paddingRight: 12,
    background: '#F9FAFD',
    justifyContent: 'space-between',
  },
  tasks: {
    maxHeight: 240,
  },
  task: {
    height: 51,
    display: 'flex',
    alignItems: 'center',
    padding: [0, 16],
    justifyContent: 'space-between',
  },
  fileName: {
    fontSize: 14,
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    color: 'inherit',
  },
});

export default useStyles;
