import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationManager as NM } from 'react-notifications';
import cx from 'classnames';

import Dialog from '@mui/material/Dialog';
import { Button, ButtonGroup } from '@mui/material';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import request from 'api/request';

import useFilePreview from 'hooks/useFilePreview';
import { mainActions } from 'redux/actions';

import useFilePreviewNavigation from './hooks/useFilePreviewNavigation';

import FileViewDialogContent from './FileViewDialogContent';

import useStyles from './FileViewDialog.styles';

export default function FileViewDialog({ fileItemId, pageNumber }) {
  const classes = useStyles();
  const [url, setUrl] = useState();
  const [fileItem, setFileItem] = useState({ });
  const { closeFilePreview } = useFilePreview();
  const dispatch = useDispatch();

  const {
    isNavigationVisible,
    hasPrev,
    hasNext,
    goPrev,
    goNext,
  } = useFilePreviewNavigation(fileItemId);

  const fileItemRef = useRef(fileItem);

  fileItemRef.current = fileItem;

  const handleClose = () => {
    closeFilePreview();
    setTimeout(() => {
      dispatch(mainActions.setHighlightedItemId({ value: fileItemId }));
    }, 0);
  };

  const handleResolveUrl = async () => {
    try {
      const { data } = await request({
        method: 'post',
        url: '/document-download-links/_generate',
        data: {
          file_item_id: fileItemId,
        },
      });
      setUrl(data.signed_link);
    } catch (e) {
      NM.error('Something went wrong during resolving the link');
    }
  };

  const handleResolveFileItem = async () => {
    try {
      const { data } = await request({
        method: 'get',
        url: `/files/${fileItemId}`,
      });

      setFileItem(data);
    } catch (e) {
      NM.error('Something went wrong during resolving the link');
    }
  };

  useEffect(() => {
    handleResolveUrl();
    handleResolveFileItem();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileItemId]);

  const handleFileItemUpdate = (newFileItem) => {
    setFileItem(newFileItem);
  };

  const renderContent = () => {
    if (!url || !fileItem.name) {
      return null;
    }

    return (
      <FileViewDialogContent
        fileItem={fileItem}
        url={url}
        pageNumber={pageNumber}
        onFileItemUpdate={handleFileItemUpdate}
        onClose={handleClose}
      />
    );
  };

  return (
    <Dialog
      open
      classes={{
        paper: cx(
          classes.paper,
          isNavigationVisible && classes.fileViewDialogWithNavigation,
        ),
      }}
      maxWidth="xl"
      fullWidth
      disableRestoreFocus
      onClose={handleClose}
    >
      {renderContent()}
      {isNavigationVisible && (
        <ButtonGroup variant="contained" className={classes.navigationButtonGroup}>
          <Button variant="contained" disabled={!hasPrev} onClick={goPrev}>
            <KeyboardArrowLeftIcon />
          </Button>
          <Button variant="contained" disabled={!hasNext} onClick={goNext}>
            <KeyboardArrowRightIcon />
          </Button>
        </ButtonGroup>
      )}
    </Dialog>
  );
}
