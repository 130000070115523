import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';

import { inboxItemSubDocsActions as actions } from 'redux/actions';
import { inboxItemSubDocsSelectors as selectors } from 'redux/selectors';

const PushDocsToImmosButton = () => {
  const dispatch = useDispatch();
  const items = useSelector(selectors.transformedItemsSelector);

  const approvalRequestIds = items
    .filter((item) => item.canBePushedToImmo)
    .map((item) => item.approval.id);

  const handlePushDocsToImmos = () => {
    dispatch(actions.pushDocsToImmos({ approvalRequestIds }));
  };

  return (
    <Button
      variant="text"
      disabled={approvalRequestIds.length === 0}
      onClick={handlePushDocsToImmos}
    >
      Push docs to immos
    </Button>
  );
};

export default PushDocsToImmosButton;
