import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';

const ThemeProvider = ({ children }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: 'rgba(250, 99, 42, 1)',
      },
    },
    components: {
      MuiTableBody: {
        styleOverrides: {
          root: {
            '& .MuiTableCell-root': {
              // verticalAlign: 'top',
              letterSpacing: 'normal',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            letterSpacing: 'normal',
          },
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ThemeProvider;
