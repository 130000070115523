import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';

import { LinearProgress, Stack } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Chip from '@mui/material/Chip';

import WarningIcon from '@mui/icons-material/Warning';

import { mainSelectors } from 'redux/selectors';

import { mainActions } from 'redux/actions';
import HeaderContent from './HeaderContent';
import Navigation from './Navigation';
import CommonDialogs from './CommonDialogs';
import ProcessingPopup from './ProcessingPopup';

import useResetHighlightedItemIdByClick from './hooks/useResetHighlightedItemIdByClick';
import useFilters from './hooks/useFilters';

import useStyles from './Layout.styles';

function Layout({
  app,
  newButton,
  breadCrumbsContent,
  searchBox,
  filterChips,
  subNav,
  children,
  mainInnerClassName,
  filterChipsClassName,
  isInitialLoading,
  withEditMode,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isLoading = useSelector(mainSelectors.isLoadingSelector);
  const isEditMode = useSelector(mainSelectors.isEditModeSelector);

  useResetHighlightedItemIdByClick();
  useFilters();

  useEffect(() => {
    if (!withEditMode) {
      dispatch(mainActions.setEditMode({ value: false }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withEditMode]);

  const handleEditModeChange = (e) => {
    dispatch(mainActions.setEditMode({ value: e.target.checked }));
  };

  const withSearchSection = searchBox || newButton || withEditMode;

  return (
    <div className={cx(classes.root, !withSearchSection && classes.noSearchBox)}>
      <Navigation className={classes.navigation} app={app} subNav={subNav} />
      {isLoading && <LinearProgress className={classes.progress} />}
      <div className={classes.header}>
        <HeaderContent app={app} breadCrumbsContent={breadCrumbsContent} />
      </div>
      {withSearchSection && !isInitialLoading
        && (
        <Stack className={classes.search} spacing="32px" direction="row">
          <div style={{ flexGrow: 1 }}>
            {searchBox || null}
          </div>
          {(withEditMode || newButton) && (
            <Stack spacing="16px" direction="row">
              {withEditMode && (
                <FormControlLabel
                  control={<Switch checked={isEditMode} onChange={handleEditModeChange} />}
                  label="Edit mode"
                />
              )}
              {newButton}
            </Stack>
          )}
        </Stack>
        )}
      {filterChips && !isInitialLoading && (
        <div className={classes.filterChips}>
          <Stack direction="row" spacing="8px" className={filterChipsClassName}>
            {filterChips}
          </Stack>
        </div>
      )}
      <div className={classes.main}>
        {isEditMode && (
          <Chip icon={<WarningIcon />} className={classes.editModeChip} label="Edit mode" color="primary" />
        )}
        <div className={cx(classes.mainInner, mainInnerClassName, isEditMode && classes.editMode)}>
          <div className={classes.mainInnerScroll}>
            {children}
          </div>
        </div>
      </div>
      <CommonDialogs />
      <ProcessingPopup />
    </div>
  );
}

export default Layout;
