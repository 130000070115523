export const COLUMN_LIST = [
  {
    type: 'ADDRESS',
    title: 'Address',
  },
  {
    type: 'documents_waiting_approval_count',
    title: 'Awaiting approvals docs',
    align: 'right',
    isDefaultVisible: true,
    isSortable: true,
  },
  {
    type: 'total_documents',
    title: 'Total docs',
    align: 'right',
    isDefaultVisible: true,
    isSortable: true,
  },
  {
    type: 'ENERGY_EFFICIENCY',
    title: 'Energy efficiency',
  },
];

export const energyEfficiencyColumns = [
  {
    type: 'living_space',
    title: 'Living space',
  },
  {
    type: 'building_construction_year',
    title: 'Year of house construction',
  },
  {
    type: 'heating_construction_year',
    title: 'Year of heating construction',
  },
  {
    type: 'annual_energy_requirement_sqm',
    title: 'Annual energy requirement per square meter (kwh)',
  },
  {
    type: 'energy_efficient_class',
    title: 'Energy class',
  },
  {
    type: 'heating_system_age',
    title: 'Age of Heating system in years',
  },
];
