import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    '& .MuiInputBase-root': {
      fontSize: 'inherit',
    },
  },
  hideOpenButton: {
    '& .MuiInputAdornment-positionEnd': {
      display: 'none',
    },
  },
});

export default useStyles;
