import {
  all, put, takeLatest, select,
} from 'redux-saga/effects';
import { NotificationManager as NM } from 'react-notifications';

import { setIsGlobalLoading } from 'redux/actions';

import { currentImmoSelectors } from 'redux/selectors';
import { actions } from './approvalListReducer';

import request from '../../utils/sagas/request';

export function* getItemListFlow() {
  const immoId = yield select(currentImmoSelectors.idSelector);
  yield put(setIsGlobalLoading(true));

  try {
    const { data: { approval_requests: items } } = yield request({
      method: 'post',
      url: '/approval-requests/_search',
      data: {
        immo_id: immoId,
        statuses: ['APPROVED', 'REJECTED', 'SENT'],
        limit: 300,
        offset: 0,
      },
    });

    if (items.length > 0) {
      const { data: { files } } = yield request({
        method: 'post',
        url: '/files/_search',
        data: {
          file_item_ids: items.map((item) => item.file_item_id),
          limit: 300,
        },
      });

      const filesObj = {};

      files.forEach((file) => {
        filesObj[file.id] = file;
      });

      items.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.file = filesObj[item.file_item_id];
      });
    }

    yield put(actions.getItemListSuccess({ items: items.filter((item) => item.file) }));
  } catch (e) {
    yield put(actions.getItemListFailure());
    NM.error('Something went wrong');
  }
  yield put(setIsGlobalLoading(false));
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.getItemListRequest.type, getItemListFlow),
  ]);
}
