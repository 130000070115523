import React from 'react';

import MuiTableCell from '@mui/material/TableCell';
import MuiTableSortLabel from '@mui/material/TableSortLabel';

const TableHeadCell = ({
  sort, column, children, onSort, ...otherProps
}) => {
  const sortDirection = sort?.sort_direction.toLowerCase();
  const sortBy = sort?.sort_by;
  const isActive = sortBy === column.type && sort?.isMetadata === column.isMetadata;

  const handleSortClick = () => {
    const getNewSortDirection = () => {
      if (isActive) {
        return sort?.sort_direction === 'ASC' ? 'DESC' : 'ASC';
      }

      return sort?.sort_direction;
    };

    onSort({
      sort_by: column.type,
      sort_direction: getNewSortDirection(),
      isMetadata: column.isMetadata,
    });
  };

  const renderContent = () => {
    if (!column.isSortable || !onSort) {
      return children;
    }

    return (
      <MuiTableSortLabel
        active={isActive}
        direction={sortDirection}
        onClick={handleSortClick}
      >
        {children}
      </MuiTableSortLabel>
    );
  };

  return (
    <MuiTableCell
      {...otherProps}
      sortDirection={sortDirection || false}
    >
      {renderContent()}
    </MuiTableCell>
  );
};

export default TableHeadCell;
