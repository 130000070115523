import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { mainSelectors } from 'redux/selectors';
import { mainActions } from 'redux/actions';

const useResetHighlightedItemIdByClick = () => {
  const dispatch = useDispatch();
  const highlightedItemId = useSelector(mainSelectors.highlightedItemIdSelector);

  const highlightedItemIdRef = useRef();

  highlightedItemIdRef.current = highlightedItemId;

  useEffect(() => {
    if (highlightedItemId) {
      const selectedRow = document.querySelector('.MuiTableRow-root.Mui-selected');

      if (selectedRow) {
        selectedRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [highlightedItemId]);

  useEffect(() => {
    // Function to handle any click on the document
    const handleDocumentClick = () => {
      if (highlightedItemIdRef.current) {
        dispatch(mainActions.setHighlightedItemId({ value: null }));
      }
    };

    // Adding the event listener to the document
    document.addEventListener('click', handleDocumentClick);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useResetHighlightedItemIdByClick;
