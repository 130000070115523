import React from 'react';
import PropTypes from 'prop-types';

import PipelineStep from './PipelineStep';

const Step = ({ step }) => {
  const status = step.status || 'NOT_STARTED';

  return (
    <PipelineStep
      status={status}
      title={`${step.step_type}: ${status}`}
      startedAt={step.started_at}
      finishedAt={step.finished_at}
    />
  );
};

Step.propTypes = {
  step: PropTypes.object.isRequired,
};

export default Step;
