/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  immoId: null,
  immo: null,
};

export const slice = createSlice({
  name: 'currentImmo',
  initialState,
  reducers: {
    setImmoId: (state, { payload: { value } }) => {
      state.immoId = value;
    },
    setImmo: (state, { payload: { data } }) => {
      state.immo = data;
    },
    reset: () => initialState,
  },
});

export const { actions } = slice;

export default slice.reducer;
