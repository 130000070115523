/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  folder: null,
  originId: null,
  items: null,
  isLoading: false,
  totalItems: null,
  searchTerm: '',
  sort: {
    sort_by: 'documents_waiting_approval_count',
    sort_direction: 'DESC',
  },
  hasMore: true,
  offset: 0,
  filters: {
    only_without_address: false,
    only_with_pending_approvals: false,
  },
};

export const slice = createSlice({
  name: 'immoList',
  initialState,
  reducers: {
    getItemsRequest: (state, { payload: { page } }) => {
      state.isLoading = true;
      if (page) {
        state.page = page;
      }
    },
    getItemsSuccess: (state, {
      payload: {
        data, offset, hasMore, loadMore,
      },
    }) => {
      if (!state.items || !loadMore) {
        state.items = data.immos;
      } else {
        state.items = [
          ...state.items,
          ...data.immos,
        ];
      }

      state.hasMore = hasMore;
      state.offset = offset;
      state.isLoading = false;
      state.totalItems = data.total;
    },
    getItemsFailure: (state) => {
      state.isLoading = false;
    },
    updateItem: (state, { payload: { immo } }) => {
      if (state.items) {
        const index = state.items.findIndex((item) => item.id === immo.id);

        if (index !== -1) {
          state.items[index] = immo;
        }
      }
    },
    setSearchTerm: (state, { payload: { value } }) => {
      state.searchTerm = value;
    },
    addItem: (state, { payload: { newImmo } }) => {
      if (state.items) {
        state.items.unshift(newImmo);
      }
    },
    setSort: (state, { payload: { sort } }) => {
      state.sort = sort;
    },
    setFilter: (state, { payload: { name, value } }) => {
      state.filters[name] = value;
    },
    setFilters: (state, { payload: { value } }) => {
      state.filters = value;
    },
    reset: () => initialState,
  },
});

export const { actions } = slice;

export default slice.reducer;
