import { useState } from 'react';
import axios from 'axios';
import { NotificationManager as NM } from 'react-notifications';

import { request } from 'api';
import useTask from 'hooks/useTask';

const useFileItem = () => {
  const [fileItem, setFileItem] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const { task } = useTask({ fileItemId: fileItem?.id });

  const uploadFile = async (file) => {
    setIsUploading(true);
    try {
      const { data: signedLinkData } = await request({
        method: 'post',
        url: '/document-upload-links/_generate',
        data: {
          file_type: 'PDF',
          file_name: file.name,
        },
      });

      await axios({
        method: 'put',
        url: signedLinkData.signed_link,
        data: file,
        headers: {
          'Content-Type': file.type,
          'x-goog-if-generation-match': 0,
        },
      });

      const { data } = await request({
        method: 'post',
        url: '/files/single-documents',
        data: {
          name: file.name,
          gcs_object_id: signedLinkData.gcs_object_id,
        },
      });

      await request({
        method: 'post',
        url: `/files/${data.id}/_prepare_split`,
        data: {},
      });

      setFileItem(data);
    } catch (e) {
      NM.error('Uploading failed');
    }
    setIsUploading(false);
  };

  return {
    fileItem,
    isUploading,
    isProcessing: fileItem && !fileItem.selected && (!task || task.status === 'processing'),
    uploadFile,
    setFileItem,
  };
};

export default useFileItem;
