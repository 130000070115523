/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: null,
  isLoading: false,
  totalItems: null,
  page: 1,
  sort: 'DATE_DESC',
};

export const slice = createSlice({
  name: 'approvalList',
  initialState,
  reducers: {
    setSort: (state, { payload: { value } }) => {
      state.sort = value;
    },
    getItemListRequest: (state, { payload: { page } }) => {
      state.isLoading = true;
      if (page) {
        state.page = page;
      }
    },
    getItemListSuccess: (state, { payload: { items } }) => {
      state.items = items;
      state.isLoading = false;
    },
    getItemListFailure: (state) => {
      state.isLoading = false;
    },
    updateApproval: (state, { payload: { approval } }) => {
      if (state.items) {
        const index = state.items.findIndex((item) => item.id === approval.id);

        if (index !== -1) {
          state.items[index] = {
            ...state.items[index],
            ...approval,
          };
        }
      }
    },
    updateFile: (state, { payload: { fileItem } }) => {
      if (state.items) {
        const index = state.items.findIndex((item) => item.file.id === fileItem.id);

        if (index !== -1) {
          state.items[index] = {
            ...state.items[index],
            file: fileItem,
          };
        }
      }
    },
    reset: () => initialState,
  },
});

export const { actions } = slice;

export default slice.reducer;
