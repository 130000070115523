import { useEffect, useRef } from 'react';
import useState from 'react-usestateref';
import { useDispatch } from 'react-redux';
import { useSearchParams, useParams } from 'react-router-dom';

import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

import { setIsGlobalLoading } from 'redux/actions';

import { getAnswersSettings } from 'utils/answersSettings';

const completedStatusSet = new Set(['COMPLETE', 'PARTIAL_SUCCESS']);

async function createAnswer({ body }) {
  try {
    const { data } = await request({
      method: 'post',
      url: '/answers',
      data: body,
    });
    return data;
  } catch (error) {
    throw new Error('Error');
  }
}

async function fetchAnswerSetById(id) {
  try {
    const { data } = await request({
      method: 'get',
      url: `/answers/${id}`,
    });
    return data;
  } catch (error) {
    throw new Error('Error');
  }
}

async function generateSummary(answerId) {
  try {
    const { data } = await request({
      method: 'post',
      url: `/answers/${answerId}/_generate_summary`,
      data: {},
    });
    return data;
  } catch (error) {
    throw new Error('Error');
  }
}

const useAnswer = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { immoId } = useParams();
  const [answer, setAnswer, answerSetRef] = useState(null);
  const [isSavedAnswerLoading, setIsSavedAnswerLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingSummary, setIsGeneratingSummary, isGeneratingSummaryRef] = useState(false);
  const prevSearchRequestRef = useRef(null);

  const answerId = answer?.id;

  const fetchAnswer = async (id) => {
    setIsSavedAnswerLoading(true);
    try {
      const answerSet = await fetchAnswerSetById(id);

      setAnswer(answerSet);
      setIsSavedAnswerLoading(false);
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  const searchParamsAnswerId = searchParams.get('a');

  useEffect(() => {
    if (searchParamsAnswerId && searchParamsAnswerId !== answerId) {
      fetchAnswer(searchParamsAnswerId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParamsAnswerId, answerId]);

  const pollAnswer = async (id) => {
    try {
      const answerSet = await fetchAnswerSetById(id);

      if (completedStatusSet.has(answerSet.status)) {
        generateSummary(answerSet.id);
        setIsGeneratingSummary(true);
      }

      if ((answerSet.status === 'ERROR' || answerSet.status === 'PARTIAL_SUCCESS')) {
        NM.error('Something went wrong');
      }

      if (answerSet.status === 'ERROR') {
        dispatch(setIsGlobalLoading(false));
      }

      setAnswer(answerSet);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const pollAnswerSummary = async (id) => {
    try {
      const answerSet = await fetchAnswerSetById(id);

      if (answerSet.summary) {
        dispatch(setIsGlobalLoading(false));
        setIsGeneratingSummary(false);

        setAnswer(answerSet);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    setIsGeneratingSummary(false);
    const intervalId = setInterval(() => {
      if (answerSetRef.current?.status === 'IN_PROGRESS') {
        pollAnswer(answerSetRef.current.id);
      } else if (isGeneratingSummaryRef.current) {
        pollAnswerSummary(answerSetRef.current.id);
      }
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerSetRef.current?.id]);

  const handleSubmit = async (question) => {
    const answerSettings = getAnswersSettings();

    const requestBody = {
      question,
      temperature: 0,
      ...answerSettings,
      file_item_rules: {
        immo_ids: [immoId],
        ...answerSettings.file_item_rules,
      },
    };

    const stringifiedSearchRequest = JSON.stringify(requestBody);

    if (prevSearchRequestRef.current === stringifiedSearchRequest || question.length < 10) {
      return;
    }

    dispatch(setIsGlobalLoading(true));

    prevSearchRequestRef.current = stringifiedSearchRequest;

    setIsGenerating(true);

    try {
      const answerSet = await createAnswer({ body: requestBody });

      setAnswer(answerSet);
      searchParams.set('a', answerSet.id);
      // setSearchParams(searchParams);
    } catch (e) {
      prevSearchRequestRef.current = null;
      NM.error('Something went wrong');
    }

    setIsGenerating(false);
  };

  return {
    isGenerating,
    isGeneratingSummary,
    isSavedAnswerLoading,
    answer,
    onSubmit: handleSubmit,
  };
};

export default useAnswer;
