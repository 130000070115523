/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  inboxType: null,
  itemId: null,
  items: null,
  isLoading: false,
  totalItems: null,
  page: 1,
  sort: 'DATE_DESC',
};

export const slice = createSlice({
  name: 'inboxItemSubDocs',
  initialState,
  reducers: {
    setInboxType: (state, { payload: { value } }) => {
      state.inboxType = value;
    },
    setItemId: (state, { payload: { itemId } }) => {
      state.itemId = itemId;
    },
    getItemsRequest: (state, { payload: { page } }) => {
      state.isLoading = true;
      if (page) {
        state.page = page;
      }
    },
    getItemsSuccess: (state, { payload: { items } }) => {
      state.items = items;
      state.isLoading = false;
    },
    getItemsFailure: (state) => {
      state.isLoading = false;
    },
    updateFile: (state, { payload: { fileItem } }) => {
      if (state.items) {
        const index = state.items.findIndex((item) => item.file_item_id === fileItem.id);

        if (index !== -1) {
          const item = state.items[index];

          item.document_type_de = fileItem.document_type_de;
          item.ai_generated_type = fileItem.ai_generated_type;
          item.name = fileItem.name;
        }
      }
    },
    generateNames: (state) => {
      if (state.items) {
        // eslint-disable-next-line no-return-assign
        state.items.forEach((item) => item.isNameGenerating = true);
      }
    },
    setApproval: (state, { payload: { itemId, approval } }) => {
      if (state.items) {
        const index = state.items.findIndex((item) => item.id === itemId);

        if (index !== -1) {
          state.items[index].approval = approval;
        }
      }
    },
    pushDocsToImmos: () => {},
    setIsPushing: (state, { payload: { ids, value } }) => {
      const idsSet = new Set(ids);

      if (state.items) {
        state.items.forEach((item) => {
          if (idsSet.has(item.approval?.id)) {
            item.isPushing = value;
          }
        });
      }
    },
    reset: () => initialState,
  },
});

export const { actions } = slice;

export default slice.reducer;
