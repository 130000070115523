import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { immoListActions as actions } from 'redux/actions';
import { immoListSelectors as selectors } from 'redux/selectors';

const ImmosApprovalsFilter = () => {
  const dispatch = useDispatch();
  const filters = useSelector(selectors.filtersSelector);

  const handleChange = (event) => {
    const { value } = event.target;

    dispatch(actions.setFilter({
      name: 'only_with_pending_approvals',
      value: value === 'with_pending_approvals',
    }));
  };

  const value = filters.only_with_pending_approvals === true ? 'with_pending_approvals' : 'all';

  return (
    <FormControl size="small" sx={{ width: 150 }}>
      <InputLabel>Approvals</InputLabel>
      <Select
        label="Approvals"
        value={value}
        onChange={handleChange}
        onClose={() => {
          setTimeout(() => {
            document.activeElement.blur();
          }, 0);
        }}
      >
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="with_pending_approvals">With awaiting approvals</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ImmosApprovalsFilter;
