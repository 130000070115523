import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { request } from 'api';
import { approvalListActions as actions } from 'redux/actions';
import { NotificationManager as NM } from 'react-notifications';

export default function RejectApprovalDialog({ item, onClose }) {
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await request({
        method: 'POST',
        url: `/approval-requests/${item.id}/_resolve`,
        data: {
          approved: false,
          comment: comment || null,
        },
      });

      dispatch(actions.updateApproval({ approval: data }));
      onClose();
    } catch (e) {
      NM.error('Something went wrong');
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog
      open
      fullWidth
      onClose={onClose}
    >
      <DialogTitle>
        Reject
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          value={comment}
          label="Comment (Optional)"
          multiline
          rows={4}
          onChange={(e) => setComment(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="error"
          variant="contained"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
}
