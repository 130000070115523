import React from 'react';
import cx from 'classnames';

import { useSelector } from 'react-redux';

import { userSelectors } from 'redux/selectors';

import MainNavigation from './MainNavigation';

import logo from './logo.svg';

import useStyles from './Navigation.styles';

const Navigation = ({ className, app, subNav }) => {
  const classes = useStyles();

  const orgDisplayName = useSelector(userSelectors.orgDisplayNameSelector);

  return (
    <div className={cx(className, classes.root)}>
      <div className={classes.logoBlock}>
        <div className={classes.logo}>
          <img className={classes.logoImg} src={logo} alt="logo" />
          <div className={classes.logoNameWrapper}>
            <div className={classes.logoName}>ImmoMate</div>
            <div>{orgDisplayName}</div>
          </div>
        </div>
      </div>
      <div className={classes.navigation}>
        <MainNavigation app={app} />
        {subNav && (
        <div className={classes.subNav}>
          <div className={classes.divider} />
          {subNav}
        </div>
        )}
      </div>
    </div>
  );
};

export default Navigation;
