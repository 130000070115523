const transformArrayToObj = (arr) => {
  const res = {};

  arr.forEach((item) => {
    res[item] = true;
  });

  return res;
};

export default transformArrayToObj;
