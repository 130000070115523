const movePages = (subDocs, pageNumbers, targetSubDocIndex, targetPageIndex) => {
  const targetSubDoc = subDocs[targetSubDocIndex];
  const pagesToMove = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const subDoc of subDocs) {
    if (subDoc !== targetSubDoc) {
      subDoc.pages = subDoc.pages.filter((page) => {
        if (pageNumbers.includes(page.number)) {
          pagesToMove.push(page);
          return false;
        }
        return true;
      });
    }
  }

  targetSubDoc.pages.splice(targetPageIndex, 0, ...pagesToMove);

  for (let i = subDocs.length - 1; i >= 0; i -= 1) {
    if (subDocs[i].pages.length === 0) {
      subDocs.splice(i, 1);
    }
  }
};

export default movePages;
