import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    backgroundColor: '#fff',
    // eslint-disable-next-line max-len
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    padding: '18px 20px 18px 16px',
    borderRadius: 16,
    color: '#1f1f1f',
    textTransform: 'none',
    height: 56,

    '& svg': {
      width: 24,
      height: 24,
    },
  },
});

export default useStyles;
