import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';

import { energyCertificatesColors } from 'constants/energyCertificates';

const EnergyEfficiency = ({ data }) => {
  const renderEnergyClass = (value) => (
    value !== 'Unknown' && <Chip label={value} sx={{ backgroundColor: energyCertificatesColors[value] }} />
  );

  return (
    <TableContainer sx={{ height: '100%' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell>
              Living space
            </TableCell>
            <TableCell>
              Year of house construction
            </TableCell>
            <TableCell>
              Year of heating construction
            </TableCell>
            <TableCell>
              Annual energy requirement per square meter (kwh)
            </TableCell>
            <TableCell>
              Energy class
            </TableCell>
            <TableCell>
              Age of Heating system in years
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              {data.living_space}
            </TableCell>
            <TableCell>
              {data.building_construction_year}
            </TableCell>
            <TableCell>
              {data.heating_construction_year}
            </TableCell>
            <TableCell>
              {data.annual_energy_requirement_sqm}
            </TableCell>
            <TableCell>
              {renderEnergyClass(data.energy_efficient_class)}
            </TableCell>
            <TableCell>
              {data.heating_system_age}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EnergyEfficiency;
