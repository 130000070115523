import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem, Switch } from '@mui/material';

const MenuItemWithSwitch = ({
  selected, children, ...props
}) => (
  <MenuItem {...props}>
    <div style={{ marginRight: 8 }}>
      <Switch
        checked={selected}
        size="small"
      />
    </div>
    {children}
  </MenuItem>
);

MenuItemWithSwitch.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

MenuItemWithSwitch.defaultProps = {
  selected: false,
};

export default MenuItemWithSwitch;
