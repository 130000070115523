import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  field: {
    marginTop: 32,

    '&:hover $editButton': {
      visibility: 'visible',
    },
  },
});

export default useStyles;
