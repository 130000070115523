import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useDocumentClasses from '../../hooks/documentClasses/useDocumentClasses';
import { semanticClassificationPrompt } from '../../constants/prompts';

const PromptSelectionMenu = ({ anchorEl, onClose, onSelect }) => {
  const documentClasses = useDocumentClasses();

  if (!documentClasses) {
    return null;
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem
        onClick={() => onSelect(semanticClassificationPrompt)}
      >
        Semantic classification
      </MenuItem>
      {documentClasses.map((item) => (
        <MenuItem
          key={item.id}
          onClick={() => onSelect(item.metadata_extraction_config.prompt)}
        >
          Extraction:
          {' '}
          {item.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default PromptSelectionMenu;
