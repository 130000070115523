import request from 'api/request';

const MAX_FILES_IN_ONE_REQUEST = 200;

const fillFilesInfo = async (sheet, docs, orgName) => {
  const filesById = {};

  try {
    for (let i = 0; i < docs.length; i += MAX_FILES_IN_ONE_REQUEST) {
      const ids = docs.slice(i, i + MAX_FILES_IN_ONE_REQUEST).map((file) => file.file_id);

      // eslint-disable-next-line no-await-in-loop
      const { data: { files } } = await request({
        method: 'post',
        url: '/files/_search',
        data: {
          file_item_ids: ids,
          limit: MAX_FILES_IN_ONE_REQUEST,
        },
      });

      files.forEach((file) => {
        filesById[file.id] = file;
      });
    }

    docs.forEach((doc, index) => {
      const valueCell = sheet.getRow(2 + index).getCell(3);
      valueCell.font = { bold: false };

      const fileItem = filesById[doc.file_id];

      if (fileItem) {
        valueCell.value = {
          text: fileItem.name,
          hyperlink: `${window.location.origin}/${orgName}?preview_file=${doc.file_id}`,
        };
      } else {
        valueCell.value = 'ERROR';
      }
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export default fillFilesInfo;
