import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { NotificationManager as NM } from 'react-notifications';

import { useDidMount } from 'hooks';

import request from 'api/request';

const useImmoOverview = () => {
  const { immoId } = useParams();

  const [immoOverview, setImmoOverview] = useState(null);

  const handleAddressUpdate = (addressDetails) => {
    setImmoOverview({
      ...immoOverview,
      address_details: addressDetails,
    });
  };

  const loadImmoCard = async () => {
    try {
      const { data: immoCard } = await request({
        method: 'get',
        url: `/immo-cards/${immoId}`,
      });

      setImmoOverview(immoCard);
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  useDidMount(() => {
    loadImmoCard();
  });

  return {
    data: immoOverview,
    updateAddress: handleAddressUpdate,
  };
};

export default useImmoOverview;
