/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isCreating: false,
};

export const slice = createSlice({
  name: 'fileCreation',
  initialState,
  reducers: {
    createFilesRequest: (state) => {
      state.isCreating = true;
    },
    createFilesSuccess: (state) => {
      state.isCreating = false;
    },
    createFilesFailure: (state) => {
      state.isCreating = false;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
