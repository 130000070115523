import React from 'react';

import Button from '@mui/material/Button';

import UploadIcon from '@mui/icons-material/Upload';

import useStyles from './UploadButton.styles';

const UploadButton = ({ onUpload }) => {
  const classes = useStyles();

  const handleFilesInput = (event) => {
    onUpload(Array.from(event.target.files));
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };

  return (
    <Button
      className={classes.root}
      component="label"
      startIcon={<UploadIcon />}
    >
      Upload
      <input
        type="file"
        hidden
        accept="application/pdf"
        multiple
        onChange={handleFilesInput}
      />
    </Button>
  );
};

export default UploadButton;
