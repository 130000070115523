import { useSelector } from 'react-redux';

import useTableSettings from 'components/TableSettingsProvider/useTableSettings';

import useDocumentClassesById from 'hooks/documentClasses/useDocumentClassesById';

import { filtersSelectors } from 'redux/selectors';

const useMetadataColumns = () => {
  const { visibleColumns } = useTableSettings();
  const documentClassesById = useDocumentClassesById() || {};
  const typeFilter = useSelector(filtersSelectors.typesSelector);

  if (
    !visibleColumns.find(({ type }) => type === 'METADATA')
    || !documentClassesById[typeFilter[0]]
  ) {
    return [];
  }

  return documentClassesById[typeFilter[0]].fields.filter(
    ({ name }) => name !== 'Summary (eng)' && name !== 'Summary (de)',
  );
};

export default useMetadataColumns;
