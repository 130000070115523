import {
  all, put, takeLatest, takeEvery, select,
} from 'redux-saga/effects';
import { NotificationManager as NM } from 'react-notifications';
import _uniq from 'lodash/uniq';

import { setIsGlobalLoading } from 'redux/actions';

import { actions } from './inboxItemSubDocsReducer';

import request from '../../utils/sagas/request';

import * as selectors from './inboxItemSubDocsSelectors';
import attachApprovalsToItems from './utils/attachApprovalsToItems';

export function* getItemsFlow() {
  yield put(setIsGlobalLoading(true));

  const itemId = yield select(selectors.itemIdSelector);

  try {
    const { data: { inbox_items: items } } = yield request({
      method: 'post',
      url: `inbox/${itemId}/_list_sub_items`,
      data: {
        sort_options: 'DATE_DESC',
        limit: 300,
        offset: 0,
      },
    });

    const { data: { approval_requests: approvals } } = yield request({
      method: 'post',
      url: 'approval-requests/_search',
      data: {
        limit: 300,
        offset: 0,
        file_item_ids: items.map((item) => item.file_item_id),
      },
    });

    const immoIds = _uniq(approvals.map((approval) => approval.suggested_immo_id)).filter((x) => x);

    let immos = [];

    if (immoIds.length > 0) {
      const responseImmos = yield request({
        method: 'post',
        url: 'immos/_search',
        data: {
          immo_ids: immoIds,
          limit: 300,
        },
      });
      immos = responseImmos.data.immos;
    }

    attachApprovalsToItems(items, approvals, immos);

    yield put(actions.getItemsSuccess({ items }));
  } catch (e) {
    yield put(actions.getItemsFailure());
    NM.error('Something went wrong');
  }
  yield put(setIsGlobalLoading(false));
}

export function* pushDocsToImmosFlow({ payload: { approvalRequestIds } }) {
  yield put(setIsGlobalLoading(true));
  yield put(actions.setIsPushing({ ids: approvalRequestIds, value: true }));

  try {
    yield request({
      method: 'post',
      url: '/approval-requests/_send',
      data: {
        approval_request_ids: approvalRequestIds,
      },
    });

    yield put(actions.getItemsRequest({}));
  } catch (e) {
    yield put(actions.setIsPushing({ ids: approvalRequestIds, value: false }));
    NM.error('Something went wrong');
  }

  yield put(setIsGlobalLoading(false));
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.getItemsRequest.type, getItemsFlow),
    takeEvery(actions.pushDocsToImmos.type, pushDocsToImmosFlow),
  ]);
}
