import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';
import { Link, useParams } from 'react-router-dom';
import ProcessingButtonWithError from 'components/ProcessingButtonWithError';
import useTask from 'hooks/useTask';

import { inboxListActions as actions, processingPopupActions } from 'redux/actions';

const InboxItemButton = ({ item, inboxType }) => {
  const inboxPath = inboxType === 'DEFAULT' ? 'inbox' : 'balance-import';
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState();
  const { orgName } = useParams();

  const handleTaskTypeUpdate = async (oldTask) => {
    if (oldTask?.type === 'split_finalizing') {
      try {
        const { data } = await request({
          method: 'post',
          url: '/inbox/_search',
          data: {
            inbox_type: inboxType,
            ids: [item.id],
            limit: 300,
          },
        });
        dispatch(actions.updateItems({ items: data.inbox_items }));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  const { task, fetchTask } = useTask({
    fileItemId: item.file_item_id,
    fetchOnMount: item.status === 'processing',
    onTaskTypeUpdate: handleTaskTypeUpdate,
  });

  const taskStatus = task?.status || item.status;
  const taskStep = task?.type || item.step;

  const handleSplit = async () => {
    setIsSubmitting(true);
    try {
      await request({
        method: 'post',
        url: `/inbox/${item.id}/_prepare_split`,
        data: {},
      });
      fetchTask();
      dispatch(processingPopupActions.fetchProcessingTasks());
    } catch (e) {
      NM.error('Something went wrong');
    }
    setIsSubmitting(false);
  };

  const handleClassifySubDocs = async () => {
    setIsSubmitting(true);
    try {
      await request({
        method: 'post',
        url: `/inbox/${item.id}/_classify_all`,
        data: {},
      });
      fetchTask();
    } catch (e) {
      NM.error('Something went wrong');
    }
    setIsSubmitting(false);
  };

  const renderSplitButton = () => (
    <Button
      variant="text"
      disabled={isSubmitting}
      onClick={() => handleSplit()}
    >
      Split
    </Button>
  );

  const renderVerifySlittingButton = () => (
    <Button
      component={Link}
      variant="text"
      to={`/${orgName}/${inboxPath}/${item.id}/splitting?file_item_id=${item.file_item_id}`}
    >
      Verify splitting
    </Button>
  );

  if (!taskStep) {
    return renderSplitButton();
  }

  if (taskStatus === 'completed') {
    switch (taskStep) {
      case 'classification':
        return <Button disabled variant="text">Sub docs classification...</Button>;
      case 'metadata_extraction':
        return <Button disabled variant="text">Sub docs metadata extraction...</Button>;
      default:
        break;
    }
  }

  if (taskStep === 'split_preparation') {
    if (taskStatus === 'completed') {
      return renderVerifySlittingButton();
    }

    if (taskStatus === 'processing') {
      return <Button disabled variant="text">Preparing splitting...</Button>;
    }

    return (
      <ProcessingButtonWithError errorMessage="AI service error">
        {renderSplitButton()}
      </ProcessingButtonWithError>
    );
  }

  if (taskStep === 'split_finalizing') {
    if (taskStatus === 'failed') {
      return (
        <ProcessingButtonWithError>
          {renderVerifySlittingButton()}
        </ProcessingButtonWithError>
      );
    }

    return <Button disabled variant="text">Splitting...</Button>;
  }

  if (taskStep === 'classification') {
    if (taskStatus === 'processing') {
      return <Button disabled variant="text">Sub docs classification...</Button>;
    }

    if (taskStatus === 'failed') {
      return (
        <ProcessingButtonWithError>
          <Button
            variant="text"
            disabled={isSubmitting}
            onClick={() => handleClassifySubDocs()}
          >
            Classify sub docs
          </Button>
        </ProcessingButtonWithError>
      );
    }
  }

  if (taskStep === 'metadata_extraction' && taskStatus === 'processing') {
    return <Button disabled variant="text">Sub docs metadata extraction...</Button>;
  }

  if (taskStep === 'name_generation' && taskStatus === 'processing') {
    return <Button disabled variant="text">Name generation...</Button>;
  }

  if (taskStep === 'immo_detection' && taskStatus === 'processing') {
    return <Button disabled variant="text">Property detection...</Button>;
  }

  return (
    <Button
      component={Link}
      variant="text"
      to={`/${orgName}/${inboxPath}/${item.id}/sub-docs?file_item_id=${item.file_item_id}`}
    >
      View sub docs
    </Button>
  );
};

export default InboxItemButton;
