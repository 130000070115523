import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

const ExcelJS = require('exceljs');

const MAX_FILES_IN_ONE_REQUEST = 300;

const useDownloadTemplateWithBenchmarkFiles = () => {
  const downloadTemplateWithBenchmarkFiles = async () => {
    try {
      const response = await fetch(`${process.env.PUBLIC_URL}/Test bench classification.xlsx`);
      const arrayBuffer = await response.arrayBuffer();
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);

      const worksheet = workbook.getWorksheet(1);

      // Keep the header row and delete all other rows
      const totalRows = worksheet.rowCount;

      for (let i = totalRows; i > 1; i -= 1) {
        worksheet.spliceRows(i, 1);
      }

      const { data: { 'document-classes': documentClasses } } = await request({
        method: 'get',
        url: '/document-classes',
        params: {
          limit: 100,
        },
        cache: true,
      });

      const { data: { prompt } } = await request({
        method: 'post',
        url: '/ai-test/calculate-prompt',
        data: {
          benchmark_type: 'CLASSIFICATION',
        },
        cache: true,
      });

      const documentClassesById = {};

      documentClasses.forEach((documentClass) => {
        documentClassesById[documentClass.id] = documentClass;
      });

      const fileItems = [];
      let offset = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const { data: { files } } = await request({
          method: 'post',
          url: '/files/_search',
          data: {
            file_types: ['benchmark'],
            limit: MAX_FILES_IN_ONE_REQUEST,
            offset,
          },
        });

        offset += MAX_FILES_IN_ONE_REQUEST;

        files.forEach((file) => {
          if (file.add_to_benchmark_request.benchmark_type === 'CLASSIFICATION') {
            fileItems.push(file);
          }
        });

        if (files.length < MAX_FILES_IN_ONE_REQUEST) {
          break;
        }
      }

      fileItems.sort(
        (a, b) => (
          documentClassesById[a.add_to_benchmark_request.document_class_id].name
          > documentClassesById[b.add_to_benchmark_request.document_class_id].name ? 1 : -1
        ),
      );

      fileItems.forEach((file) => {
        if (!file.add_to_benchmark_request.document_class_id) {
          return;
        }

        worksheet.addRow([
          file.id,
          documentClassesById[file.add_to_benchmark_request.document_class_id].name,
        ]);
      });

      const configSheet = workbook.getWorksheet(2);

      configSheet.getRow(2).getCell('G').value = prompt;
      configSheet.getRow(3).getCell('G').value = prompt;

      // Save the modified workbook
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);

      // Trigger download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Test bench classification with benchmark files.xlsx';
      link.click();

      // Clean up
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
      NM.error('Failed to download template with benchmark files');
    }
  };

  return downloadTemplateWithBenchmarkFiles;
};

export default useDownloadTemplateWithBenchmarkFiles;
