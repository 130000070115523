/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/material';

import { mainSelectors } from 'redux/selectors';
import { mainActions } from 'redux/actions';

import useStyles from './LanguageSwitcher.styles';

const LanguageSwitcher = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(mainSelectors.languageSelector);

  const renderLanguage = (lang) => (
    <div
      style={{
        color: lang === selectedLanguage ? 'rgba(0, 0, 0, 0.8)' : undefined,
        cursor: 'pointer',
      }}
      onClick={() => dispatch(mainActions.setLanguage({ value: lang }))}
    >
      {lang}
    </div>
  );

  return (
    <Stack divider={<div>/</div>} spacing={1} direction="row" className={classes.root}>
      {renderLanguage('EN')}
      {renderLanguage('DE')}
    </Stack>
  );
};

export default LanguageSwitcher;
