import { request } from 'api';

import downloadByLink from 'utils/downloadByLink';

import { NotificationManager as NM } from 'react-notifications';

function timeout(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const useDownloadExportZip = () => {
  const downloadExportZip = async (exportTaskId, fileName) => {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      await timeout(5000);

      // eslint-disable-next-line no-await-in-loop
      const { data: task } = await request({
        method: 'get',
        url: `export-tasks/${exportTaskId}`,
      });

      if (task.status === 'completed') {
        // eslint-disable-next-line no-await-in-loop
        const { data: signedLinkResponse } = await request({
          method: 'post',
          url: '/document-download-links/_generate_export_link',
          data: {
            export_task_id: task.id,
          },
        });

        downloadByLink(signedLinkResponse.signed_link, `${fileName}.zip`);
        break;
      }

      if (task.status === 'failed') {
        NM.error('Something went wrong');
        break;
      }
    }
  };

  return downloadExportZip;
};

export default useDownloadExportZip;
