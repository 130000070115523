import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import cx from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import HomeWork from '@mui/icons-material/HomeWork';
import Mail from '@mui/icons-material/Mail';
import BalanceIcon from '@mui/icons-material/Balance';
import SearchIcon from '@mui/icons-material/Search';
import ScienceIcon from '@mui/icons-material/Science';
import CompareIcon from '@mui/icons-material/Compare';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { mainSelectors } from 'redux/selectors';
import { mainActions } from 'redux/actions';

import { IconButton } from '@mui/material';

import NavigationItem from './NavigationItem';

import useStyles from './MainNavigation.styles';

const MainNavigation = ({ app }) => {
  const classes = useStyles();
  const { orgName } = useParams();
  const { user } = useAuth0();

  const dispatch = useDispatch();

  const isExpanded = useSelector(mainSelectors.isNavigationExpandedSelector);

  const handleExpandButtonClick = () => {
    dispatch(mainActions.expandNavigation());
  };

  const isAdmin = user.email.includes('@bordercon.de') || user.email.includes('@finhound.ai');

  return (
    <div className={cx(classes.superWrapper, isExpanded && classes.expanded)}>
      <div className={classes.divider} />
      <div className={classes.content}>
        <div className={classes.topLinks}>
          <NavigationItem
            name="Properties"
            icon={<HomeWork />}
            active={app === 'immos'}
            component={Link}
            linkTo={`/${orgName}`}
            isExpanded={isExpanded}
          />
          <NavigationItem
            name="Inbox"
            icon={<Mail />}
            active={app === 'inbox'}
            component={Link}
            linkTo={`/${orgName}/inbox`}
            isExpanded={isExpanded}
          />
          <NavigationItem
            name="Balance import"
            icon={<BalanceIcon />}
            active={app === 'balanceImport'}
            component={Link}
            linkTo={`/${orgName}/balance-import`}
            isExpanded={isExpanded}
          />
          <NavigationItem
            name="Search"
            icon={<SearchIcon />}
            active={app === 'search'}
            component={Link}
            linkTo={`/${orgName}/search`}
            isExpanded={isExpanded}
          />
          {isAdmin && (
            <NavigationItem
              name="AI test"
              icon={<ScienceIcon />}
              active={app === 'ai-test'}
              component={Link}
              linkTo={`/${orgName}/ai-test`}
              isExpanded={isExpanded}
            />
          )}
          {isAdmin && (
            <NavigationItem
              name="Benchmark"
              icon={<CompareIcon />}
              active={app === 'benchmark'}
              component={Link}
              linkTo={`/${orgName}/benchmark`}
              isExpanded={isExpanded}
            />
          )}
          {isAdmin && (
            <NavigationItem
              name="Admin"
              icon={<AdminPanelSettingsIcon />}
              active={app === 'admin'}
              component={Link}
              linkTo={`/${orgName}/admin/file-item-change-events`}
              isExpanded={isExpanded}
            />
          )}
        </div>
      </div>
      <IconButton className={classes.expandCollapseButton} size="small" onClick={handleExpandButtonClick}>
        <KeyboardArrowLeftIcon
          className={classes.expandCollapseButtonIcon}
        />
      </IconButton>
    </div>
  );
};

export default MainNavigation;
