export const getVisibleColumns = (columns, columnsVisibilityState, alwaysVisibleColumnsObj) => {
  const columnsVisibility = {
    ...columnsVisibilityState,
    ...alwaysVisibleColumnsObj,
  };

  return columns.filter((column) => columnsVisibility[column.type]);
};

export const getAlwaysAndDefaultVisibleColumns = (columns) => {
  const alwaysVisibleColumns = [];
  const defaultVisibleColumns = [];

  columns.forEach((column) => {
    if (column.isAlwaysVisible) {
      alwaysVisibleColumns.push(column.type);
    }
    if (column.isDefaultVisible) {
      defaultVisibleColumns.push(column.type);
    }
  });

  return { alwaysVisibleColumns, defaultVisibleColumns };
};
