import React, { useState } from 'react';

import cx from 'classnames';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { IconButton, Typography } from '@mui/material';

import AddressFieldValueEditMode from './AddressFieldValueEditMode';
import useStyles from './AddressFieldValue.styles';

const AddressFieldValue = ({ value, onChange }) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);

  const handleEditClick = (e) => {
    setIsEdit(true);
    e.preventDefault();
    e.stopPropagation();
  };

  if (!isEdit) {
    return (
      <div className={cx(classes.root, value && classes.withValue)}>
        <Typography>{value}</Typography>
        <IconButton size="small" className={classes.editButton} onClick={handleEditClick}>
          <ModeEditIcon />
        </IconButton>
      </div>
    );
  }

  return (
    <AddressFieldValueEditMode
      value={value}
      onChange={onChange}
      onClose={() => setIsEdit(false)}
    />
  );
};

export default AddressFieldValue;
