const useFileTransformedMetadata = (fileItem) => {
  if (!fileItem.ai_metadata) {
    return {};
  }

  const res = {};

  fileItem.ai_metadata.metadata_list.forEach((metadata) => {
    res[metadata.name] = metadata;
  });

  return res;
};

export default useFileTransformedMetadata;
