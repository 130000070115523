const getItems = () => {
  try {
    return JSON.parse(localStorage.getItem('immomate.splitting')) || [];
  } catch (e) {
    return [];
  }
};

export const getSplittingState = (fileId) => {
  try {
    const items = getItems();
    const item = items.find((it) => it.fileId === fileId);

    return item || null;
  } catch (e) {
    return null;
  }
};

export const setSplittingState = (fileId, subDocs) => {
  try {
    const items = getItems();
    const itemIndex = items.findIndex((it) => it.fileId === fileId);

    if (itemIndex !== -1) {
      items[itemIndex] = {
        ...items[itemIndex],
        subDocs,
      };
    } else {
      items.push({
        fileId,
        subDocs,
      });

      if (items.length > 10) {
        items.shift();
      }
    }

    localStorage.setItem('immomate.splitting', JSON.stringify(items));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const removeSplittingState = (fileId) => {
  try {
    const items = getItems();

    const newItems = items.filter((item) => item.fileId !== fileId);

    localStorage.setItem('immomate.splitting', JSON.stringify(newItems));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
