const rootSelector = (state) => state.inboxList;

export const inboxTypeSelector = (state) => rootSelector(state).inboxType;

export const showCompletedSelector = (state) => rootSelector(state).showCompleted;

export const isLoadingSelector = (state) => rootSelector(state).isLoading;

export const itemsSelector = (state) => rootSelector(state).items;

export const totalItemsSelector = (state) => rootSelector(state).totalItems;

export const pageSelector = (state) => rootSelector(state).page;

export const limitSelector = (state) => rootSelector(state).limit;

export const sortSelector = (state) => rootSelector(state).sort;

export const searchTermSelector = (state) => rootSelector(state).searchTerm;

export const approvalStatsSelector = (state) => rootSelector(state).approvalStats;
