import React, { Fragment, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { mainSelectors, fileListSelectors as selectors } from 'redux/selectors';
import { fileListActions as actions } from 'redux/actions';

import FileNameTableCell from 'components/FileNameTableCell';
import FileRawActions from 'components/FileRawActions';
import ColumnVisibilitySelect from 'components/TableSettingsProvider/ColumnVisibilitySelect';
import useTableSettings from 'components/TableSettingsProvider/useTableSettings';

import formatDate from 'utils/formatDate';

import ScanProcessingButton from './ScanProcessingButton';
import LegacyScanProcessingButton from './LegacyScanProcessingButton';
// eslint-disable-next-line no-unused-vars
import useStyles from './ScansTable.styles';

function bytesToMB(bytes) {
  return bytes / 1024 / 1024;
}

const ScansTable = () => {
  const tableContainerRef = useRef(null);
  const dispatch = useDispatch();
  const items = useSelector(selectors.itemsSelector) || [];
  const hasMore = useSelector(selectors.hasMoreSelector);
  const loading = useSelector(selectors.isLoadingSelector);
  const { visibleColumns } = useTableSettings();

  const highlightedItemId = useSelector(mainSelectors.highlightedItemIdSelector);

  const handleItemUpdate = (fileItem) => {
    dispatch(actions.updateFile({ file: fileItem }));
  };

  const renderBodyCell = (column, item) => {
    switch (column.type) {
      case 'PAGES':
        return <TableCell align="right">{item.pages_count}</TableCell>;
      case 'CREATOR':
        return <TableCell>{item.created_by}</TableCell>;
      case 'DATE_ADDED':
        return <TableCell>{formatDate(item.created_at)}</TableCell>;
      case 'SIZE':
        return (
          <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
            {bytesToMB(Math.max(item.size, 0.1)).toFixed(3)}
            {' '}
            MB
          </TableCell>
        );
      default:
        return <TableCell />;
    }
  };

  useLayoutEffect(() => {
    const tableEl = tableContainerRef.current;

    const handleScroll = () => {
      const bottom = tableEl.scrollHeight - tableEl.clientHeight;

      if (tableEl.scrollTop > bottom - 1000 && hasMore && !loading) {
        dispatch(actions.getFileListRequest({ loadMore: true }));
      }
    };

    tableEl.addEventListener('scroll', handleScroll);
    return () => {
      tableEl.removeEventListener('scroll', handleScroll);
    };
  }, [hasMore, loading, dispatch]);

  return (
    <TableContainer sx={{ height: '100%' }} ref={tableContainerRef}>
      <Table stickyHeader aria-label="sticky table" style={{ padding: 16 }}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{ paddingRight: 0, width: 40 }}
              colSpan={2}
            >
              Name
            </TableCell>
            {visibleColumns.map((column) => (
              <TableCell key={column.type} align={column.align}>
                {column.title}
              </TableCell>
            ))}
            <TableCell />
            <TableCell align="right" sx={{ width: 0 }}>
              <ColumnVisibilitySelect />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow
              key={item.id}
              hover
              selected={highlightedItemId === item.id}
              role="checkbox"
              tabIndex={-1}
            >
              <FileNameTableCell item={item} onItemUpdate={handleItemUpdate} />
              {visibleColumns.map((column) => (
                <Fragment key={column.type}>
                  {renderBodyCell(column, item)}
                </Fragment>
              ))}
              <TableCell align="right">
                {item.status === 'SPLIT_REQUESTED' ? (
                  <ScanProcessingButton item={item} />
                ) : (
                  <LegacyScanProcessingButton item={item} />
                )}
              </TableCell>
              <TableCell>
                <FileRawActions
                  item={item}
                  withExportSubDocs
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScansTable;
