import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import useImmoExportZip from './hooks/useImmoExportZip';

export default function ImmoListItemMenu({ immo }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const exportZip = useImmoExportZip(immo);

  const handleMenuButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleExportZip = async () => {
    handleMenuClose();
    exportZip();
  };

  return (
    <>
      <IconButton onClick={handleMenuButtonClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleExportZip}>
          Export ZIP
        </MenuItem>
      </Menu>
    </>
  );
}
