import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import cx from 'classnames';
import {
  StatusNotStarted, StatusRunning, StatusSuccess, StatusFailed, StatusCanceled, StatusSkipped,
} from 'icons';

import getTitle from './utils/getPipelineStepTitle';

import useStyles from './PipelineStep.styles';

const PipelineStep = ({
  status, title, startedAt, finishedAt, onClick,
}) => {
  const classes = useStyles();

  const icons = {
    NOT_STARTED: [StatusNotStarted, classes.created],
    STARTED: [StatusRunning, classes.started],
    COMPLETED: [StatusSuccess, classes.success],
    FAILED: [StatusFailed, classes.error],
    TIMED_OUT: [StatusCanceled, classes.error],
    SKIPPED: [StatusSkipped, classes.created],
    FINISHED_WITH_BUSINESS_ERROR: [StatusFailed, classes.success],
  };

  const [Icon, iconClassName] = icons[status] || icons.NOT_STARTED;

  return (
    <div className={classes.root}>
      <Tooltip title={getTitle(title, startedAt, finishedAt)} placement="top">
        <div
          className={cx(classes.stepButton, onClick)}
        >
          <span className={cx(classes.icon, iconClassName)}>
            <Icon />
          </span>
        </div>
      </Tooltip>
    </div>
  );
};

PipelineStep.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
  startedAt: PropTypes.any,
  finishedAt: PropTypes.any,
  onClick: PropTypes.func,
};

PipelineStep.defaultProps = {
  status: null,
  startedAt: null,
  finishedAt: null,
  onClick: undefined,
};

export default PipelineStep;
