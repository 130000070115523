import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  popper: {
    width: '480px !important',
  },
  tag: {
    display: 'none',
  },
});

export default useStyles;
