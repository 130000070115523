import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  header: {
    height: 64,
    padding: '0px 24px',
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    boxShadow: 'rgba(70, 45, 140, 0.05) 0px 4px 8px',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
  },
  leftSide: {
    minWidth: 0,
    marginRight: 'auto',
    paddingRight: 16,
  },
  fileNameWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  fileName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  content: {
    height: 'calc(100vh - 64px)',
    boxSizing: 'border-box',
  },
});

export default useStyles;
