import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    fontSize: 14,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.4)',
  },
});

export default useStyles;
