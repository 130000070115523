import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  dialogPaper: {
    maxWidth: 1000,
    minHeight: 451,
    fontFamily: 'Fira Sans',
    color: '#0F0F0F',
    background: '#FAFAFA',
    borderRadius: 13,
  },
  content: {
    padding: [33, 17, 19, 17],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '&:before': {
      content: '""',
      background: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 50%)',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 40,
      zIndex: 9,
      pointerEvents: 'none',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 8,
    zIndex: 10,

    '& svg': {
      height: 40,
      width: 'auto',
    },
  },
  settingsButton: {
    position: 'absolute',
    right: 40,
    top: 0,
    padding: 8,
    zIndex: 10,

    '& svg': {
      height: 30,
      width: 'auto',
      padding: 5,
    },
  },
  logoImg: {
    height: 42,
    width: 'auto',
  },
  title: {
    marginTop: 14,
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '26px',
  },
  description: {
    fontSize: '13px',
    lineHeight: '20px',
  },
  generating: {},
});

export default useStyles;
