import { request } from 'api';
import { useDispatch } from 'react-redux';

import { NotificationManager as NM } from 'react-notifications';

import { mainActions } from 'redux/actions';

import useDownloadExportZip from 'hooks/export/useDownloadExportZip';

const useImmoExportZip = (immo) => {
  const dispatch = useDispatch();
  const downloadExportZip = useDownloadExportZip();

  const exportZip = async () => {
    dispatch(mainActions.setIsGlobalLoading(true));

    try {
      const { data: task } = await request({
        method: 'post',
        url: `immos/${immo.id}/_export_zip`,
      });
      await downloadExportZip(task.id, immo.name);
    } catch (e) {
      NM.error('Something went wrong');
    }

    dispatch(mainActions.setIsGlobalLoading(false));
  };

  return exportZip;
};

export default useImmoExportZip;
