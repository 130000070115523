import React, { useState } from 'react';

import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import useStyles from './SearchBox.styles';

const SearchBox = ({ onSubmit }) => {
  const classes = useStyles();
  const [question, setQuestion] = useState('');

  const handleChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      onSubmit(question);
    }
  };

  const handleSubmitClick = () => {
    onSubmit(question);
  };

  return (
    <div className={classes.root}>
      <div className={classes.description}>
        What do you want to know?
      </div>
      <div className={classes.searchBox}>
        <OutlinedInput
          className={classes.input}
          value={question}
          placeholder="What is the total income from rent payments for the last quarter?"
          fullWidth
          autoFocus
          onChange={handleChange}
          onKeyDown={handleKeyPress}
        />
        <IconButton className={classes.submitButton} onClick={handleSubmitClick}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default SearchBox;
