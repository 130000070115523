import { useState } from 'react';
import { useSelector } from 'react-redux';
import useDidMount from 'hooks/useDidMount';

import { mainSelectors } from 'redux/selectors';

import { NotificationManager as NM } from 'react-notifications';

import { request } from 'api';

const abcSort = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const abcSortDe = (a, b) => {
  if (a.name_de < b.name_de) {
    return -1;
  }
  if (a.name_de > b.name_de) {
    return 1;
  }
  return 0;
};

const useDocumentClasses = () => {
  const [items, setItems] = useState(null);
  const isEn = useSelector(mainSelectors.isEnSelector);

  const fetchItems = async () => {
    try {
      const { data } = await request({
        method: 'get',
        url: '/document-classes',
        params: {
          limit: 100,
        },
        cache: true,
      });
      setItems(data['document-classes']);
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  useDidMount(() => {
    fetchItems();
  });

  const sortFunction = isEn ? abcSort : abcSortDe;

  return items && items.sort(sortFunction);
};

export default useDocumentClasses;
