import React, { useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Stack } from '@mui/material';

import ViewUtilityDocsDialog from './ViewUtilityDocsDialog';

import artesiaLogo from './utilityLogos/artesia.jpg';
import berlinRecyclingLogo from './utilityLogos/berlin recycling.png';
import calmessLogo from './utilityLogos/calmess.png';
import gaerteLogo from './utilityLogos/Gaerte.jpg';
import homewiseLogo from './utilityLogos/homewise.png';
import KanzleyLogo from './utilityLogos/KANZLEI RITTER.webp';
import logoPlaceholder from './utilityLogos/logo-placeholder.jpg';

const logos = {
  'homewise GmbH': homewiseLogo,
  'Kanzlei Ritter GmbH': KanzleyLogo,
  'Gaerte GmbH & Co. KG': gaerteLogo,
  'CalMess GmbH Wärmemessdienst': calmessLogo,
  'CalMess GmbH': calmessLogo,
  'Berlin Recycling GmbH': berlinRecyclingLogo,
  'Artesia Immobilien GmbH': artesiaLogo,
};

const UtilitiesInfoSection = ({ utilitiesInfo }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleCardClick = (utilityItem) => {
    setSelectedItem(utilityItem);
  };

  const handleDialogClose = () => {
    setSelectedItem(null);
  };

  return (
    <>
      <Stack sx={{ mt: 2, flexWrap: 'wrap', gap: 1 }} direction="row">
        {utilitiesInfo.map((item) => (
          <Card key={item.provider_id} sx={{ width: 300 }} variant="outlined">
            <CardActionArea onClick={() => handleCardClick(item)}>
              <CardMedia
                component="img"
                height="80"
                image={logos[item.provider_name] || logoPlaceholder}
                sx={{
                  objectFit: 'contain', padding: 1, mt: 2, boxSizing: 'border-box',
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div" sx={{ fontSize: 16 }}>
                  {item.provider_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.documents.length}
                  {' '}
                  {item.documents.length === 1 ? 'document' : 'documents'}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Stack>
      {selectedItem && <ViewUtilityDocsDialog utilityItem={selectedItem} onClose={handleDialogClose} />}
    </>
  );
};

export default UtilitiesInfoSection;
