import React from 'react';

import Chip from '@mui/material/Chip';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Stack, Tooltip } from '@mui/material';

const ApprovalChip = ({ approval }) => {
  if (!approval) {
    return null;
  }

  switch (approval.status) {
    case 'CREATED':
      return null;
    case 'SENT':
      return (
        <Chip
          label="AWAITING"
        />
      );
    case 'REJECTED':
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Chip
            label="REJECTED"
            color="error"
          />
          {approval.comment && (
            <Tooltip title={approval.comment}>
              <InfoOutlinedIcon />
            </Tooltip>
          )}
        </Stack>
      );
    case 'APPROVED':
      return (
        <Chip
          label="APPROVED"
          color="success"
        />
      );
    default:
      return (
        <Chip
          label={approval.status}
        />
      );
  }
};

export default ApprovalChip;
