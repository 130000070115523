import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { Divider } from '@mui/material';

import { mainSelectors } from 'redux/selectors';
import { mainActions } from 'redux/actions';

import { idToName } from 'constants/adminFolders';
import { Link, useParams } from 'react-router-dom';
import { useDidMount } from 'hooks';

function AdminSubNavigation() {
  const { folderId, orgName } = useParams();

  const dispatch = useDispatch();

  const isExpanded = useSelector(mainSelectors.isNavigationExpandedSelector);

  useDidMount(() => {
    if (isExpanded) {
      setTimeout(() => {
        dispatch(mainActions.expandNavigation());
      }, 0);
    }
  });

  const renderItem = (num, name) => (
    <MenuItem
      key={name}
      sx={{ alignItems: 'flex-start' }}
      component={Link}
      to={`/${orgName}/admin/${name}`}
      selected={name === folderId}
    >
      <Typography
        sx={{ fontSize: 14, whiteSpace: 'normal', marginLeft: '8px' }}
        variant="inherit"
      >
        {idToName[name]}
      </Typography>
    </MenuItem>
  );

  return (
    <MenuList>
      {renderItem('', 'file-item-change-events')}
      <Divider />
      {renderItem('', 'benchmark-files')}
    </MenuList>
  );
}

export default AdminSubNavigation;
