import React from 'react';
import { useSelector } from 'react-redux';

import { Link as RouterLink, useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { currentImmoSelectors } from 'redux/selectors';

function ImmosBreadcrumbs({ content }) {
  const { immoId, orgName } = useParams();
  const currentImmo = useSelector(currentImmoSelectors.immoSelector);

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
    >
      <Link underline="hover" key="1" color="inherit" component={RouterLink} to={`/${orgName}`}>
        Properties
      </Link>
      {currentImmo && (
        <Link underline="hover" key="2" color="inherit" component={RouterLink} to={`/${orgName}/${immoId}/scans`}>
          {currentImmo.name}
        </Link>
      )}
      {content}
    </Breadcrumbs>

  );
}

export default ImmosBreadcrumbs;
