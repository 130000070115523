import React, { useState } from 'react';

import { InputBase } from '@mui/material';

const EditableNumberCellEditMode = ({
  type = 'DOUBLE', // DOUBLE | INTEGER
  initialValue,
  onSubmit,
  onFocus,
  onBlur,
}) => {
  const [value, setValue] = useState(initialValue);

  const handleSubmit = async () => {
    onSubmit(value);
  };

  const handleBlur = () => {
    onBlur();
    handleSubmit();
  };

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case 13:
        handleSubmit();
        break;
      default:
        break;
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;

    if (!newValue) {
      setValue(newValue);
      return;
    }

    if (type === 'INTEGER') {
      setValue(Math.floor(newValue));
      return;
    }

    setValue(newValue);
  };

  return (
    <InputBase
      sx={{
        width: '100%',
        fontWeight: 'inherit',
        fontSize: 'inherit',
        padding: 0,
      }}
      slotProps={{
        input: {
          style: {
            padding: 0,
          },
        },
      }}
      placeholder="Enter value"
      value={value}
      onKeyDown={handleKeyDown}
      type="number"
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={handleBlur}
    />
  );
};

export default EditableNumberCellEditMode;
