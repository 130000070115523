import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Stack, IconButton } from '@mui/material';

import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import { fileSplittingActions as actions } from 'redux/actions';
import { fileSplittingSelectors as selectors } from 'redux/selectors';

const SplittingViewZoomController = () => {
  const dispatch = useDispatch();
  const zoom = useSelector(selectors.zoomSelector);

  const setNewZoom = (newValue) => {
    const roundedValue = Math.round(newValue * 10) / 10;

    dispatch(actions.setZoom({ value: roundedValue }));
    localStorage.setItem('immomate.splitting.zoom', roundedValue);
  };

  const handleZoomOutClick = () => {
    setNewZoom(zoom - 0.1);
  };

  const handleZoomInClick = () => {
    setNewZoom(zoom + 0.1);
  };

  return (
    <Stack direction="row" sx={{ marginRight: '16px' }}>
      <IconButton disabled={zoom <= 1} onClick={handleZoomOutClick}>
        <ZoomOutIcon />
      </IconButton>
      <IconButton onClick={handleZoomInClick}>
        <ZoomInIcon />
      </IconButton>
    </Stack>
  );
};

export default SplittingViewZoomController;
