import React from 'react';

import { Link as RouterLink, useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function InboxBreadcrumbs({ content }) {
  const { orgName } = useParams();

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
    >
      <Link underline="hover" key="1" color="inherit" component={RouterLink} to={`/${orgName}/balance-import`}>
        Balance import
      </Link>
      {content}
    </Breadcrumbs>

  );
}

export default InboxBreadcrumbs;
