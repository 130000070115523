import React from 'react';
import { Provider } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import Helmet from 'react-helmet';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import ThemeProvider from 'components/UIKit/ThemeProvider';
import AppRouter from 'components/AppRouter';
import store from 'redux/store';

import 'react-notifications/lib/notifications.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import jss from 'jss';
import preset from 'jss-preset-default';

jss.setup(preset());

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Helmet><title>ImmoMate</title></Helmet>
      <Provider store={store}>
        <ThemeProvider>
          <AppRouter />
        </ThemeProvider>
      </Provider>
      <NotificationContainer />
    </LocalizationProvider>
  );
}

export default App;
