import React from 'react';

import ErrorIcon from '@mui/icons-material/Error';

import { Tooltip, Stack } from '@mui/material';

const ProcessingButtonWithError = ({ errorMessage, children }) => (
  <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
    <Tooltip title={errorMessage} placement="top">
      <ErrorIcon color="error" />
    </Tooltip>
    {children}
  </Stack>
);

export default ProcessingButtonWithError;
