import React from 'react';

import cx from 'classnames';

import { CircularProgress, Stack, Collapse } from '@mui/material';

import FileItemAnswer from './FileItemAnswer';
import SummaryMarkdown from './SummaryMarkdown';

import useStyles from './AnswerContent.styles';

import useFileItemAnswers from '../hooks/useFileItemAnswers';

const AnswerContent = ({ isGenerating, isGeneratingSummary, answer }) => {
  const classes = useStyles();

  const fileItemAnswers = useFileItemAnswers(answer);

  if ((!answer || answer.status === 'ERROR') && !isGenerating) {
    return (
      <div className={classes.howItWorksBlock}>
        <div className={classes.howItWorksTitle}>
          How it works?
        </div>
        <div className={classes.howItWorksDescription}>
          Enter your question and we will give you the answer in seconds
        </div>
      </div>
    );
  }

  if (isGenerating || (answer?.status === 'IN_PROGRESS' && !fileItemAnswers)) {
    return (
      <div className={cx(classes.answerBlock, isGenerating && classes.generating)}>
        <div className={classes.answerBlockTitle}>
          Your answer will be here
        </div>
        <div className={classes.answerBlockContent}>
          <CircularProgress size={60} thickness={2} />
          <div className={classes.generatingTitle}>
            We are generating an answer
          </div>
          <div className={classes.generatingDescription}>
            Just a moment, we are racing through all immo documents to collect relevant content
          </div>
        </div>
      </div>
    );
  }

  if (
    (answer?.status === 'COMPLETE' || answer?.status === 'PARTIAL_SUCCESS')
    && (!fileItemAnswers || fileItemAnswers.length === 0)
  ) {
    return (
      <div className={cx(classes.answerBlock, isGenerating && classes.generating)}>
        <div className={classes.answerBlockContent}>
          <div className={classes.answerSummary}>
            <div>{'Unfortunately, we couldn\'t find relevant documents'}</div>
            <div>Please try to reformulate your question</div>
          </div>
        </div>
      </div>
    );
  }

  const renderSummary = () => {
    if (isGeneratingSummary || !answer.summary) {
      return (
        <div
          className={classes.answerBlockContent}
          style={{
            padding: 0,
            paddingTop: 75,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={60} thickness={2} />
          <div className={classes.generatingTitle}>
            We are generating a summary
          </div>
        </div>
      );
    }

    return (
      <div className={classes.answerBlockContent}>
        <div className={classes.answerTitle}>
          We generated this answer based on the most relevant immo documents.
        </div>
        <div className={classes.answerSummary}>
          <SummaryMarkdown text={answer.summary} />
        </div>
      </div>
    );
  };

  return (
    <div className={cx(classes.answerBlock, isGenerating && classes.generating)}>
      <div className={classes.answerBlockTitle}>
        Your answer
      </div>
      <Collapse collapsedSize={283} in={answer.summary}>
        {renderSummary()}
      </Collapse>
      <div className={classes.answerBlockTitle} style={{ marginTop: 16 }}>
        Relevant documents
      </div>
      {fileItemAnswers && (
        <Stack spacing="16px">
          {fileItemAnswers.map((fileItemAnswer, index) => (
            <FileItemAnswer key={fileItemAnswer.id} index={index} fileItemAnswer={fileItemAnswer} />
          ))}
        </Stack>
      )}
    </div>
  );
};

export default AnswerContent;
