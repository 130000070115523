import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { NotificationManager as NM } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { currentImmoSelectors } from 'redux/selectors';
import { request } from 'api';
import { currentImmoActions } from 'redux/actions';

const useCurrentImmo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { immoId, orgName } = useParams();
  const immo = useSelector(currentImmoSelectors.immoSelector);

  const loadImmo = async () => {
    try {
      const { data } = await request({
        method: 'get',
        url: `immos/${immoId}`,
      });
      dispatch(currentImmoActions.setImmo({ data }));
    } catch (e) {
      if (e.response?.status === 404) {
        NM.error('Property not found');
        navigate(`/${orgName}`);
      } else {
        NM.error('Something went wrong');
      }
    }
  };

  useEffect(() => {
    dispatch(currentImmoActions.setImmoId({ value: immoId }));
    if (immoId) {
      if (immo?.id !== immoId) {
        loadImmo();
      }
    } else {
      dispatch(currentImmoActions.reset());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [immoId, immo]);
};

export default useCurrentImmo;
