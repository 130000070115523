const rootSelector = (state) => state.immoList;

export const isLoadingSelector = (state) => rootSelector(state).isLoading;

export const itemsSelector = (state) => rootSelector(state).items;

export const totalItemsSelector = (state) => rootSelector(state).totalItems;

export const pageSelector = (state) => rootSelector(state).page;

export const searchTermSelector = (state) => rootSelector(state).searchTerm;

export const sortSelector = (state) => rootSelector(state).sort;

export const offsetSelector = (state) => rootSelector(state).offset;

export const hasMoreSelector = (state) => rootSelector(state).hasMore;

export const filtersSelector = (state) => rootSelector(state).filters;
