/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  groupFilter: null,
  typeFilter: [],
  immosFilter: [],
  benchmarkTypeFilter: [],
};

export const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setGroupFilter: (state, { payload: { value } }) => {
      state.groupFilter = value;
    },
    setTypeFilter: (state, { payload: { value } }) => {
      state.typeFilter = value;
    },
    setBenchmarkTypeFilter: (state, { payload: { value } }) => {
      state.benchmarkTypeFilter = value;
    },
    setImmosFilter: (state, { payload: { value } }) => {
      state.immosFilter = value;
    },
    reset: () => initialState,
  },
});

export const { actions } = slice;

export default slice.reducer;
