import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    backgroundColor: '#E9EEF6',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 722,
    borderRadius: '24px',
    padding: '0 9px',
    boxSizing: 'border-box',
    flexGrow: 1,

    '& fieldset': {
      border: 'none',
    },
  },
});

export default useStyles;
