/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  processingTasks: [],
  filesById: {},
  isInited: false,
};

export const slice = createSlice({
  name: 'processingPopup',
  initialState,
  reducers: {
    fetchProcessingTasks: () => {},
    fetchProcessingTasksSuccess: (state, { payload: { tasks, newFilesById } }) => {
      state.processingTasks = tasks;
      state.filesById = {
        ...state.filesById,
        ...newFilesById,
      };
      state.isInited = true;
    },
    close: (state) => {
      state.processingTasks = [];
      state.filesById = {};
    },
    reset: () => initialState,
  },
});

export const { actions } = slice;

export default slice.reducer;
