import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, InputLabel, Select } from '@mui/material';

const SelectController = ({
  control,
  name,
  label,
  helperText,
  rules,
  children,
  ...otherProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <FormControl variant="standard" fullWidth {...otherProps}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          {children}
        </Select>
      </FormControl>
    )}
    rules={rules}
  />
);

export default SelectController;
