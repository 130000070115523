import React from 'react';

import { Box, FormGroup, Typography } from '@mui/material';

import FormControl from '@mui/material/FormControl';

import {
  TextFieldController,
  SwitchController,
} from 'components/formControllers';

import ModelSelect from '../ModelSelect';

function AnswersFileItemRulesForm({ control, watch }) {
  const isEvidenceClassificationEnabled = watch('file_item_rules.evidence_classification_on');

  return (
    <Box sx={{ mx: 3 }}>
      <ModelSelect control={control} name="file_item_rules.model" />
      <TextFieldController
        sx={{ mb: 3, mt: 3, display: 'block' }}
        fullWidth
        control={control}
        name="file_item_rules.prompt_template"
        multiline
        label="Prompt template"
        helperText="Placeholders '<question>' and '<file_item_prompt>' must be present in the template"
        rules={{
          pattern: {
            value: /(?=.*<question>)(?=.*<file_item_prompt>)/s,
          },
        }}
      />
      <FormControl sx={{ display: 'block', position: 'relative' }}>
        <TextFieldController
          label="Cut off score"
          variant="standard"
          size="small"
          control={control}
          name="file_item_rules.cut_off_score"
        />
      </FormControl>
      <TextFieldController
        sx={{ mt: 3, display: 'block' }}
        label="Top results amount"
        variant="standard"
        size="small"
        control={control}
        name="file_item_rules.top_results_amount"
      />
      <Typography variant="h6" sx={{ mt: 5 }}>Evidence classification</Typography>
      <FormGroup>
        <SwitchController
          sx={{ mt: 1 }}
          control={control}
          name="file_item_rules.evidence_classification_on"
          label="Enabled"
        />
      </FormGroup>
      {isEvidenceClassificationEnabled && (
        <>
          <TextFieldController
            sx={{ mb: 3, mt: 3, display: 'block' }}
            fullWidth
            control={control}
            name="file_item_rules.evidence_classification_prompt"
            multiline
            label="Prompt template"
            helperText="Placeholders '<text>' and '<question>' must be present in the template"
            rules={{
              pattern: {
                value: /(?=.*<text>)(?=.*<question>)/s,
              },
            }}
          />
          <TextFieldController
            sx={{ mt: 3, display: 'block' }}
            label="Evidence cut off score "
            variant="standard"
            size="small"
            control={control}
            name="file_item_rules.evidence_cut_off_score"
          />
        </>
      )}
    </Box>
  );
}

export default AnswersFileItemRulesForm;
