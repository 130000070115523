import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  zoomContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageWrapper: {
    position: 'relative',

    '& canvas': {
      height: 'auto !important',
      width: 'auto !important',
      maxWidth: '50vw !important',
      maxHeight: 'calc(100vh - 100px) !important',
    },

    marginBottom: 60,
  },
  overlayButton: {
    backgroundColor: 'rgba(50, 50, 50)',
    color: 'white',
    zIndex: 10,

    '&:hover': {
      backgroundColor: 'rgba(80, 80, 80)',
    },
  },
  leftButton: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 40,
  },
  rightButton: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    right: 40,
  },
  splitBeforeButton: {
    display: 'block',
    position: 'absolute',
    bottom: 40,
    left: 40,
  },
  splitAfterButton: {
    display: 'block',
    position: 'absolute',
    bottom: 40,
    right: 40,
  },
  closeButton: {
    backgroundColor: 'rgba(50, 50, 50)',
    position: 'absolute',
    top: 40,
    right: 40,
  },
  markedForDeletion: {
    fontSize: 64,
    color: 'red',
    position: 'absolute',
    top: 'calc(50% - 32px)',
    left: 'calc(50% - 32px)',
    pointerEvents: 'none',
  },
  toolbox: {
    position: 'absolute',
    bottom: 20,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  buttonGroup: {
    '& .MuiButton-root': {
      backgroundColor: 'rgba(50, 50, 50)',
      borderColor: '#bdbdbd !important',

      '&:hover': {
        backgroundColor: 'rgba(80, 80, 80)',
      },

      '&.Mui-disabled': {
        opacity: '0.5',

        color: '#fff',
      },
    },
  },
  prevPage: {
    position: 'absolute',
    left: '-22vw',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '20vw !important',
  },
  nextPage: {
    position: 'absolute',
    right: '-22vw',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '20vw !important',
  },
});

export default useStyles;
