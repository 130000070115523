import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  body: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    lineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  tooltip: {
    fontSize: 14,
  },
});

export default useStyles;
