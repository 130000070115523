import { request } from 'api';

function* makeRequest({
  method,
  url,
  params,
  data,
}) {
  const res = yield request({
    method,
    url,
    params,
    data,
  });

  return res;
}

export default makeRequest;
