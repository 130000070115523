import React, { forwardRef } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'overlayscrollbars/styles/overlayscrollbars.css';

const SimpleBar = forwardRef(({
  children, className, disabled, onScroll, ...otherProps
}, ref) => {
  if (disabled) {
    return children;
  }

  return (
    <OverlayScrollbarsComponent
      ref={ref}
      className={className}
      options={{
        scrollbars: { autoHide: 'leave' },
      }}
      events={{
        scroll: onScroll,
      }}
      {...otherProps}
    >
      {children}
    </OverlayScrollbarsComponent>
  );
});

SimpleBar.defaultProps = {
  children: undefined,
  disabled: false,
  onScroll: undefined,
};

export default SimpleBar;
