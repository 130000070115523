import {
  all, put, takeLatest, select,
} from 'redux-saga/effects';
import { NotificationManager as NM } from 'react-notifications';

import { setIsGlobalLoading } from 'redux/actions';

import { actions } from './immoListReducer';
import * as selectors from './immoListSelectors';

import request from '../../utils/sagas/request';

const LIMIT = 30;

export function* getItemsFlow({ payload = {} }) {
  const { loadMore } = payload;
  const searchTerm = yield select(selectors.searchTermSelector);
  const sort = yield select(selectors.sortSelector);
  const offset = loadMore ? yield select(selectors.offsetSelector) : 0;
  const filters = yield select(selectors.filtersSelector);
  const newOffset = offset + LIMIT;

  yield put(setIsGlobalLoading(true));
  try {
    const { data } = yield request({
      method: 'post',
      url: '/immos/_search',
      data: {
        name: searchTerm,
        limit: LIMIT,
        sort_direction: sort.sort_direction,
        sort_by: sort.sort_by,
        offset,
        only_with_pending_approvals: filters.only_with_pending_approvals,
        only_without_address: filters.only_without_address,
      },
    });

    yield put(actions.getItemsSuccess({
      data,
      offset: newOffset,
      hasMore: data.immos.length === LIMIT,
      loadMore,
    }));
  } catch (e) {
    yield put(actions.getItemsFailure());
    NM.error('Something went wrong');
  }
  yield put(setIsGlobalLoading(false));
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.getItemsRequest.type, getItemsFlow),
  ]);
}
