import { useSearchParams } from 'react-router-dom';

const useFilePreview = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const openFilePreview = ({ fileId, pageNumber, highlight }) => {
    searchParams.set('preview_file', fileId);

    if (pageNumber) {
      searchParams.set('fp_page', pageNumber);
    }

    if (highlight) {
      searchParams.set('fp_highlight', highlight);
    }

    setSearchParams(searchParams);
  };

  const closeFilePreview = () => {
    searchParams.delete('preview_file');
    searchParams.delete('fp_number');
    searchParams.delete('fp_highlight');

    setSearchParams(searchParams);
  };

  return {
    openFilePreview,
    closeFilePreview,
  };
};

export default useFilePreview;
