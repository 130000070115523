import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    padding: 4,
    cursor: 'pointer',
    borderRadius: '50%',

    '&:hover': {
      backgroundColor: 'rgba(60,64,67,.08)',
    },
  },
});

export default useStyles;
