import React from 'react';

import Button from '@mui/material/Button';

import { Link, useParams } from 'react-router-dom';

const ScanProcessingButton = ({ item }) => {
  const { immoId, orgName } = useParams();

  return (
    <Button
      component={Link}
      variant="text"
      to={`/${orgName}/${immoId}/scans/${item.id}/sub-docs`}
    >
      View sub docs
    </Button>
  );
};

export default ScanProcessingButton;
