import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import withAuth0Provider from 'hocs/withAuth0Provider';
import withAuthRequired from 'hocs/withAuthRequired';
import withAccessTokenRequired from 'hocs/withAccessTokenRequired';
import useCurrentImmo from './hooks/useCurrentImmo';

const PrivateRoute = ({ children }) => {
  const auth0 = useAuth0();
  const { orgName } = useParams();

  useCurrentImmo();

  if (!orgName) {
    return <Navigate to={`/${auth0.user.org_name}${window.location.pathname}`} replace />;
  }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.any,
};

PrivateRoute.defaultProps = {
  children: undefined,
};

export default withAuth0Provider(withAuthRequired(withAccessTokenRequired(PrivateRoute)));
