import React from 'react';
import { useSelector } from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import useDocumentClasses from 'hooks/documentClasses/useDocumentClasses';

import { mainSelectors, filtersSelectors } from 'redux/selectors';

const TypeFilter = ({ onChange }) => {
  const selectedValue = useSelector(filtersSelectors.benchmarkTypesSelector);
  const fileTypes = useDocumentClasses() || [];
  const isEn = useSelector(mainSelectors.isEnSelector);

  const labelKey = isEn ? 'name' : 'name_de';

  const fileTypeById = {};

  fileTypes.forEach((fileType) => {
    fileTypeById[fileType.id] = fileType[labelKey];
  });

  const handleChange = (event) => {
    const { value } = event.target;

    onChange(value);
  };

  return (
    <FormControl size="small" sx={{ width: 150 }}>
      <InputLabel>Type</InputLabel>
      <Select
        label="Type"
        multiple
        value={selectedValue}
        renderValue={(selected) => selected.map((x) => fileTypeById[x]).join(', ')}
        onChange={handleChange}
        onClose={() => {
          setTimeout(() => {
            document.activeElement.blur();
          }, 0);
        }}
      >
        {fileTypes.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox checked={selectedValue.indexOf(option.id) > -1} />
            <ListItemText primary={option[labelKey]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TypeFilter;
