/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { transformFileItem } from './utils/transformFileItems';

const initialState = {
  subFolder: null,
  originId: null,
  items: null,
  isLoading: false,
  totalItems: null,
  sort: {
    sort_by: 'DATE',
    sort_direction: 'DESC',
    isMetadata: false,
  },
  tasksPerItem: {},
  pipelinesPerItem: {},
  searchTerm: '',
  typeFilter: [],
  fileTypes: null,
  hasMore: true,
  offset: 0,
};

export const slice = createSlice({
  name: 'fileList',
  initialState,
  reducers: {
    resetSort: (state) => {
      state.sort = initialState.sort;
    },
    setSort: (state, { payload: { sort } }) => {
      state.sort = sort;
    },
    setOriginId: (state, { payload: { value } }) => {
      state.subFolder = undefined;
      state.originId = value;
    },
    updateFile: (state, { payload: { file } }) => {
      if (state.items) {
        const index = state.items.findIndex((item) => item.id === file.id);

        if (index !== -1) {
          state.items[index] = transformFileItem(file);
        }
      }
    },
    deleteFile: (state, { payload: { fileId } }) => {
      if (state.items) {
        state.items = state.items.filter((item) => item.id !== fileId);
      }
    },
    getFileListRequest: (state, { payload: { page } }) => {
      state.isLoading = true;
      if (page) {
        state.page = page;
      }
    },
    getFileListSuccess: (state, {
      payload: {
        data, offset, hasMore, loadMore,
      },
    }) => {
      if (!state.items || !loadMore) {
        state.items = data.files;
      } else {
        state.items = [
          ...state.items,
          ...data.files,
        ];
      }

      state.hasMore = hasMore;
      state.offset = offset;
      state.isLoading = false;
      state.totalItems = data.total_items;
    },
    getFileListFailure: (state) => {
      state.isLoading = false;
    },
    setItemTask: (state, { payload: { task, itemId } }) => {
      state.tasksPerItem[itemId] = task;
    },
    fetchPipelinesRequest: () => { },
    fetchPipelinesSuccess: (state, { payload: { pipelines } }) => {
      pipelines.forEach((groupedPipeline) => {
        // eslint-disable-next-line prefer-destructuring
        state.pipelinesPerItem[groupedPipeline.file_item_id] = groupedPipeline.pipelines[0];
      });
    },
    setSearchTerm: (state, { payload: { value } }) => {
      state.searchTerm = value;
    },
    setFileTypes: (state, { payload: { value } }) => {
      state.fileTypes = value;
    },
    reset: () => initialState,
  },
});

export const { actions } = slice;

export default slice.reducer;
