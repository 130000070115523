const getTransformedMetadata = (item) => {
  if (!item.ai_metadata) {
    return {};
  }

  const res = {};

  item.ai_metadata.metadata_list.forEach((metadata) => {
    res[metadata.name] = metadata;
  });

  return res;
};

export const transformFileItem = (item) => ({
  ...item,
  transformedMetadata: getTransformedMetadata(item),
});

const transformFileItems = (items) => items.map(transformFileItem);

export default transformFileItems;
