import {
  all, put, takeLatest, select,
} from 'redux-saga/effects';
import { NotificationManager as NM } from 'react-notifications';

import { setIsGlobalLoading } from 'redux/actions';
import { filtersSelectors } from 'redux/selectors';

import { actions } from './searchReducer';
import * as selectors from './searchSelectors';

import request from '../utils/sagas/request';

export function* searchFlow() {
  const searchTerm = yield select(selectors.searchTermSelector);

  yield put(setIsGlobalLoading(true));

  const searchType = yield select(selectors.searchTypeSelector);
  const fileTypes = yield select(filtersSelectors.typesSelector);
  const group = yield select(filtersSelectors.groupSelector);
  const immos = yield select(filtersSelectors.immosSelector);

  const immoIds = immos.map(({ id }) => id);

  const finalSearchType = searchTerm ? searchType : 'text';

  try {
    const { data } = yield request({
      method: 'post',
      url: `documents/_${finalSearchType}-search`,
      data: {
        search_term: searchTerm,
        document_class_ids: fileTypes.length ? fileTypes : undefined,
        grouping_types: group ? [group] : undefined,
        immo_ids: immoIds.length ? immoIds : undefined,
      },
    });

    let { items } = data;

    if (items.length === 0 && immoIds.length === 0) {
      const { data: simpleSearchData } = yield request({
        method: 'post',
        url: 'files/_text-search',
        data: {
          search_term: searchTerm,
          parent_ids: group ? [group] : undefined,
          document_class_ids: fileTypes.length ? fileTypes : undefined,
          immo_ids: immoIds.length ? immoIds : undefined,
        },
      });

      items = simpleSearchData.file_items.map((item) => ({
        file_item_id: item.id,
        pages: [],
        immo_id: item.immo_id,
        file_item_name: item.name,
        document_class_name: item.ai_generated_type,
        document_class_name_de: item.document_type_de,
        grouping_type: item.classification_group,
      }));
    }

    yield put(actions.searchSuccess({ results: items, searchTerm, searchResultId: data.id }));
  } catch (e) {
    NM.error('Something went wrong');
  }
  yield put(setIsGlobalLoading(false));
}

export function* loadSearchResultFlow({ payload: { id } }) {
  yield put(setIsGlobalLoading(true));

  try {
    const { data } = yield request({
      method: 'get',
      url: `documents/search-results/${id}`,
    });

    let immos = [];

    if (data.request.immo_ids?.length) {
      const { data: immosSearchResponse } = yield request({
        method: 'post',
        url: 'immos/_search',
        data: {
          immo_ids: data.request.immo_ids,
        },
      });

      immos = immosSearchResponse.immos;
    }

    yield put(actions.loadSearchResultSuccess({
      results: data.items,
      searchTerm: data.request.search_term,
      searchResultId: data.id,
      searchType: data.type,
      groupingTypes: data.request.grouping_types,
      documentClassIds: data.request.document_class_ids,
      immos,
    }));
  } catch (e) {
    NM.error('Something went wrong');
  }

  yield put(setIsGlobalLoading(false));
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.search.type, searchFlow),
    takeLatest(actions.loadSearchResult.type, loadSearchResultFlow),
  ]);
}
