import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

import { NotificationManager as NM } from 'react-notifications';

import { Autocomplete, TextField } from '@mui/material';

import request from 'api/request';

import { inboxItemSubDocsActions as actions, creationImmoDialogActions } from 'redux/actions';

import { useDidMount } from 'hooks';
import useStyles from './SuggestedImmoCellEditMode.styles';

const SuggestedImmoCellEditMode = ({
  item, onClose,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [suggestions, setSuggestions] = useState(null);

  const sendApprovalRequest = async (immo) => {
    setIsSubmitting(true);

    try {
      const { data } = await request({
        method: 'patch',
        url: `/approval-requests/${item.approval.id}`,
        data: {
          suggested_immo_id: immo.id,
        },
      });

      dispatch(actions.setApproval({
        itemId: item.id,
        approval: {
          ...data,
          suggestedImmoName: immo.name,
        },
      }));

      onClose();
    } catch (error) {
      NM.error('Something went wrong');
    }

    setIsSubmitting(false);
  };

  const handleChange = async (e, newValue) => {
    if (newValue.action === 'CREATE_IMMO') {
      dispatch(creationImmoDialogActions.openDialog({
        onSuccess: (newImmo) => sendApprovalRequest(newImmo),
      }));
      return;
    }

    sendApprovalRequest(newValue);
  };

  const searchImmos = async (text) => {
    try {
      const { data: { immos } } = await request({
        method: 'post',
        url: '/immos/_search',
        data: {
          name: text,
        },
      });

      setSuggestions(immos);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const debouncedSearchImmos = debounce(searchImmos, 200);

  const handleInputChange = (event, newValue) => {
    debouncedSearchImmos(newValue);
  };

  useDidMount(() => {
    searchImmos('');
  });

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case 27:
        onClose();
        break;
      default:
        break;
    }
  };

  const getOptions = () => {
    if (suggestions) {
      return [
        {
          name: 'Create a new immo',
          action: 'CREATE_IMMO',
        },
        ...suggestions,
      ];
    }

    return [];
  };

  return (
    <Autocomplete
      classes={{
        paper: classes.autocompletePaper,
      }}
      disabled={isSubmitting}
      onBlur={onClose}
      open={!isSubmitting}
      options={getOptions()}
      getOptionKey={((option) => option.id)}
      getOptionLabel={((option) => option.name)}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      filterOptions={(x) => x}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          autoFocus
          placeholder="Start typing..."
          InputProps={{ ...params.InputProps, disableUnderline: true }}
        />
      )}
      renderOption={(props, option) => (
        <li
          style={{ fontStyle: option.action ? 'italic' : 'normal' }}
          {...props}
        >
          {option.name}
        </li>
      )}
    />
  );
};

export default SuggestedImmoCellEditMode;
