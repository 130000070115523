const rootSelector = (state) => state.search;

export const searchTypeSelector = (state) => rootSelector(state).searchType;

export const viewTypeSelector = (state) => rootSelector(state).viewType;

export const searchTermSelector = (state) => rootSelector(state).searchTerm;

export const resultsSelector = (state) => rootSelector(state).results;

export const appliedSearchTermSelector = (state) => rootSelector(state).appliedSearchTerm;

export const searchResultIdSelector = (state) => rootSelector(state).searchResultId;

export const isLoadingSelector = (state) => rootSelector(state).isLoading;
