import { useSelector } from 'react-redux';

const { userSelectors } = require('redux/selectors');

const useIsAdmin = () => {
  const isAdmin = useSelector(userSelectors.isAdminSelector);

  return isAdmin;
};

export default useIsAdmin;
